.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.pointer-events-none {
  pointer-events: none;
}

.pointer-events-auto {
  pointer-events: auto;
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: sticky;
}

.inset-0 {
  inset: 0px;
}

.inset-x-0 {
  left: 0px;
  right: 0px;
}

.-bottom-10 {
  bottom: -2.5rem;
}

.-bottom-3 {
  bottom: -0.75rem;
}

.-bottom-5 {
  bottom: -1.25rem;
}

.-left-20 {
  left: -5rem;
}

.-left-3 {
  left: -0.75rem;
}

.-left-5 {
  left: -1.25rem;
}

.-right-0 {
  right: -0px;
}

.-right-1 {
  right: -0.25rem;
}

.-right-3 {
  right: -0.75rem;
}

.-right-4 {
  right: -1rem;
}

.-right-5 {
  right: -1.25rem;
}

.-top-15 {
  top: -3.75rem;
}

.-top-2 {
  top: -0.5rem;
}

.-top-20 {
  top: -5rem;
}

.-top-5 {
  top: -1.25rem;
}

.-top-6 {
  top: -1.5rem;
}

.bottom-0 {
  bottom: 0px;
}

.bottom-10 {
  bottom: 2.5rem;
}

.bottom-2 {
  bottom: 0.5rem;
}

.bottom-20 {
  bottom: 5rem;
}

.bottom-6 {
  bottom: 1.5rem;
}

.bottom-8 {
  bottom: 2rem;
}

.bottom-\[-100px\] {
  bottom: -100px;
}

.bottom-\[-10px\] {
  bottom: -10px;
}

.bottom-\[-30px\] {
  bottom: -30px;
}

.bottom-\[-5px\] {
  bottom: -5px;
}

.bottom-\[10\%\] {
  bottom: 10%;
}

.bottom-\[20\%\] {
  bottom: 20%;
}

.end-1 {
  inset-inline-end: 0.25rem;
}

.left-0 {
  left: 0px;
}

.left-1\/2 {
  left: 50%;
}

.left-14 {
  left: 3.5rem;
}

.left-5 {
  left: 1.25rem;
}

.left-\[-10px\] {
  left: -10px;
}

.left-\[-1px\] {
  left: -1px;
}

.left-\[-200px\] {
  left: -200px;
}

.left-\[-60\%\] {
  left: -60%;
}

.left-\[10\%\] {
  left: 10%;
}

.left-\[15px\] {
  left: 15px;
}

.left-\[17\%\] {
  left: 17%;
}

.left-\[19\%\] {
  left: 19%;
}

.left-\[24\%\] {
  left: 24%;
}

.left-\[76\%\] {
  left: 76%;
}

.left-\[9\%\] {
  left: 9%;
}

.left-\[calc\(50\%-60px\)\] {
  left: calc(50% - 60px);
}

.right-0 {
  right: 0px;
}

.right-10 {
  right: 2.5rem;
}

.right-2 {
  right: 0.5rem;
}

.right-4 {
  right: 1rem;
}

.right-40 {
  right: 10rem;
}

.right-5 {
  right: 1.25rem;
}

.right-7 {
  right: 1.75rem;
}

.right-\[-10px\] {
  right: -10px;
}

.right-\[-10vw\] {
  right: -10vw;
}

.right-\[-15px\] {
  right: -15px;
}

.right-\[-25px\] {
  right: -25px;
}

.right-\[-60\%\] {
  right: -60%;
}

.right-\[-60px\] {
  right: -60px;
}

.right-\[20px\] {
  right: 20px;
}

.right-\[6px\] {
  right: 6px;
}

.right-\[6vw\] {
  right: 6vw;
}

.right-full {
  right: 100%;
}

.top-0 {
  top: 0px;
}

.top-1 {
  top: 0.25rem;
}

.top-1\/2 {
  top: 50%;
}

.top-12 {
  top: 3rem;
}

.top-14 {
  top: 3.5rem;
}

.top-15 {
  top: 3.75rem;
}

.top-18 {
  top: 4.5rem;
}

.top-2 {
  top: 0.5rem;
}

.top-20 {
  top: 5rem;
}

.top-28 {
  top: 7rem;
}

.top-4 {
  top: 1rem;
}

.top-5 {
  top: 1.25rem;
}

.top-96 {
  top: 24rem;
}

.top-\[-147px\] {
  top: -147px;
}

.top-\[-15\%\] {
  top: -15%;
}

.top-\[-15vw\] {
  top: -15vw;
}

.top-\[-2px\] {
  top: -2px;
}

.top-\[-3px\] {
  top: -3px;
}

.top-\[-5\%\] {
  top: -5%;
}

.top-\[-5px\] {
  top: -5px;
}

.top-\[10\%\] {
  top: 10%;
}

.top-\[100px\] {
  top: 100px;
}

.top-\[120px\] {
  top: 120px;
}

.top-\[30px\] {
  top: 30px;
}

.top-\[34\%\] {
  top: 34%;
}

.top-\[40\%\] {
  top: 40%;
}

.top-\[40px\] {
  top: 40px;
}

.top-\[5\%\] {
  top: 5%;
}

.top-\[50\%\] {
  top: 50%;
}

.top-\[60px\] {
  top: 60px;
}

.top-\[62\%\] {
  top: 62%;
}

.top-\[6px\] {
  top: 6px;
}

.top-\[71px\] {
  top: 71px;
}

.top-\[85\%\] {
  top: 85%;
}

.top-\[calc\(100\%-120px\)\] {
  top: calc(100% - 120px);
}

.top-\[calc\(100\%-90px\)\] {
  top: calc(100% - 90px);
}

.isolate {
  isolation: isolate;
}

.-z-10 {
  z-index: -10;
}

.-z-20 {
  z-index: -20;
}

.z-0 {
  z-index: 0;
}

.z-10 {
  z-index: 10;
}

.z-30 {
  z-index: 30;
}

.z-40 {
  z-index: 40;
}

.z-50 {
  z-index: 50;
}

.z-\[-10\] {
  z-index: -10;
}

.z-\[-1\] {
  z-index: -1;
}

.z-\[-2\] {
  z-index: -2;
}

.z-\[100\] {
  z-index: 100;
}

.z-\[2\] {
  z-index: 2;
}

.order-1 {
  order: 1;
}

.order-10 {
  order: 10;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-first {
  order: -9999;
}

.order-last {
  order: 9999;
}

.col-span-1 {
  grid-column: span 1 / span 1;
}

.col-span-10 {
  grid-column: span 10 / span 10;
}

.col-span-12 {
  grid-column: span 12 / span 12;
}

.col-span-2 {
  grid-column: span 2 / span 2;
}

.col-span-3 {
  grid-column: span 3 / span 3;
}

.col-span-4 {
  grid-column: span 4 / span 4;
}

.col-span-5 {
  grid-column: span 5 / span 5;
}

.col-span-6 {
  grid-column: span 6 / span 6;
}

.col-span-7 {
  grid-column: span 7 / span 7;
}

.col-span-full {
  grid-column: 1 / -1;
}

.col-start-2 {
  grid-column-start: 2;
}

.col-start-7 {
  grid-column-start: 7;
}

.row-span-full {
  grid-row: 1 / -1;
}

.row-start-1 {
  grid-row-start: 1;
}

.row-start-2 {
  grid-row-start: 2;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.float-none {
  float: none;
}

.m-0 {
  margin: 0px;
}

.m-2 {
  margin: 0.5rem;
}

.m-5 {
  margin: 1.25rem;
}

.m-6 {
  margin: 1.5rem;
}

.m-\[-70px_0_20px\] {
  margin: -70px 0 20px;
}

.m-auto {
  margin: auto;
}

.-mx-6 {
  margin-left: -1.5rem;
  margin-right: -1.5rem;
}

.mx-0 {
  margin-left: 0px;
  margin-right: 0px;
}

.mx-12 {
  margin-left: 3rem;
  margin-right: 3rem;
}

.mx-14 {
  margin-left: 3.5rem;
  margin-right: 3.5rem;
}

.mx-2 {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.mx-24 {
  margin-left: 6rem;
  margin-right: 6rem;
}

.mx-3 {
  margin-left: 0.75rem;
  margin-right: 0.75rem;
}

.mx-6 {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.mx-8 {
  margin-left: 2rem;
  margin-right: 2rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-0 {
  margin-top: 0px;
  margin-bottom: 0px;
}

.my-0\.5 {
  margin-top: 0.125rem;
  margin-bottom: 0.125rem;
}

.my-10 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.my-12 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.my-15 {
  margin-top: 3.75rem;
  margin-bottom: 3.75rem;
}

.my-16 {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.my-20 {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.my-24 {
  margin-top: 6rem;
  margin-bottom: 6rem;
}

.my-3 {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-5 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.-mb-18 {
  margin-bottom: -4.5rem;
}

.-mb-2 {
  margin-bottom: -0.5rem;
}

.-mb-4 {
  margin-bottom: -1rem;
}

.-mb-5 {
  margin-bottom: -1.25rem;
}

.-mb-6 {
  margin-bottom: -1.5rem;
}

.-mb-8 {
  margin-bottom: -2rem;
}

.-ml-10 {
  margin-left: -2.5rem;
}

.-ml-2 {
  margin-left: -0.5rem;
}

.-ml-6 {
  margin-left: -1.5rem;
}

.-ml-8 {
  margin-left: -2rem;
}

.-ml-\[2\.5\%\] {
  margin-left: -2.5%;
}

.-mr-2 {
  margin-right: -0.5rem;
}

.-mr-6 {
  margin-right: -1.5rem;
}

.-mt-1 {
  margin-top: -0.25rem;
}

.-mt-12 {
  margin-top: -3rem;
}

.-mt-2 {
  margin-top: -0.5rem;
}

.-mt-20 {
  margin-top: -5rem;
}

.-mt-28 {
  margin-top: -7rem;
}

.-mt-4 {
  margin-top: -1rem;
}

.-mt-6 {
  margin-top: -1.5rem;
}

.-mt-8 {
  margin-top: -2rem;
}

.-mt-\[196px\] {
  margin-top: -196px;
}

.mb-0 {
  margin-bottom: 0px;
}

.mb-1 {
  margin-bottom: 0.25rem;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.mb-11 {
  margin-bottom: 2.75rem;
}

.mb-12 {
  margin-bottom: 3rem;
}

.mb-13 {
  margin-bottom: 3.25rem;
}

.mb-14 {
  margin-bottom: 3.5rem;
}

.mb-15 {
  margin-bottom: 3.75rem;
}

.mb-16 {
  margin-bottom: 4rem;
}

.mb-18 {
  margin-bottom: 4.5rem;
}

.mb-2 {
  margin-bottom: 0.5rem;
}

.mb-20 {
  margin-bottom: 5rem;
}

.mb-24 {
  margin-bottom: 6rem;
}

.mb-3 {
  margin-bottom: 0.75rem;
}

.mb-32 {
  margin-bottom: 8rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mb-7 {
  margin-bottom: 1.75rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.mb-9 {
  margin-bottom: 2.25rem;
}

.mb-\[-65px\] {
  margin-bottom: -65px;
}

.mb-\[24px\] {
  margin-bottom: 24px;
}

.mb-\[50vh\] {
  margin-bottom: 50vh;
}

.mb-\[66px\] {
  margin-bottom: 66px;
}

.ml-0 {
  margin-left: 0px;
}

.ml-1 {
  margin-left: 0.25rem;
}

.ml-16 {
  margin-left: 4rem;
}

.ml-2 {
  margin-left: 0.5rem;
}

.ml-3 {
  margin-left: 0.75rem;
}

.ml-4 {
  margin-left: 1rem;
}

.ml-8 {
  margin-left: 2rem;
}

.ml-\[-15px\] {
  margin-left: -15px;
}

.ml-\[-21px\] {
  margin-left: -21px;
}

.ml-\[-5px\] {
  margin-left: -5px;
}

.ml-\[15px\] {
  margin-left: 15px;
}

.ml-\[20px\] {
  margin-left: 20px;
}

.ml-\[30px\] {
  margin-left: 30px;
}

.ml-\[50px\] {
  margin-left: 50px;
}

.ml-\[calc\(50vw-35vw\)\] {
  margin-left: calc(50vw - 35vw);
}

.ml-auto {
  margin-left: auto;
}

.mr-0 {
  margin-right: 0px;
}

.mr-1 {
  margin-right: 0.25rem;
}

.mr-12 {
  margin-right: 3rem;
}

.mr-2 {
  margin-right: 0.5rem;
}

.mr-2\.5 {
  margin-right: 0.625rem;
}

.mr-3 {
  margin-right: 0.75rem;
}

.mr-4 {
  margin-right: 1rem;
}

.mr-5 {
  margin-right: 1.25rem;
}

.mr-6 {
  margin-right: 1.5rem;
}

.mr-8 {
  margin-right: 2rem;
}

.mr-\[-5px\] {
  margin-right: -5px;
}

.mr-auto {
  margin-right: auto;
}

.mt-0 {
  margin-top: 0px;
}

.mt-1 {
  margin-top: 0.25rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mt-12 {
  margin-top: 3rem;
}

.mt-13 {
  margin-top: 3.25rem;
}

.mt-14 {
  margin-top: 3.5rem;
}

.mt-15 {
  margin-top: 3.75rem;
}

.mt-16 {
  margin-top: 4rem;
}

.mt-18 {
  margin-top: 4.5rem;
}

.mt-2 {
  margin-top: 0.5rem;
}

.mt-20 {
  margin-top: 5rem;
}

.mt-24 {
  margin-top: 6rem;
}

.mt-3 {
  margin-top: 0.75rem;
}

.mt-36 {
  margin-top: 9rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mt-9 {
  margin-top: 2.25rem;
}

.mt-\[-100px\] {
  margin-top: -100px;
}

.mt-\[-40px\] {
  margin-top: -40px;
}

.mt-\[-5px\] {
  margin-top: -5px;
}

.mt-\[20px\] {
  margin-top: 20px;
}

.mt-\[30px\] {
  margin-top: 30px;
}

.mt-\[3px\] {
  margin-top: 3px;
}

.mt-\[7px\] {
  margin-top: 7px;
}

.box-content {
  box-sizing: content-box;
}

.line-clamp-2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.line-clamp-3 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.line-clamp-4 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.table {
  display: table;
}

.grid {
  display: grid;
}

.contents {
  display: contents;
}

.hidden {
  display: none;
}

.aspect-auto {
  aspect-ratio: auto;
}

.aspect-square {
  aspect-ratio: 1 / 1;
}

.aspect-video {
  aspect-ratio: 16 / 9;
}

.\!h-16 {
  height: 4rem !important;
}

.\!h-56 {
  height: 14rem !important;
}

.\!h-auto {
  height: auto !important;
}

.h-0 {
  height: 0px;
}

.h-0\.5 {
  height: 0.125rem;
}

.h-1 {
  height: 0.25rem;
}

.h-1\/2 {
  height: 50%;
}

.h-10 {
  height: 2.5rem;
}

.h-100 {
  height: 25rem;
}

.h-12 {
  height: 3rem;
}

.h-136 {
  height: 34rem;
}

.h-14 {
  height: 3.5rem;
}

.h-16 {
  height: 4rem;
}

.h-20 {
  height: 5rem;
}

.h-3 {
  height: 0.75rem;
}

.h-36 {
  height: 9rem;
}

.h-4 {
  height: 1rem;
}

.h-48 {
  height: 12rem;
}

.h-5 {
  height: 1.25rem;
}

.h-6 {
  height: 1.5rem;
}

.h-7 {
  height: 1.75rem;
}

.h-8 {
  height: 2rem;
}

.h-\[0\.75em\] {
  height: 0.75em;
}

.h-\[100\%\] {
  height: 100%;
}

.h-\[100px\] {
  height: 100px;
}

.h-\[100vh\] {
  height: 100vh;
}

.h-\[11px\] {
  height: 11px;
}

.h-\[120\%\] {
  height: 120%;
}

.h-\[120px\] {
  height: 120px;
}

.h-\[140px\] {
  height: 140px;
}

.h-\[144px\] {
  height: 144px;
}

.h-\[15px\] {
  height: 15px;
}

.h-\[16px\] {
  height: 16px;
}

.h-\[175px\] {
  height: 175px;
}

.h-\[180px\] {
  height: 180px;
}

.h-\[18px\] {
  height: 18px;
}

.h-\[200px\] {
  height: 200px;
}

.h-\[208px\] {
  height: 208px;
}

.h-\[20px\] {
  height: 20px;
}

.h-\[210px\] {
  height: 210px;
}

.h-\[24px\] {
  height: 24px;
}

.h-\[250px\] {
  height: 250px;
}

.h-\[25px\] {
  height: 25px;
}

.h-\[280px\] {
  height: 280px;
}

.h-\[29px\] {
  height: 29px;
}

.h-\[2px\] {
  height: 2px;
}

.h-\[30\%\] {
  height: 30%;
}

.h-\[30px\] {
  height: 30px;
}

.h-\[320px\] {
  height: 320px;
}

.h-\[340px\] {
  height: 340px;
}

.h-\[350px\] {
  height: 350px;
}

.h-\[37\%\] {
  height: 37%;
}

.h-\[40px\] {
  height: 40px;
}

.h-\[44px\] {
  height: 44px;
}

.h-\[450px\] {
  height: 450px;
}

.h-\[45px\] {
  height: 45px;
}

.h-\[480px\] {
  height: 480px;
}

.h-\[48px\] {
  height: 48px;
}

.h-\[4px\] {
  height: 4px;
}

.h-\[500px\] {
  height: 500px;
}

.h-\[50px\] {
  height: 50px;
}

.h-\[55px\] {
  height: 55px;
}

.h-\[56px\] {
  height: 56px;
}

.h-\[5px\] {
  height: 5px;
}

.h-\[60px\] {
  height: 60px;
}

.h-\[60vh\] {
  height: 60vh;
}

.h-\[64px\] {
  height: 64px;
}

.h-\[700px\] {
  height: 700px;
}

.h-\[70px\] {
  height: 70px;
}

.h-\[75px\] {
  height: 75px;
}

.h-\[80\%\] {
  height: 80%;
}

.h-\[80px\] {
  height: 80px;
}

.h-\[82px\] {
  height: 82px;
}

.h-\[90\%\] {
  height: 90%;
}

.h-\[90px\] {
  height: 90px;
}

.h-auto {
  height: auto;
}

.h-fit {
  height: fit-content;
}

.h-full {
  height: 100%;
}

.h-min {
  height: min-content;
}

.h-screen {
  height: var(--screen-height, 100vh);
}

.max-h-116 {
  max-height: 29rem;
}

.max-h-\[190px\] {
  max-height: 190px;
}

.max-h-\[200px\] {
  max-height: 200px;
}

.max-h-\[420px\] {
  max-height: 420px;
}

.max-h-\[480px\] {
  max-height: 480px;
}

.max-h-\[80px\] {
  max-height: 80px;
}

.max-h-\[calc\(100vh-180px\)\] {
  max-height: calc(100vh - 180px);
}

.max-h-full {
  max-height: 100%;
}

.max-h-screen {
  max-height: 100vh;
}

.min-h-16 {
  min-height: 4rem;
}

.min-h-\[100px\] {
  min-height: 100px;
}

.min-h-\[1280px\] {
  min-height: 1280px;
}

.min-h-\[128px\] {
  min-height: 128px;
}

.min-h-\[136px\] {
  min-height: 136px;
}

.min-h-\[180px\] {
  min-height: 180px;
}

.min-h-\[18rem\] {
  min-height: 18rem;
}

.min-h-\[240px\] {
  min-height: 240px;
}

.min-h-\[264px\] {
  min-height: 264px;
}

.min-h-\[320px\] {
  min-height: 320px;
}

.min-h-\[380px\] {
  min-height: 380px;
}

.min-h-\[400px\] {
  min-height: 400px;
}

.min-h-\[40px\] {
  min-height: 40px;
}

.min-h-\[436px\] {
  min-height: 436px;
}

.min-h-\[500px\] {
  min-height: 500px;
}

.min-h-\[540px\] {
  min-height: 540px;
}

.min-h-\[570px\] {
  min-height: 570px;
}

.min-h-\[600px\] {
  min-height: 600px;
}

.min-h-\[60px\] {
  min-height: 60px;
}

.min-h-\[64px\] {
  min-height: 64px;
}

.min-h-\[700px\] {
  min-height: 700px;
}

.min-h-\[80vh\] {
  min-height: 80vh;
}

.min-h-\[845px\] {
  min-height: 845px;
}

.min-h-\[900px\] {
  min-height: 900px;
}

.min-h-full {
  min-height: 100%;
}

.min-h-screen {
  min-height: 100vh;
}

.\!w-\[105\%\] {
  width: 105% !important;
}

.\!w-full {
  width: 100% !important;
}

.w-1 {
  width: 0.25rem;
}

.w-1\/2 {
  width: 50%;
}

.w-10 {
  width: 2.5rem;
}

.w-10\/12 {
  width: 83.333333%;
}

.w-184 {
  width: 46rem;
}

.w-19 {
  width: 4.75rem;
}

.w-20 {
  width: 5rem;
}

.w-3 {
  width: 0.75rem;
}

.w-3\/4 {
  width: 75%;
}

.w-32 {
  width: 8rem;
}

.w-4 {
  width: 1rem;
}

.w-4\/12 {
  width: 33.333333%;
}

.w-40 {
  width: 10rem;
}

.w-48 {
  width: 12rem;
}

.w-5\/12 {
  width: 41.666667%;
}

.w-6 {
  width: 1.5rem;
}

.w-6\/12 {
  width: 50%;
}

.w-60 {
  width: 15rem;
}

.w-7 {
  width: 1.75rem;
}

.w-7\/12 {
  width: 58.333333%;
}

.w-8 {
  width: 2rem;
}

.w-8\/12 {
  width: 66.666667%;
}

.w-92 {
  width: 23rem;
}

.w-\[0\.75em\] {
  width: 0.75em;
}

.w-\[100\%\] {
  width: 100%;
}

.w-\[1000px\] {
  width: 1000px;
}

.w-\[100px\] {
  width: 100px;
}

.w-\[100vw\] {
  width: 100vw;
}

.w-\[110px\] {
  width: 110px;
}

.w-\[11px\] {
  width: 11px;
}

.w-\[120px\] {
  width: 120px;
}

.w-\[130px\] {
  width: 130px;
}

.w-\[140px\] {
  width: 140px;
}

.w-\[144px\] {
  width: 144px;
}

.w-\[150px\] {
  width: 150px;
}

.w-\[150vw\] {
  width: 150vw;
}

.w-\[165px\] {
  width: 165px;
}

.w-\[16px\] {
  width: 16px;
}

.w-\[175px\] {
  width: 175px;
}

.w-\[18px\] {
  width: 18px;
}

.w-\[19px\] {
  width: 19px;
}

.w-\[20\%\] {
  width: 20%;
}

.w-\[200px\] {
  width: 200px;
}

.w-\[20px\] {
  width: 20px;
}

.w-\[210px\] {
  width: 210px;
}

.w-\[230px\] {
  width: 230px;
}

.w-\[24px\] {
  width: 24px;
}

.w-\[25\%\] {
  width: 25%;
}

.w-\[250px\] {
  width: 250px;
}

.w-\[25px\] {
  width: 25px;
}

.w-\[260px\] {
  width: 260px;
}

.w-\[280px\] {
  width: 280px;
}

.w-\[29px\] {
  width: 29px;
}

.w-\[2px\] {
  width: 2px;
}

.w-\[30px\] {
  width: 30px;
}

.w-\[320px\] {
  width: 320px;
}

.w-\[350px\] {
  width: 350px;
}

.w-\[35px\] {
  width: 35px;
}

.w-\[380px\] {
  width: 380px;
}

.w-\[400\%\] {
  width: 400%;
}

.w-\[40px\] {
  width: 40px;
}

.w-\[450px\] {
  width: 450px;
}

.w-\[45px\] {
  width: 45px;
}

.w-\[48px\] {
  width: 48px;
}

.w-\[500px\] {
  width: 500px;
}

.w-\[50px\] {
  width: 50px;
}

.w-\[50vw\] {
  width: 50vw;
}

.w-\[60\%\] {
  width: 60%;
}

.w-\[60px\] {
  width: 60px;
}

.w-\[64px\] {
  width: 64px;
}

.w-\[70\%\] {
  width: 70%;
}

.w-\[70px\] {
  width: 70px;
}

.w-\[70vw\] {
  width: 70vw;
}

.w-\[75\%\] {
  width: 75%;
}

.w-\[75px\] {
  width: 75px;
}

.w-\[80\%\] {
  width: 80%;
}

.w-\[800px\] {
  width: 800px;
}

.w-\[80vw\] {
  width: 80vw;
}

.w-\[85\%\] {
  width: 85%;
}

.w-\[90\%\] {
  width: 90%;
}

.w-\[90px\] {
  width: 90px;
}

.w-\[92\%\] {
  width: 92%;
}

.w-\[94px\] {
  width: 94px;
}

.w-\[99\%\] {
  width: 99%;
}

.w-\[calc\(100\%\+110px\)\] {
  width: calc(100% + 110px);
}

.w-\[calc\(100\%-100px\)\] {
  width: calc(100% - 100px);
}

.w-\[calc\(100vw-3rem\)\] {
  width: calc(100vw - 3rem);
}

.w-\[calc\(100vw-48px\)\] {
  width: calc(100vw - 48px);
}

.w-auto {
  width: auto;
}

.w-fit {
  width: fit-content;
}

.w-full {
  width: 100%;
}

.w-screen {
  width: 100vw;
}

.min-w-\[100px\] {
  min-width: 100px;
}

.min-w-\[180px\] {
  min-width: 180px;
}

.min-w-\[250px\] {
  min-width: 250px;
}

.min-w-\[284px\] {
  min-width: 284px;
}

.min-w-\[340px\] {
  min-width: 340px;
}

.min-w-\[65vw\] {
  min-width: 65vw;
}

.min-w-full {
  min-width: 100%;
}

.min-w-min {
  min-width: min-content;
}

.max-w-2xl {
  max-width: 42rem;
}

.max-w-3xl {
  max-width: 48rem;
}

.max-w-4xl {
  max-width: 56rem;
}

.max-w-6xl {
  max-width: 72rem;
}

.max-w-\[100px\] {
  max-width: 100px;
}

.max-w-\[1100px\] {
  max-width: 1100px;
}

.max-w-\[150px\] {
  max-width: 150px;
}

.max-w-\[160px\] {
  max-width: 160px;
}

.max-w-\[182px\] {
  max-width: 182px;
}

.max-w-\[200px\] {
  max-width: 200px;
}

.max-w-\[2048px\] {
  max-width: 2048px;
}

.max-w-\[240px\] {
  max-width: 240px;
}

.max-w-\[287px\] {
  max-width: 287px;
}

.max-w-\[300px\] {
  max-width: 300px;
}

.max-w-\[350px\] {
  max-width: 350px;
}

.max-w-\[400px\] {
  max-width: 400px;
}

.max-w-\[500px\] {
  max-width: 500px;
}

.max-w-\[600px\] {
  max-width: 600px;
}

.max-w-\[620px\] {
  max-width: 620px;
}

.max-w-\[690px\] {
  max-width: 690px;
}

.max-w-\[700px\] {
  max-width: 700px;
}

.max-w-\[70px\] {
  max-width: 70px;
}

.max-w-\[768px\] {
  max-width: 768px;
}

.max-w-\[780px\] {
  max-width: 780px;
}

.max-w-\[80\%\] {
  max-width: 80%;
}

.max-w-\[800px\] {
  max-width: 800px;
}

.max-w-full {
  max-width: 100%;
}

.max-w-lg {
  max-width: 32rem;
}

.max-w-md {
  max-width: 28rem;
}

.max-w-sm {
  max-width: 24rem;
}

.max-w-xl {
  max-width: 36rem;
}

.flex-1 {
  flex: 1 1 0%;
}

.flex-auto {
  flex: 1 1 auto;
}

.flex-none {
  flex: none;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.shrink {
  flex-shrink: 1;
}

.shrink-0 {
  flex-shrink: 0;
}

.grow {
  flex-grow: 1;
}

.grow-0 {
  flex-grow: 0;
}

.basis-4\/12 {
  flex-basis: 33.333333%;
}

.basis-72 {
  flex-basis: 18rem;
}

.basis-8\/12 {
  flex-basis: 66.666667%;
}

.basis-\[50px\] {
  flex-basis: 50px;
}

.basis-full {
  flex-basis: 100%;
}

.table-fixed {
  table-layout: fixed;
}

.border-collapse {
  border-collapse: collapse;
}

.border-spacing-0 {
  --tw-border-spacing-x: 0px;
  --tw-border-spacing-y: 0px;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.origin-center {
  transform-origin: center;
}

.origin-left {
  transform-origin: left;
}

.-translate-x-1\/2 {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-x-\[65\%\] {
  --tw-translate-x: -65%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-1\/2 {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-\[5px\] {
  --tw-translate-y: -5px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-full {
  --tw-translate-y: -100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-1\/2 {
  --tw-translate-x: 50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-1\/4 {
  --tw-translate-x: 25%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-44 {
  --tw-translate-x: 11rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-9 {
  --tw-translate-y: 2.25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-\[-100px\] {
  --tw-translate-y: -100px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-full {
  --tw-translate-y: 100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-px {
  --tw-translate-y: 1px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-0 {
  --tw-rotate: 0deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-180 {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-45 {
  --tw-rotate: 45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-90 {
  --tw-rotate: 90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-\[-45deg\] {
  --tw-rotate: -45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-105 {
  --tw-scale-x: 1.05;
  --tw-scale-y: 1.05;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-110 {
  --tw-scale-x: 1.1;
  --tw-scale-y: 1.1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-75 {
  --tw-scale-x: .75;
  --tw-scale-y: .75;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-95 {
  --tw-scale-x: .95;
  --tw-scale-y: .95;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-\[0\.6\] {
  --tw-scale-x: 0.6;
  --tw-scale-y: 0.6;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-\[1\.3\] {
  --tw-scale-x: 1.3;
  --tw-scale-y: 1.3;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-\[1\.5\] {
  --tw-scale-x: 1.5;
  --tw-scale-y: 1.5;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-\[2\] {
  --tw-scale-x: 2;
  --tw-scale-y: 2;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-x-0 {
  --tw-scale-x: 0;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@keyframes natashaIdle {
  0% {
    box-shadow: 0 0 0 8px #b388ff, 0 0 0 40px #f3ebff;
  }

  100% {
    box-shadow: 0 0 0 15px #b388ff, 0 0 0 25px #f3ebff;
  }
}

.animate-\[natashaIdle_1\.5s_infinite_ease-in-out_alternate\] {
  animation: natashaIdle 1.5s infinite ease-in-out alternate;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: scale(0.8);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.animate-fadein {
  animation: fadeIn 1s ease-in-out forwards;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(var(--translate-x, calc(-50%)));
  }
}

.animate-slow-infinite-scroll {
  animation: scroll var(--animation-duration, 40s) linear infinite;
}

.cursor-default {
  cursor: default;
}

.cursor-pointer {
  cursor: pointer;
}

.resize {
  resize: both;
}

.scroll-mt-0 {
  scroll-margin-top: 0px;
}

.scroll-mt-20 {
  scroll-margin-top: 5rem;
}

.scroll-pt-0 {
  scroll-padding-top: 0px;
}

.list-inside {
  list-style-position: inside;
}

.list-disc {
  list-style-type: disc;
}

.list-none {
  list-style-type: none;
}

.auto-cols-\[minmax\(50px\2c 1fr\)\] {
  grid-auto-columns: minmax(50px,1fr);
}

.auto-cols-fr {
  grid-auto-columns: minmax(0, 1fr);
}

.auto-cols-max {
  grid-auto-columns: max-content;
}

.grid-flow-row {
  grid-auto-flow: row;
}

.grid-flow-col {
  grid-auto-flow: column;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-cols-10 {
  grid-template-columns: repeat(10, minmax(0, 1fr));
}

.grid-cols-12 {
  grid-template-columns: repeat(12, minmax(0, 1fr));
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.grid-cols-6 {
  grid-template-columns: repeat(6, minmax(0, 1fr));
}

.grid-cols-\[2fr_2fr\] {
  grid-template-columns: 2fr 2fr;
}

.grid-cols-\[50px_auto\] {
  grid-template-columns: 50px auto;
}

.grid-rows-1 {
  grid-template-rows: repeat(1, minmax(0, 1fr));
}

.grid-rows-2 {
  grid-template-rows: repeat(2, minmax(0, 1fr));
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-col-reverse {
  flex-direction: column-reverse;
}

.flex-wrap {
  flex-wrap: wrap;
}

.place-content-center {
  place-content: center;
}

.place-items-center {
  place-items: center;
}

.content-center {
  align-content: center;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.items-baseline {
  align-items: baseline;
}

.items-stretch {
  align-items: stretch;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-items-center {
  justify-items: center;
}

.justify-items-stretch {
  justify-items: stretch;
}

.gap-1 {
  gap: 0.25rem;
}

.gap-1\.5 {
  gap: 0.375rem;
}

.gap-10 {
  gap: 2.5rem;
}

.gap-12 {
  gap: 3rem;
}

.gap-15 {
  gap: 3.75rem;
}

.gap-16 {
  gap: 4rem;
}

.gap-18 {
  gap: 4.5rem;
}

.gap-2 {
  gap: 0.5rem;
}

.gap-24 {
  gap: 6rem;
}

.gap-3 {
  gap: 0.75rem;
}

.gap-32 {
  gap: 8rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-5 {
  gap: 1.25rem;
}

.gap-6 {
  gap: 1.5rem;
}

.gap-7 {
  gap: 1.75rem;
}

.gap-8 {
  gap: 2rem;
}

.gap-x-10 {
  column-gap: 2.5rem;
}

.gap-x-12 {
  column-gap: 3rem;
}

.gap-x-2 {
  column-gap: 0.5rem;
}

.gap-x-24 {
  column-gap: 6rem;
}

.gap-x-4 {
  column-gap: 1rem;
}

.gap-x-5 {
  column-gap: 1.25rem;
}

.gap-x-6 {
  column-gap: 1.5rem;
}

.gap-y-1 {
  row-gap: 0.25rem;
}

.gap-y-10 {
  row-gap: 2.5rem;
}

.gap-y-12 {
  row-gap: 3rem;
}

.gap-y-2 {
  row-gap: 0.5rem;
}

.gap-y-24 {
  row-gap: 6rem;
}

.gap-y-4 {
  row-gap: 1rem;
}

.gap-y-5 {
  row-gap: 1.25rem;
}

.gap-y-6 {
  row-gap: 1.5rem;
}

.gap-y-8 {
  row-gap: 2rem;
}

.space-x-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.25rem * var(--tw-space-x-reverse));
  margin-left: calc(0.25rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.25rem * var(--tw-space-x-reverse));
  margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.self-start {
  align-self: flex-start;
}

.self-center {
  align-self: center;
}

.justify-self-start {
  justify-self: start;
}

.justify-self-end {
  justify-self: end;
}

.justify-self-center {
  justify-self: center;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-visible {
  overflow: visible;
}

.overflow-scroll {
  overflow: scroll;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-x-clip {
  overflow-x: clip;
}

.overflow-x-scroll {
  overflow-x: scroll;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.scroll-auto {
  scroll-behavior: auto;
}

.text-ellipsis {
  text-overflow: ellipsis;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.\!rounded-\[40px\] {
  border-radius: 40px !important;
}

.rounded {
  border-radius: 0.25rem;
}

.rounded-2xl {
  border-radius: 1rem;
}

.rounded-3xl {
  border-radius: 1.5rem;
}

.rounded-\[100\%\] {
  border-radius: 100%;
}

.rounded-\[10px\] {
  border-radius: 10px;
}

.rounded-\[20px\] {
  border-radius: 20px;
}

.rounded-\[3px\] {
  border-radius: 3px;
}

.rounded-\[3rem\] {
  border-radius: 3rem;
}

.rounded-\[50px\] {
  border-radius: 50px;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-lg {
  border-radius: 0.5rem;
}

.rounded-md {
  border-radius: 0.375rem;
}

.rounded-none {
  border-radius: 0px;
}

.rounded-sm {
  border-radius: 0.125rem;
}

.rounded-xl {
  border-radius: 0.75rem;
}

.rounded-b-\[10px\] {
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.rounded-b-lg {
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.rounded-b-none {
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}

.rounded-b-xl {
  border-bottom-right-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem;
}

.rounded-l-\[170px\] {
  border-top-left-radius: 170px;
  border-bottom-left-radius: 170px;
}

.rounded-l-full {
  border-top-left-radius: 9999px;
  border-bottom-left-radius: 9999px;
}

.rounded-l-sm {
  border-top-left-radius: 0.125rem;
  border-bottom-left-radius: 0.125rem;
}

.rounded-r-full {
  border-top-right-radius: 9999px;
  border-bottom-right-radius: 9999px;
}

.rounded-r-md {
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}

.rounded-t-2xl {
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}

.rounded-t-full {
  border-top-left-radius: 9999px;
  border-top-right-radius: 9999px;
}

.rounded-t-lg {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.rounded-t-none {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.rounded-t-xl {
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
}

.rounded-bl-lg {
  border-bottom-left-radius: 0.5rem;
}

.rounded-br-lg {
  border-bottom-right-radius: 0.5rem;
}

.rounded-tl-2xl {
  border-top-left-radius: 1rem;
}

.rounded-tl-\[100px\] {
  border-top-left-radius: 100px;
}

.rounded-tl-lg {
  border-top-left-radius: 0.5rem;
}

.rounded-tr-2xl {
  border-top-right-radius: 1rem;
}

.rounded-tr-\[20\%\] {
  border-top-right-radius: 20%;
}

.rounded-tr-lg {
  border-top-right-radius: 0.5rem;
}

.border {
  border-width: 1px;
}

.border-0 {
  border-width: 0px;
}

.border-2 {
  border-width: 2px;
}

.border-4 {
  border-width: 4px;
}

.border-\[0\.5px\] {
  border-width: 0.5px;
}

.border-\[1\.5px\] {
  border-width: 1.5px;
}

.border-\[1px\] {
  border-width: 1px;
}

.border-\[2\.5px\] {
  border-width: 2.5px;
}

.border-\[3px\] {
  border-width: 3px;
}

.border-\[6px\] {
  border-width: 6px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-b-2 {
  border-bottom-width: 2px;
}

.border-b-\[1px\] {
  border-bottom-width: 1px;
}

.border-b-\[36px\] {
  border-bottom-width: 36px;
}

.border-l-2 {
  border-left-width: 2px;
}

.border-l-4 {
  border-left-width: 4px;
}

.border-l-\[3px\] {
  border-left-width: 3px;
}

.border-l-\[6px\] {
  border-left-width: 6px;
}

.border-r-2 {
  border-right-width: 2px;
}

.border-t {
  border-top-width: 1px;
}

.border-t-2 {
  border-top-width: 2px;
}

.border-t-\[12px\] {
  border-top-width: 12px;
}

.border-t-\[1px\] {
  border-top-width: 1px;
}

.border-t-\[6px\] {
  border-top-width: 6px;
}

.border-solid {
  border-style: solid;
}

.border-dashed {
  border-style: dashed;
}

.\!border-none {
  border-style: none !important;
}

.border-none {
  border-style: none;
}

.border-\[\#A63CFD\] {
  --tw-border-opacity: 1;
  border-color: rgb(166 60 253 / var(--tw-border-opacity, 1));
}

.border-\[\#D9D9D9\] {
  --tw-border-opacity: 1;
  border-color: rgb(217 217 217 / var(--tw-border-opacity, 1));
}

.border-black {
  --tw-border-opacity: 1;
  border-color: rgb(0 0 0 / var(--tw-border-opacity, 1));
}

.border-blue-200 {
  --tw-border-opacity: 1;
  border-color: rgb(140 158 255 / var(--tw-border-opacity, 1));
}

.border-blueGray-200 {
  --tw-border-opacity: 1;
  border-color: rgb(236 237 240 / var(--tw-border-opacity, 1));
}

.border-blueGray-300 {
  --tw-border-opacity: 1;
  border-color: rgb(223 224 230 / var(--tw-border-opacity, 1));
}

.border-blueGray-400 {
  --tw-border-opacity: 1;
  border-color: rgb(208 210 218 / var(--tw-border-opacity, 1));
}

.border-blueGray-600 {
  --tw-border-opacity: 1;
  border-color: rgb(175 178 192 / var(--tw-border-opacity, 1));
}

.border-blueGray-800 {
  --tw-border-opacity: 1;
  border-color: rgb(131 136 158 / var(--tw-border-opacity, 1));
}

.border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgb(232 232 232 / var(--tw-border-opacity, 1));
}

.border-gray-400 {
  --tw-border-opacity: 1;
  border-color: rgb(217 217 217 / var(--tw-border-opacity, 1));
}

.border-green-400 {
  --tw-border-opacity: 1;
  border-color: rgb(0 230 118 / var(--tw-border-opacity, 1));
}

.border-green-500 {
  --tw-border-opacity: 1;
  border-color: rgb(0 214 89 / var(--tw-border-opacity, 1));
}

.border-green-600 {
  --tw-border-opacity: 1;
  border-color: rgb(67 160 71 / var(--tw-border-opacity, 1));
}

.border-hotPink {
  --tw-border-opacity: 1;
  border-color: rgb(255 0 179 / var(--tw-border-opacity, 1));
}

.border-purple-100 {
  --tw-border-opacity: 1;
  border-color: rgb(243 235 255 / var(--tw-border-opacity, 1));
}

.border-purple-200 {
  --tw-border-opacity: 1;
  border-color: rgb(179 136 255 / var(--tw-border-opacity, 1));
}

.border-purple-300 {
  --tw-border-opacity: 1;
  border-color: rgb(124 77 255 / var(--tw-border-opacity, 1));
}

.border-purple-400 {
  --tw-border-opacity: 1;
  border-color: rgb(101 31 255 / var(--tw-border-opacity, 1));
}

.border-purple-500 {
  --tw-border-opacity: 1;
  border-color: rgb(98 0 234 / var(--tw-border-opacity, 1));
}

.border-white {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity, 1));
}

.border-x-gray-400 {
  --tw-border-opacity: 1;
  border-left-color: rgb(217 217 217 / var(--tw-border-opacity, 1));
  border-right-color: rgb(217 217 217 / var(--tw-border-opacity, 1));
}

.border-x-purple-100 {
  --tw-border-opacity: 1;
  border-left-color: rgb(243 235 255 / var(--tw-border-opacity, 1));
  border-right-color: rgb(243 235 255 / var(--tw-border-opacity, 1));
}

.border-y-gray-400 {
  --tw-border-opacity: 1;
  border-top-color: rgb(217 217 217 / var(--tw-border-opacity, 1));
  border-bottom-color: rgb(217 217 217 / var(--tw-border-opacity, 1));
}

.border-b-blueGray-200 {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(236 237 240 / var(--tw-border-opacity, 1));
}

.border-b-blueGray-300 {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(223 224 230 / var(--tw-border-opacity, 1));
}

.border-b-green-500 {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(0 214 89 / var(--tw-border-opacity, 1));
}

.border-b-purple-400 {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(101 31 255 / var(--tw-border-opacity, 1));
}

.\!bg-\[\#A63CFD\] {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(166 60 253 / var(--tw-bg-opacity, 1)) !important;
}

.bg-\[\#0f0d26\] {
  --tw-bg-opacity: 1;
  background-color: rgb(15 13 38 / var(--tw-bg-opacity, 1));
}

.bg-\[\#5ADDE7\] {
  --tw-bg-opacity: 1;
  background-color: rgb(90 221 231 / var(--tw-bg-opacity, 1));
}

.bg-\[\#6200EA\] {
  --tw-bg-opacity: 1;
  background-color: rgb(98 0 234 / var(--tw-bg-opacity, 1));
}

.bg-\[\#69F0AE\] {
  --tw-bg-opacity: 1;
  background-color: rgb(105 240 174 / var(--tw-bg-opacity, 1));
}

.bg-\[\#6C00EA\] {
  --tw-bg-opacity: 1;
  background-color: rgb(108 0 234 / var(--tw-bg-opacity, 1));
}

.bg-\[\#A63CFD\] {
  --tw-bg-opacity: 1;
  background-color: rgb(166 60 253 / var(--tw-bg-opacity, 1));
}

.bg-\[\#D9C4FF\] {
  --tw-bg-opacity: 1;
  background-color: rgb(217 196 255 / var(--tw-bg-opacity, 1));
}

.bg-\[\#E8FD3C\] {
  --tw-bg-opacity: 1;
  background-color: rgb(232 253 60 / var(--tw-bg-opacity, 1));
}

.bg-\[\#E9E5D1\] {
  --tw-bg-opacity: 1;
  background-color: rgb(233 229 209 / var(--tw-bg-opacity, 1));
}

.bg-\[\#EDE9D5\] {
  --tw-bg-opacity: 1;
  background-color: rgb(237 233 213 / var(--tw-bg-opacity, 1));
}

.bg-\[\#F1F1FA\] {
  --tw-bg-opacity: 1;
  background-color: rgb(241 241 250 / var(--tw-bg-opacity, 1));
}

.bg-\[\#F3EBFF\] {
  --tw-bg-opacity: 1;
  background-color: rgb(243 235 255 / var(--tw-bg-opacity, 1));
}

.bg-\[\#FAFBFC\] {
  --tw-bg-opacity: 1;
  background-color: rgb(250 251 252 / var(--tw-bg-opacity, 1));
}

.bg-\[\#FBF8FF\] {
  --tw-bg-opacity: 1;
  background-color: rgb(251 248 255 / var(--tw-bg-opacity, 1));
}

.bg-\[\#FFE57F\] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 229 127 / var(--tw-bg-opacity, 1));
}

.bg-\[\#eae0f8\] {
  --tw-bg-opacity: 1;
  background-color: rgb(234 224 248 / var(--tw-bg-opacity, 1));
}

.bg-\[\#eeeeee\] {
  --tw-bg-opacity: 1;
  background-color: rgb(238 238 238 / var(--tw-bg-opacity, 1));
}

.bg-\[\#f3e4f6\] {
  --tw-bg-opacity: 1;
  background-color: rgb(243 228 246 / var(--tw-bg-opacity, 1));
}

.bg-\[\#f8f9fa\] {
  --tw-bg-opacity: 1;
  background-color: rgb(248 249 250 / var(--tw-bg-opacity, 1));
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity, 1));
}

.bg-blue-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 255 / var(--tw-bg-opacity, 1));
}

.bg-blue-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(140 158 255 / var(--tw-bg-opacity, 1));
}

.bg-blue-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(83 109 254 / var(--tw-bg-opacity, 1));
}

.bg-blueGray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(248 249 250 / var(--tw-bg-opacity, 1));
}

.bg-blueGray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(236 237 240 / var(--tw-bg-opacity, 1));
}

.bg-blueGray-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(223 224 230 / var(--tw-bg-opacity, 1));
}

.bg-blueGray-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(208 210 218 / var(--tw-bg-opacity, 1));
}

.bg-blueGray-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(103 107 126 / var(--tw-bg-opacity, 1));
}

.bg-darkPurple-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(81 45 168 / var(--tw-bg-opacity, 1));
}

.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(250 250 250 / var(--tw-bg-opacity, 1));
}

.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(245 245 245 / var(--tw-bg-opacity, 1));
}

.bg-gray-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(232 232 232 / var(--tw-bg-opacity, 1));
}

.bg-gray-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(217 217 217 / var(--tw-bg-opacity, 1));
}

.bg-gray-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(140 140 140 / var(--tw-bg-opacity, 1));
}

.bg-gray-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(38 38 38 / var(--tw-bg-opacity, 1));
}

.bg-green-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(226 252 234 / var(--tw-bg-opacity, 1));
}

.bg-green-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(185 246 202 / var(--tw-bg-opacity, 1));
}

.bg-green-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(0 230 118 / var(--tw-bg-opacity, 1));
}

.bg-green-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(0 214 89 / var(--tw-bg-opacity, 1));
}

.bg-hotPink {
  --tw-bg-opacity: 1;
  background-color: rgb(255 0 179 / var(--tw-bg-opacity, 1));
}

.bg-purple-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 235 255 / var(--tw-bg-opacity, 1));
}

.bg-purple-150 {
  --tw-bg-opacity: 1;
  background-color: rgb(236 223 255 / var(--tw-bg-opacity, 1));
}

.bg-purple-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(179 136 255 / var(--tw-bg-opacity, 1));
}

.bg-purple-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(124 77 255 / var(--tw-bg-opacity, 1));
}

.bg-purple-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(101 31 255 / var(--tw-bg-opacity, 1));
}

.bg-purple-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(98 0 234 / var(--tw-bg-opacity, 1));
}

.bg-red-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 179 172 / var(--tw-bg-opacity, 1));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
}

.bg-yellow-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 249 225 / var(--tw-bg-opacity, 1));
}

.bg-yellow-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 229 127 / var(--tw-bg-opacity, 1));
}

.bg-opacity-75 {
  --tw-bg-opacity: 0.75;
}

.bg-\[linear-gradient\(120\.8deg\2c \#F5EFFF_0\%\2c \#FDEDEC_100\%\)\] {
  background-image: linear-gradient(120.8deg,#F5EFFF 0%,#FDEDEC 100%);
}

.bg-\[url\(\'\/images\/bg-hero-banner-desktop\.png\'\)\] {
  background-image: url('/images/bg-hero-banner-desktop.png');
}

.bg-\[url\(\'\/images\/bg-hero-banner-mobile\.png\'\)\] {
  background-image: url('/images/bg-hero-banner-mobile.png');
}

.bg-\[url\(\'\/images\/purple-bg\.svg\'\)\] {
  background-image: url('/images/purple-bg.svg');
}

.bg-gradient-to-b {
  background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
}

.bg-gradient-to-br {
  background-image: linear-gradient(to bottom right, var(--tw-gradient-stops));
}

.bg-gradient-to-l {
  background-image: linear-gradient(to left, var(--tw-gradient-stops));
}

.bg-gradient-to-r {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
}

.bg-gradient-to-t {
  background-image: linear-gradient(to top, var(--tw-gradient-stops));
}

.bg-none {
  background-image: none;
}

.from-\[\#00000000\] {
  --tw-gradient-from: #00000000 var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(0 0 0 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-\[\#00B0FF\] {
  --tw-gradient-from: #00B0FF var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(0 176 255 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-\[\#00E676\] {
  --tw-gradient-from: #00E676 var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(0 230 118 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-\[\#040848\] {
  --tw-gradient-from: #040848 var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(4 8 72 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-\[\#8833FF\] {
  --tw-gradient-from: #8833FF var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(136 51 255 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-\[\#AA00FF\] {
  --tw-gradient-from: #AA00FF var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(170 0 255 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-\[\#B54AFA\] {
  --tw-gradient-from: #B54AFA var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(181 74 250 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-\[\#F5EFFF\] {
  --tw-gradient-from: #F5EFFF var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(245 239 255 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-\[\#FF80AB\] {
  --tw-gradient-from: #FF80AB var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(255 128 171 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-\[\#FFFF00\] {
  --tw-gradient-from: #FFFF00 var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(255 255 0 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-\[\#f4eefc\] {
  --tw-gradient-from: #f4eefc var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(244 238 252 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-\[transparent\] {
  --tw-gradient-from: transparent var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(0 0 0 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-blueGray-100 {
  --tw-gradient-from: #F8F9FA var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(248 249 250 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-200 {
  --tw-gradient-from: #B388FF var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(179 136 255 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-300 {
  --tw-gradient-from: #7C4DFF var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(124 77 255 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-white {
  --tw-gradient-from: #FFFFFF var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(255 255 255 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-0\% {
  --tw-gradient-from-position: 0%;
}

.to-\[\#16268d\] {
  --tw-gradient-to: #16268d var(--tw-gradient-to-position);
}

.to-\[\#3b247b\] {
  --tw-gradient-to: #3b247b var(--tw-gradient-to-position);
}

.to-\[\#6933E8\] {
  --tw-gradient-to: #6933E8 var(--tw-gradient-to-position);
}

.to-\[\#C399FF\] {
  --tw-gradient-to: #C399FF var(--tw-gradient-to-position);
}

.to-\[\#FDEDEC\] {
  --tw-gradient-to: #FDEDEC var(--tw-gradient-to-position);
}

.to-\[\#faeeec\] {
  --tw-gradient-to: #faeeec var(--tw-gradient-to-position);
}

.to-black {
  --tw-gradient-to: #000000 var(--tw-gradient-to-position);
}

.to-purple-100 {
  --tw-gradient-to: #F3EBFF var(--tw-gradient-to-position);
}

.to-white {
  --tw-gradient-to: #FFFFFF var(--tw-gradient-to-position);
}

.to-100\% {
  --tw-gradient-to-position: 100%;
}

.bg-cover {
  background-size: cover;
}

.bg-clip-padding {
  background-clip: padding-box;
}

.bg-clip-text {
  background-clip: text;
}

.bg-bottom {
  background-position: bottom;
}

.bg-center {
  background-position: center;
}

.bg-left {
  background-position: left;
}

.bg-no-repeat {
  background-repeat: no-repeat;
}

.bg-origin-padding {
  background-origin: padding-box;
}

.fill-purple-200 {
  fill: #B388FF;
}

.fill-purple-500 {
  fill: #6200EA;
}

.fill-white {
  fill: #FFFFFF;
}

.stroke-white {
  stroke: #FFFFFF;
}

.stroke-1 {
  stroke-width: 1;
}

.stroke-2 {
  stroke-width: 2;
}

.stroke-\[3\] {
  stroke-width: 3;
}

.\!object-contain {
  object-fit: contain !important;
}

.object-contain {
  object-fit: contain;
}

.object-cover {
  object-fit: cover;
}

.object-fill {
  object-fit: fill;
}

.object-scale-down {
  object-fit: scale-down;
}

.object-center {
  object-position: center;
}

.object-left {
  object-position: left;
}

.object-left-bottom {
  object-position: left bottom;
}

.object-top {
  object-position: top;
}

.p-0 {
  padding: 0px;
}

.p-1 {
  padding: 0.25rem;
}

.p-10 {
  padding: 2.5rem;
}

.p-11 {
  padding: 2.75rem;
}

.p-12 {
  padding: 3rem;
}

.p-18 {
  padding: 4.5rem;
}

.p-2 {
  padding: 0.5rem;
}

.p-3 {
  padding: 0.75rem;
}

.p-4 {
  padding: 1rem;
}

.p-5 {
  padding: 1.25rem;
}

.p-6 {
  padding: 1.5rem;
}

.p-7 {
  padding: 1.75rem;
}

.p-8 {
  padding: 2rem;
}

.p-\[1px\] {
  padding: 1px;
}

.\!px-0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.\!px-16 {
  padding-left: 4rem !important;
  padding-right: 4rem !important;
}

.\!py-8 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.px-0 {
  padding-left: 0px;
  padding-right: 0px;
}

.px-1\.5 {
  padding-left: 0.375rem;
  padding-right: 0.375rem;
}

.px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.px-12 {
  padding-left: 3rem;
  padding-right: 3rem;
}

.px-14 {
  padding-left: 3.5rem;
  padding-right: 3.5rem;
}

.px-16 {
  padding-left: 4rem;
  padding-right: 4rem;
}

.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.px-20 {
  padding-left: 5rem;
  padding-right: 5rem;
}

.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-7 {
  padding-left: 1.75rem;
  padding-right: 1.75rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.px-\[10px\] {
  padding-left: 10px;
  padding-right: 10px;
}

.py-0 {
  padding-top: 0px;
  padding-bottom: 0px;
}

.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.py-14 {
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;
}

.py-15 {
  padding-top: 3.75rem;
  padding-bottom: 3.75rem;
}

.py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.py-18 {
  padding-top: 4.5rem;
  padding-bottom: 4.5rem;
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.py-2\.5 {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
}

.py-20 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.py-24 {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.py-7 {
  padding-top: 1.75rem;
  padding-bottom: 1.75rem;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.py-9 {
  padding-top: 2.25rem;
  padding-bottom: 2.25rem;
}

.py-\[10px\] {
  padding-top: 10px;
  padding-bottom: 10px;
}

.\!pl-14 {
  padding-left: 3.5rem !important;
}

.\!pl-16 {
  padding-left: 4rem !important;
}

.\!pt-0 {
  padding-top: 0px !important;
}

.pb-0 {
  padding-bottom: 0px;
}

.pb-0\.5 {
  padding-bottom: 0.125rem;
}

.pb-1 {
  padding-bottom: 0.25rem;
}

.pb-10 {
  padding-bottom: 2.5rem;
}

.pb-12 {
  padding-bottom: 3rem;
}

.pb-14 {
  padding-bottom: 3.5rem;
}

.pb-16 {
  padding-bottom: 4rem;
}

.pb-2 {
  padding-bottom: 0.5rem;
}

.pb-20 {
  padding-bottom: 5rem;
}

.pb-24 {
  padding-bottom: 6rem;
}

.pb-28 {
  padding-bottom: 7rem;
}

.pb-32 {
  padding-bottom: 8rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pb-40 {
  padding-bottom: 10rem;
}

.pb-5 {
  padding-bottom: 1.25rem;
}

.pb-6 {
  padding-bottom: 1.5rem;
}

.pb-60 {
  padding-bottom: 15rem;
}

.pb-8 {
  padding-bottom: 2rem;
}

.pb-\[200px\] {
  padding-bottom: 200px;
}

.pb-\[204px\] {
  padding-bottom: 204px;
}

.pb-\[3px\] {
  padding-bottom: 3px;
}

.pb-\[80\%\] {
  padding-bottom: 80%;
}

.pl-0 {
  padding-left: 0px;
}

.pl-1 {
  padding-left: 0.25rem;
}

.pl-14 {
  padding-left: 3.5rem;
}

.pl-2 {
  padding-left: 0.5rem;
}

.pl-24 {
  padding-left: 6rem;
}

.pl-4 {
  padding-left: 1rem;
}

.pl-5 {
  padding-left: 1.25rem;
}

.pl-6 {
  padding-left: 1.5rem;
}

.pl-8 {
  padding-left: 2rem;
}

.pl-\[20px\] {
  padding-left: 20px;
}

.pr-0 {
  padding-right: 0px;
}

.pr-10 {
  padding-right: 2.5rem;
}

.pr-14 {
  padding-right: 3.5rem;
}

.pr-2 {
  padding-right: 0.5rem;
}

.pr-3 {
  padding-right: 0.75rem;
}

.pr-4 {
  padding-right: 1rem;
}

.pr-6 {
  padding-right: 1.5rem;
}

.pr-8 {
  padding-right: 2rem;
}

.pt-0 {
  padding-top: 0px;
}

.pt-1 {
  padding-top: 0.25rem;
}

.pt-10 {
  padding-top: 2.5rem;
}

.pt-12 {
  padding-top: 3rem;
}

.pt-14 {
  padding-top: 3.5rem;
}

.pt-15 {
  padding-top: 3.75rem;
}

.pt-156 {
  padding-top: 39rem;
}

.pt-16 {
  padding-top: 4rem;
}

.pt-18 {
  padding-top: 4.5rem;
}

.pt-2 {
  padding-top: 0.5rem;
}

.pt-20 {
  padding-top: 5rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pt-5 {
  padding-top: 1.25rem;
}

.pt-6 {
  padding-top: 1.5rem;
}

.pt-8 {
  padding-top: 2rem;
}

.pt-\[50px\] {
  padding-top: 50px;
}

.pt-\[52px\] {
  padding-top: 52px;
}

.pt-\[5px\] {
  padding-top: 5px;
}

.pt-\[75\.5\%\] {
  padding-top: 75.5%;
}

.pt-\[calc\(566\.5\%\+56px\)\] {
  padding-top: calc(566.5% + 56px);
}

.\!text-left {
  text-align: left !important;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.align-top {
  vertical-align: top;
}

.align-middle {
  vertical-align: middle;
}

.align-text-bottom {
  vertical-align: text-bottom;
}

.\!text-2xl-heading {
  font-size: 2rem !important;
  line-height: 2.25rem !important;
  letter-spacing: -0.04em !important;
}

.\!text-4xl-copy {
  font-size: 2.25rem !important;
  line-height: 2.5rem !important;
}

.\!text-5xl-copy {
  font-size: 3rem !important;
  line-height: 1 !important;
}

.\!text-6xl-copy {
  font-size: 3.75rem !important;
  line-height: 1 !important;
}

.\!text-8xl-copy {
  font-size: 6rem !important;
  line-height: 1 !important;
}

.\!text-\[15px\] {
  font-size: 15px !important;
}

.\!text-\[22px\] {
  font-size: 22px !important;
}

.text-2xl-copy {
  font-size: 2rem;
  line-height: 2.25rem;
}

.text-2xl-heading {
  font-size: 2rem;
  line-height: 2.25rem;
  letter-spacing: -0.04em;
}

.text-3xl-heading {
  font-size: 2.25rem;
  line-height: 2.5rem;
  letter-spacing: -0.04em;
}

.text-4xl-copy {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-4xl-heading {
  font-size: 2.75rem;
  line-height: 3rem;
  letter-spacing: -0.04em;
}

.text-5xl-heading {
  font-size: 3.5rem;
  line-height: 3.75rem;
  letter-spacing: -0.04em;
}

.text-\[12px\] {
  font-size: 12px;
}

.text-\[14px\] {
  font-size: 14px;
}

.text-\[160px\] {
  font-size: 160px;
}

.text-\[16px\] {
  font-size: 16px;
}

.text-\[18px\] {
  font-size: 18px;
}

.text-\[20px\] {
  font-size: 20px;
}

.text-\[22px\] {
  font-size: 22px;
}

.text-\[24px\] {
  font-size: 24px;
}

.text-\[32px\] {
  font-size: 32px;
}

.text-\[36px\] {
  font-size: 36px;
}

.text-\[42px\] {
  font-size: 42px;
}

.text-\[44px\] {
  font-size: 44px;
}

.text-\[52px\] {
  font-size: 52px;
}

.text-\[54px\] {
  font-size: 54px;
}

.text-\[64px\] {
  font-size: 64px;
}

.text-\[8px\] {
  font-size: 8px;
}

.text-\[9px\] {
  font-size: 9px;
}

.text-lg-copy {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-lg-heading {
  font-size: 1.5rem;
  line-height: 2rem;
  letter-spacing: -0.04em;
}

.text-md-copy {
  font-size: 1.125rem;
  line-height: 1.625rem;
}

.text-sm-copy {
  font-size: 0.875rem;
  line-height: 1.375rem;
}

.text-sm-heading {
  font-size: 1.125rem;
  line-height: 1.5rem;
}

.text-xl-copy {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-xl-heading {
  font-size: 1.75rem;
  line-height: 2rem;
  letter-spacing: -0.04em;
}

.text-xs-copy {
  font-size: 0.75rem;
  line-height: 1.125rem;
}

.text-xs-heading {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.\!font-black {
  font-weight: 900 !important;
}

.\!font-bold {
  font-weight: 700 !important;
}

.\!font-extrabold {
  font-weight: 800 !important;
}

.\!font-light {
  font-weight: 300 !important;
}

.\!font-medium {
  font-weight: 500 !important;
}

.font-black {
  font-weight: 900;
}

.font-bold {
  font-weight: 700;
}

.font-extrabold {
  font-weight: 800;
}

.font-light {
  font-weight: 300;
}

.font-medium {
  font-weight: 500;
}

.font-normal {
  font-weight: 400;
}

.font-semibold {
  font-weight: 600;
}

.font-thin {
  font-weight: 100;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.normal-case {
  text-transform: none;
}

.italic {
  font-style: italic;
}

.not-italic {
  font-style: normal;
}

.\!leading-tight {
  line-height: 1.25 !important;
}

.leading-10 {
  line-height: 2.5rem;
}

.leading-5 {
  line-height: 1.25rem;
}

.leading-6 {
  line-height: 1.5rem;
}

.leading-7 {
  line-height: 1.75rem;
}

.leading-8 {
  line-height: 2rem;
}

.leading-tight {
  line-height: 1.25;
}

.tracking-\[-\.02em\] {
  letter-spacing: -.02em;
}

.tracking-\[-0\.04em\] {
  letter-spacing: -0.04em;
}

.tracking-\[\.02em\] {
  letter-spacing: .02em;
}

.tracking-negative {
  letter-spacing: -0.5rem;
}

.tracking-normal {
  letter-spacing: 0;
}

.tracking-tight {
  letter-spacing: -.025em;
}

.tracking-tighter {
  letter-spacing: -.05em;
}

.tracking-tightest {
  letter-spacing: -.075em;
}

.tracking-wider {
  letter-spacing: .05em;
}

.\!text-white {
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1)) !important;
}

.text-\[\#10103F\] {
  --tw-text-opacity: 1;
  color: rgb(16 16 63 / var(--tw-text-opacity, 1));
}

.text-\[\#262626\] {
  --tw-text-opacity: 1;
  color: rgb(38 38 38 / var(--tw-text-opacity, 1));
}

.text-\[\#3b82f6\] {
  --tw-text-opacity: 1;
  color: rgb(59 130 246 / var(--tw-text-opacity, 1));
}

.text-\[\#6200EA\] {
  --tw-text-opacity: 1;
  color: rgb(98 0 234 / var(--tw-text-opacity, 1));
}

.text-\[\#A63CFD\] {
  --tw-text-opacity: 1;
  color: rgb(166 60 253 / var(--tw-text-opacity, 1));
}

.text-\[\#B288FF\] {
  --tw-text-opacity: 1;
  color: rgb(178 136 255 / var(--tw-text-opacity, 1));
}

.text-\[\#E8FD3C\] {
  --tw-text-opacity: 1;
  color: rgb(232 253 60 / var(--tw-text-opacity, 1));
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity, 1));
}

.text-blue-300 {
  --tw-text-opacity: 1;
  color: rgb(83 109 254 / var(--tw-text-opacity, 1));
}

.text-blue-400 {
  --tw-text-opacity: 1;
  color: rgb(48 79 254 / var(--tw-text-opacity, 1));
}

.text-blueGray-1000 {
  --tw-text-opacity: 1;
  color: rgb(60 62 73 / var(--tw-text-opacity, 1));
}

.text-blueGray-700 {
  --tw-text-opacity: 1;
  color: rgb(155 159 177 / var(--tw-text-opacity, 1));
}

.text-blueGray-800 {
  --tw-text-opacity: 1;
  color: rgb(131 136 158 / var(--tw-text-opacity, 1));
}

.text-blueGray-900 {
  --tw-text-opacity: 1;
  color: rgb(103 107 126 / var(--tw-text-opacity, 1));
}

.text-current {
  color: currentColor;
}

.text-darkPurple-200 {
  --tw-text-opacity: 1;
  color: rgb(81 45 168 / var(--tw-text-opacity, 1));
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(140 140 140 / var(--tw-text-opacity, 1));
}

.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(89 89 89 / var(--tw-text-opacity, 1));
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(38 38 38 / var(--tw-text-opacity, 1));
}

.text-green-400 {
  --tw-text-opacity: 1;
  color: rgb(0 230 118 / var(--tw-text-opacity, 1));
}

.text-green-500 {
  --tw-text-opacity: 1;
  color: rgb(0 214 89 / var(--tw-text-opacity, 1));
}

.text-hotPink {
  --tw-text-opacity: 1;
  color: rgb(255 0 179 / var(--tw-text-opacity, 1));
}

.text-purple-200 {
  --tw-text-opacity: 1;
  color: rgb(179 136 255 / var(--tw-text-opacity, 1));
}

.text-purple-300 {
  --tw-text-opacity: 1;
  color: rgb(124 77 255 / var(--tw-text-opacity, 1));
}

.text-purple-400 {
  --tw-text-opacity: 1;
  color: rgb(101 31 255 / var(--tw-text-opacity, 1));
}

.text-purple-500 {
  --tw-text-opacity: 1;
  color: rgb(98 0 234 / var(--tw-text-opacity, 1));
}

.text-red-300 {
  --tw-text-opacity: 1;
  color: rgb(255 23 68 / var(--tw-text-opacity, 1));
}

.text-red-400 {
  --tw-text-opacity: 1;
  color: rgb(211 47 47 / var(--tw-text-opacity, 1));
}

.text-red-500 {
  --tw-text-opacity: 1;
  color: rgb(183 28 28 / var(--tw-text-opacity, 1));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

.underline {
  text-decoration-line: underline;
}

.line-through {
  text-decoration-line: line-through;
}

.no-underline {
  text-decoration-line: none;
}

.decoration-green-500 {
  text-decoration-color: #00D659;
}

.underline-offset-4 {
  text-underline-offset: 4px;
}

.antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.accent-purple-500 {
  accent-color: #6200EA;
}

.opacity-0 {
  opacity: 0;
}

.opacity-10 {
  opacity: 0.1;
}

.opacity-100 {
  opacity: 1;
}

.opacity-40 {
  opacity: 0.4;
}

.opacity-50 {
  opacity: 0.5;
}

.opacity-60 {
  opacity: 0.6;
}

.opacity-70 {
  opacity: 0.7;
}

.opacity-80 {
  opacity: 0.8;
}

.opacity-90 {
  opacity: 0.9;
}

.mix-blend-darken {
  mix-blend-mode: darken;
}

.shadow {
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-\[0_0_16px_\#b388ff\2c 0_0_0_40px_\#f3ebff\] {
  --tw-shadow: 0 0 16px #b388ff,0 0 0 40px #f3ebff;
  --tw-shadow-colored: 0 0 16px var(--tw-shadow-color), 0 0 0 40px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-around {
  --tw-shadow: 0 0 12px rgb(0 0 0 / 15%);
  --tw-shadow-colored: 0 0 12px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-none {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-sm {
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-xl {
  --tw-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.outline-none {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.outline {
  outline-style: solid;
}

.ring {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-0 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-2 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-blueGray-900\/5 {
  --tw-ring-color: rgb(103 107 126 / 0.05);
}

.blur {
  --tw-blur: blur(8px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.drop-shadow-sm {
  --tw-drop-shadow: drop-shadow(0 1px 1px rgb(0 0 0 / 0.05));
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.invert {
  --tw-invert: invert(100%);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.transition-colors {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.transition-opacity {
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.transition-shadow {
  transition-property: box-shadow;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.transition-transform {
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.duration-100 {
  transition-duration: 100ms;
}

.duration-150 {
  transition-duration: 150ms;
}

.duration-200 {
  transition-duration: 200ms;
}

.duration-300 {
  transition-duration: 300ms;
}

.duration-500 {
  transition-duration: 500ms;
}

.ease-in {
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
}

.ease-in-out {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.\[--animation-duration\:35s\] {
  --animation-duration: 35s;
}

.\[--translate-x\:-75\%\] {
  --translate-x: -75%;
}

.\[-webkit-tap-highlight-color\:transparent\] {
  -webkit-tap-highlight-color: transparent;
}

.\[align-content\:center\] {
  align-content: center;
}

.\[align-content\:stretch\] {
  align-content: stretch;
}

.\[align-items\:end\] {
  align-items: end;
}

.\[align-items\:flex-end\] {
  align-items: flex-end;
}

.\[align-items\:flex-start\] {
  align-items: flex-start;
}

.\[align-items\:stretch\] {
  align-items: stretch;
}

.\[align-self\:auto\] {
  align-self: auto;
}

.\[align-self\:center\] {
  align-self: center;
}

.\[align-self\:end\] {
  align-self: end;
}

.\[align-self\:flex-start\] {
  align-self: flex-start;
}

.\[align-self\:start\] {
  align-self: start;
}

.\[align-self\:stretch\] {
  align-self: stretch;
}

.\[animation-delay\:0\.5s\] {
  animation-delay: 0.5s;
}

.\[animation-delay\:1s\] {
  animation-delay: 1s;
}

.\[animation-delay\:200ms\] {
  animation-delay: 200ms;
}

.\[animation-delay\:400ms\] {
  animation-delay: 400ms;
}

.\[backdrop-filter\:brightness\(200\%\)\] {
  backdrop-filter: brightness(200%);
}

.\[backdrop-filter\:brightness\(300\%\)\] {
  backdrop-filter: brightness(300%);
}

.\[background-attachment\:scroll\] {
  background-attachment: scroll;
}

.\[background-color\:\#00d659\] {
  background-color: #00d659;
}

.\[background-color\:\#243a5e\] {
  background-color: #243a5e;
}

.\[background-color\:\#6200ea\] {
  background-color: #6200ea;
}

.\[background-color\:\#651fff\] {
  background-color: #651fff;
}

.\[background-color\:\#676b7e\] {
  background-color: #676b7e;
}

.\[background-color\:\#7c4dff\] {
  background-color: #7c4dff;
}

.\[background-color\:\#b388ff\] {
  background-color: #b388ff;
}

.\[background-color\:\#c0c3ce\] {
  background-color: #c0c3ce;
}

.\[background-color\:\#ddd\] {
  background-color: #ddd;
}

.\[background-color\:\#e5e7ff\] {
  background-color: #e5e7ff;
}

.\[background-color\:\#e8f5e9\] {
  background-color: #e8f5e9;
}

.\[background-color\:\#ecedf0\] {
  background-color: #ecedf0;
}

.\[background-color\:\#eee\] {
  background-color: #eee;
}

.\[background-color\:\#f3ebff\] {
  background-color: #f3ebff;
}

.\[background-color\:\#f4f6ff\] {
  background-color: #f4f6ff;
}

.\[background-color\:\#f5f5f5\] {
  background-color: #f5f5f5;
}

.\[background-color\:\#f6f7fb\] {
  background-color: #f6f7fb;
}

.\[background-color\:\#f8f8fa\] {
  background-color: #f8f8fa;
}

.\[background-color\:\#f8f9fa\] {
  background-color: #f8f9fa;
}

.\[background-color\:\#fafafa\] {
  background-color: #fafafa;
}

.\[background-color\:\#ffab00\] {
  background-color: #ffab00;
}

.\[background-color\:\#ffdede\] {
  background-color: #ffdede;
}

.\[background-color\:\#ffe57f\] {
  background-color: #ffe57f;
}

.\[background-color\:\#ffebee\] {
  background-color: #ffebee;
}

.\[background-color\:rgba\(0\2c _0\2c _0\2c _0\.24\)\] {
  background-color: rgba(0, 0, 0, 0.24);
}

.\[background-color\:rgba\(29\2c _185\2c _84\2c _0\.25\)\] {
  background-color: rgba(29, 185, 84, 0.25);
}

.\[background-image\:linear-gradient\(180deg\2c _\#000\2c _\#000\)\] {
  background-image: linear-gradient(180deg, #000, #000);
}

.\[background-image\:none\] {
  background-image: none;
}

.\[background-image\:url\(\'\/images\/2vcross\.svg\'\)\] {
  background-image: url('/images/2vcross.svg');
}

.\[background-image\:url\(\'\/images\/Check-circle-fill-blue\.svg\'\)\] {
  background-image: url('/images/Check-circle-fill-blue.svg');
}

.\[background-image\:url\(\'\/images\/Check-circle-fill-purple\.svg\'\)\] {
  background-image: url('/images/Check-circle-fill-purple.svg');
}

.\[background-image\:url\(\'\/images\/Check-circle-fill\\_1\.svg\'\)\] {
  background-image: url('/images/Check-circle-fill\_1.svg');
}

.\[background-image\:url\(\'\/images\/Check-circle-fill\\_2\.svg\'\)\] {
  background-image: url('/images/Check-circle-fill\_2.svg');
}

.\[background-image\:url\(\'\/images\/Check-circle-fill\\_3\.svg\'\)\] {
  background-image: url('/images/Check-circle-fill\_3.svg');
}

.\[background-image\:url\(\'\/images\/Download-black\.svg\'\)\] {
  background-image: url('/images/Download-black.svg');
}

.\[background-image\:url\(\'\/images\/Download\.svg\'\)\] {
  background-image: url('/images/Download.svg');
}

.\[background-image\:url\(\'\/images\/Group\\_6\.svg\'\)\] {
  background-image: url('/images/Group\_6.svg');
}

.\[background-image\:url\(\'\/images\/background-image\.svg\'\)\] {
  background-image: url('/images/background-image.svg');
}

.\[background-image\:url\(\'\/images\/cross\.svg\'\)\] {
  background-image: url('/images/cross.svg');
}

.\[background-image\:url\(\'\/images\/cross\\_1\.svg\'\)\] {
  background-image: url('/images/cross\_1.svg');
}

.\[background-image\:url\(\'\/images\/cross\\_2\.svg\'\)\] {
  background-image: url('/images/cross\_2.svg');
}

.\[background-image\:url\(\'\/images\/left\.png\'\)\] {
  background-image: url('/images/left.png');
}

.\[background-image\:url\(\'\/images\/purple-bg\.svg\'\)\] {
  background-image: url('/images/purple-bg.svg');
}

.\[background-image\:url\(\'\/images\/purple-tick\.svg\'\)\] {
  background-image: url('/images/purple-tick.svg');
}

.\[background-image\:url\(\'\/images\/right\.png\'\)\] {
  background-image: url('/images/right.png');
}

.\[background-image\:url\(\/images\/quiz-prompt\.jpg\)\] {
  background-image: url(/images/quiz-prompt.jpg);
}

.\[background-position\:0_0\] {
  background-position: 0 0;
}

.\[background-position\:0_50\%\] {
  background-position: 0 50%;
}

.\[background-position\:10\%_50\%\] {
  background-position: 10% 50%;
}

.\[background-position\:50\%_50\%\] {
  background-position: 50% 50%;
}

.\[background-position\:95\%_50\%\] {
  background-position: 95% 50%;
}

.\[background-repeat\:repeat\] {
  background-repeat: repeat;
}

.\[background-size\:16px_16px\] {
  background-size: 16px 16px;
}

.\[background-size\:auto\] {
  background-size: auto;
}

.\[background\:conic-gradient\(\#100c30_9\%\2c \#9f3a91\2c \#3739a8_62\%\2c \#100c30_94\%\)\] {
  background: conic-gradient(#100c30 9%,#9f3a91,#3739a8 62%,#100c30 94%);
}

.\[background\:linear-gradient\(90deg\2c _\#B54AFA\2c \#6933E8\)\] {
  background: linear-gradient(90deg, #B54AFA,#6933E8);
}

.\[border-bottom-color\:\#ccc\] {
  border-bottom-color: #ccc;
}

.\[border-bottom-left-radius\:2px\] {
  border-bottom-left-radius: 2px;
}

.\[border-bottom-left-radius\:8px\] {
  border-bottom-left-radius: 8px;
}

.\[border-bottom-right-radius\:2px\] {
  border-bottom-right-radius: 2px;
}

.\[border-bottom-right-radius\:8px\] {
  border-bottom-right-radius: 8px;
}

.\[border-bottom-style\:none\] {
  border-bottom-style: none;
}

.\[border-bottom-style\:solid\] {
  border-bottom-style: solid;
}

.\[border-bottom-width\:0\] {
  border-bottom-width: 0;
}

.\[border-bottom-width\:1px\] {
  border-bottom-width: 1px;
}

.\[border-bottom\:1px_none_\#000\] {
  border-bottom: 1px none #000;
}

.\[border-bottom\:1px_none_\#eee\] {
  border-bottom: 1px none #eee;
}

.\[border-bottom\:1px_solid_\#000\] {
  border-bottom: 1px solid #000;
}

.\[border-bottom\:1px_solid_\#eee\] {
  border-bottom: 1px solid #eee;
}

.\[border-bottom\:2px_solid_\#000\] {
  border-bottom: 2px solid #000;
}

.\[border-bottom\:2px_solid_\#00D759\] {
  border-bottom: 2px solid #00D759;
}

.\[border-bottom\:2px_solid_\#00d659\] {
  border-bottom: 2px solid #00d659;
}

.\[border-color\:\#000\] {
  border-color: #000;
}

.\[border-color\:\#00d659\] {
  border-color: #00d659;
}

.\[border-color\:\#6c00ea\] {
  border-color: #6c00ea;
}

.\[border-color\:\#7c4dff\] {
  border-color: #7c4dff;
}

.\[border-color\:\#ECEDF0\] {
  border-color: #ECEDF0;
}

.\[border-color\:\#d0d2da\] {
  border-color: #d0d2da;
}

.\[border-color\:\#dfe0e6\] {
  border-color: #dfe0e6;
}

.\[border-left-color\:\#ccc\] {
  border-left-color: #ccc;
}

.\[border-left-style\:solid\] {
  border-left-style: solid;
}

.\[border-left-width\:1px\] {
  border-left-width: 1px;
}

.\[border-left\:2px_solid_\#000\] {
  border-left: 2px solid #000;
}

.\[border-radius\:100\%\] {
  border-radius: 100%;
}

.\[border-radius\:11\.5px\] {
  border-radius: 11.5px;
}

.\[border-radius\:3px\] {
  border-radius: 3px;
}

.\[border-radius\:50px\] {
  border-radius: 50px;
}

.\[border-radius\:5px\] {
  border-radius: 5px;
}

.\[border-radius\:6px\] {
  border-radius: 6px;
}

.\[border-radius\:7px\] {
  border-radius: 7px;
}

.\[border-right-color\:\#ccc\] {
  border-right-color: #ccc;
}

.\[border-right-style\:solid\] {
  border-right-style: solid;
}

.\[border-right-width\:1px\] {
  border-right-width: 1px;
}

.\[border-style\:none\] {
  border-style: none;
}

.\[border-style\:none_none_solid\] {
  border-style: none none solid;
}

.\[border-style\:solid\] {
  border-style: solid;
}

.\[border-top-color\:\#ccc\] {
  border-top-color: #ccc;
}

.\[border-top-left-radius\:2px\] {
  border-top-left-radius: 2px;
}

.\[border-top-right-radius\:2px\] {
  border-top-right-radius: 2px;
}

.\[border-top-style\:solid\] {
  border-top-style: solid;
}

.\[border-top-width\:1px\] {
  border-top-width: 1px;
}

.\[border-top\:2px_solid_\#000\] {
  border-top: 2px solid #000;
}

.\[border-width\:1px\] {
  border-width: 1px;
}

.\[border-width\:2px\] {
  border-width: 2px;
}

.\[border\:0_none_none_transparent\] {
  border: 0 none none transparent;
}

.\[border\:1\.5px_solid_\#000\] {
  border: 1.5px solid #000;
}

.\[border\:1px_solid_\#000\] {
  border: 1px solid #000;
}

.\[border\:1px_solid_\#c0c3ce\] {
  border: 1px solid #c0c3ce;
}

.\[border\:1px_solid_\#ccc\] {
  border: 1px solid #ccc;
}

.\[border\:1px_solid_\#d9d9d9\] {
  border: 1px solid #d9d9d9;
}

.\[border\:1px_solid_\#dfe0e6\] {
  border: 1px solid #dfe0e6;
}

.\[border\:2px_none_none_\#000\] {
  border: 2px none none #000;
}

.\[border\:2px_solid_\#000\] {
  border: 2px solid #000;
}

.\[border\:none\] {
  border: none;
}

.\[bottom\:-12px\] {
  bottom: -12px;
}

.\[bottom\:-7\%\] {
  bottom: -7%;
}

.\[bottom\:20px\] {
  bottom: 20px;
}

.\[bottom\:auto\] {
  bottom: auto;
}

.\[box-shadow\:-3px_-3px_4px_0_rgba\(0\2c _0\2c _0\2c _0\.15\)\2c ____3px_3px_4px_0_rgba\(0\2c _0\2c _0\2c _0\.15\)\] {
  box-shadow: -3px -3px 4px 0 rgba(0, 0, 0, 0.15),    3px 3px 4px 0 rgba(0, 0, 0, 0.15);
}

.\[box-shadow\:0_0_10px_0_rgba\(0\2c _0\2c _0\2c _0\.2\)\] {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
}

.\[box-shadow\:0_0_2px_0_rgba\(0\2c _0\2c _0\2c _0\.15\)\2c _6px_7px_4px_0_rgba\(0\2c _0\2c _0\2c _0\.15\)\] {
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.15), 6px 7px 4px 0 rgba(0, 0, 0, 0.15);
}

.\[box-shadow\:0_0_2px_0_rgba\(0\2c _0\2c _0\2c _0\.15\)\] {
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.15);
}

.\[box-shadow\:0_0_6px_2px_rgba\(0\2c _0\2c _0\2c _0\.15\)\] {
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.15);
}

.\[box-shadow\:0_0_8px_4px_\#ecedf0\] {
  box-shadow: 0 0 8px 4px #ecedf0;
}

.\[box-shadow\:0px_4px_8px_0px_rgba\(0\2c 0\2c 0\2c 0\.1\)\] {
  box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.1);
}

.\[box-shadow\:3px_3px_8px_0_\#ecedf0\] {
  box-shadow: 3px 3px 8px 0 #ecedf0;
}

.\[box-shadow\:4px_0_8px_0_\#ecedf0\] {
  box-shadow: 4px 0 8px 0 #ecedf0;
}

.\[box-shadow\:6px_6px_20px_-4px_rgba\(0\2c _0\2c _0\2c _0\.1\)\] {
  box-shadow: 6px 6px 20px -4px rgba(0, 0, 0, 0.1);
}

.\[clearclear\:both\] {
  clearclear: both;
}

.\[clearclear\:none\] {
  clearclear: none;
}

.\[color\:\#262626\] {
  color: #262626;
}

.\[color\:\#2962ff\] {
  color: #2962ff;
}

.\[color\:\#333\] {
  color: #333;
}

.\[color\:\#3c3e49\] {
  color: #3c3e49;
}

.\[color\:\#595959\] {
  color: #595959;
}

.\[color\:\#5e8e3e\] {
  color: #5e8e3e;
}

.\[color\:\#676b7e\] {
  color: #676b7e;
}

.\[color\:\#6c00ea\] {
  color: #6c00ea;
}

.\[color\:\#6c01ea\] {
  color: #6c01ea;
}

.\[color\:\#777\] {
  color: #777;
}

.\[color\:\#7c4dff\] {
  color: #7c4dff;
}

.\[color\:\#83889e\] {
  color: #83889e;
}

.\[color\:\#9096a4\] {
  color: #9096a4;
}

.\[color\:\#b71c1c\] {
  color: #b71c1c;
}

.\[color\:\#d32f2f\] {
  color: #d32f2f;
}

.\[color\:\#eee\] {
  color: #eee;
}

.\[color\:\#ff1744\] {
  color: #ff1744;
}

.\[color\:grey\] {
  color: grey;
}

.\[color\:hsla\(0\2c _0\%\2c _100\%\2c _0\.5\)\] {
  color: hsla(0, 0%, 100%, 0.5);
}

.\[color\:inherit\] {
  color: inherit;
}

.\[content-visibility\:auto\] {
  content-visibility: auto;
}

.\[content-visibility\:visible\] {
  content-visibility: visible;
}

.\[cursor\:auto\] {
  cursor: auto;
}

.\[display\:contents\] {
  display: contents;
}

.\[fill\:\#83889E\] {
  fill: #83889E;
}

.\[filter\:blur\(0px\)_blur\(0px\)\] {
  filter: blur(0px) blur(0px);
}

.\[filter\:hue-rotate\(50deg\)\] {
  filter: hue-rotate(50deg);
}

.\[flex-basis\:70px\] {
  flex-basis: 70px;
}

.\[flex-direction\:row-reverse\] {
  flex-direction: row-reverse;
}

.\[flex-direction\:row\] {
  flex-direction: row;
}

.\[flex-grow\:1\] {
  flex-grow: 1;
}

.\[flex-shrink\:0\] {
  flex-shrink: 0;
}

.\[flex-wrap\:nowrap\] {
  flex-wrap: nowrap;
}

.\[flex\:0_0_auto\] {
  flex: 0 0 auto;
}

.\[flex\:0_auto\] {
  flex: 0 auto;
}

.\[flex\:1\] {
  flex: 1;
}

.\[font-family\:Rubik\2c _Arial\2c _sans-serif\] {
  font-family: Rubik, Arial, sans-serif;
}

.\[font-family\:webflow-icons_\!important\] {
  font-family: webflow-icons !important;
}

.\[font-size\:10px\] {
  font-size: 10px;
}

.\[font-size\:16px\] {
  font-size: 16px;
}

.\[font-size\:40px\] {
  font-size: 40px;
}

.\[font-size\:42px\] {
  font-size: 42px;
}

.\[font-size\:75\%\] {
  font-size: 75%;
}

.\[font-size\:8px\] {
  font-size: 8px;
}

.\[font-style\:italic\] {
  font-style: italic;
}

.\[font-style\:normal\] {
  font-style: normal;
}

.\[font-variant\:normal\] {
  font-variant: normal;
}

.\[font-weight\:700\] {
  font-weight: 700;
}

.\[font\:inherit\] {
  font: inherit;
}

.\[grid-auto-columns\:1fr\] {
  grid-auto-columns: 1fr;
}

.\[grid-auto-columns\:5fr\] {
  grid-auto-columns: 5fr;
}

.\[grid-auto-flow\:row\] {
  grid-auto-flow: row;
}

.\[grid-auto-rows\:1fr\] {
  grid-auto-rows: 1fr;
}

.\[grid-column-end\:10\] {
  grid-column-end: 10;
}

.\[grid-column-end\:13\] {
  grid-column-end: 13;
}

.\[grid-column-end\:span_1\] {
  grid-column-end: span 1;
}

.\[grid-column-gap\:0px\] {
  grid-column-gap: 0px;
}

.\[grid-column-gap\:16px\] {
  grid-column-gap: 16px;
}

.\[grid-column-gap\:24px\] {
  grid-column-gap: 24px;
}

.\[grid-column-start\:10\] {
  grid-column-start: 10;
}

.\[grid-column-start\:3\] {
  grid-column-start: 3;
}

.\[grid-column-start\:span_1\] {
  grid-column-start: span 1;
}

.\[grid-row-end\:1\] {
  grid-row-end: 1;
}

.\[grid-row-end\:3\] {
  grid-row-end: 3;
}

.\[grid-row-end\:4\] {
  grid-row-end: 4;
}

.\[grid-row-end\:span_1\] {
  grid-row-end: span 1;
}

.\[grid-row-gap\:0px\] {
  grid-row-gap: 0px;
}

.\[grid-row-gap\:10px\] {
  grid-row-gap: 10px;
}

.\[grid-row-gap\:12px\] {
  grid-row-gap: 12px;
}

.\[grid-row-gap\:16px\] {
  grid-row-gap: 16px;
}

.\[grid-row-gap\:24px\] {
  grid-row-gap: 24px;
}

.\[grid-row-gap\:48px\] {
  grid-row-gap: 48px;
}

.\[grid-row-start\:1\] {
  grid-row-start: 1;
}

.\[grid-row-start\:2\] {
  grid-row-start: 2;
}

.\[grid-row-start\:3\] {
  grid-row-start: 3;
}

.\[grid-row-start\:span_1\] {
  grid-row-start: span 1;
}

.\[grid-template-columns\:12px_1fr\] {
  grid-template-columns: 12px 1fr;
}

.\[grid-template-columns\:1fr\] {
  grid-template-columns: 1fr;
}

.\[grid-template-columns\:1fr_1fr\] {
  grid-template-columns: 1fr 1fr;
}

.\[grid-template-columns\:1fr_1fr_1fr\] {
  grid-template-columns: 1fr 1fr 1fr;
}

.\[grid-template-columns\:1fr_1fr_1fr_1fr\] {
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.\[grid-template-columns\:1fr_3fr\] {
  grid-template-columns: 1fr 3fr;
}

.\[grid-template-columns\:20\%_1fr\] {
  grid-template-columns: 20% 1fr;
}

.\[grid-template-columns\:2fr_10fr\] {
  grid-template-columns: 2fr 10fr;
}

.\[grid-template-columns\:2fr_4fr\] {
  grid-template-columns: 2fr 4fr;
}

.\[grid-template-columns\:2fr_8fr_2fr\] {
  grid-template-columns: 2fr 8fr 2fr;
}

.\[grid-template-columns\:30\%_40\%_30\%\] {
  grid-template-columns: 30% 40% 30%;
}

.\[grid-template-columns\:30px_1fr\] {
  grid-template-columns: 30px 1fr;
}

.\[grid-template-columns\:3fr_minmax\(80px\2c _80px\)\] {
  grid-template-columns: 3fr minmax(80px, 80px);
}

.\[grid-template-columns\:4fr_4fr_4fr\] {
  grid-template-columns: 4fr 4fr 4fr;
}

.\[grid-template-columns\:5fr_4fr\] {
  grid-template-columns: 5fr 4fr;
}

.\[grid-template-rows\:500px_200px_auto\] {
  grid-template-rows: 500px 200px auto;
}

.\[grid-template-rows\:auto\] {
  grid-template-rows: auto;
}

.\[grid-template-rows\:auto_auto\] {
  grid-template-rows: auto auto;
}

.\[grid-template-rows\:auto_auto_auto_auto\] {
  grid-template-rows: auto auto auto auto;
}

.\[height\:0\.1px\] {
  height: 0.1px;
}

.\[height\:1\.25em\] {
  height: 1.25em;
}

.\[height\:100\%\] {
  height: 100%;
}

.\[height\:100px\] {
  height: 100px;
}

.\[height\:100vh\] {
  height: 100vh;
}

.\[height\:120px\] {
  height: 120px;
}

.\[height\:12px\] {
  height: 12px;
}

.\[height\:15vh\] {
  height: 15vh;
}

.\[height\:16px\] {
  height: 16px;
}

.\[height\:180px\] {
  height: 180px;
}

.\[height\:18px\] {
  height: 18px;
}

.\[height\:1px\] {
  height: 1px;
}

.\[height\:20px\] {
  height: 20px;
}

.\[height\:216px\] {
  height: 216px;
}

.\[height\:24px\] {
  height: 24px;
}

.\[height\:27vh\] {
  height: 27vh;
}

.\[height\:28px\] {
  height: 28px;
}

.\[height\:2px\] {
  height: 2px;
}

.\[height\:300px\] {
  height: 300px;
}

.\[height\:30px\] {
  height: 30px;
}

.\[height\:32px\] {
  height: 32px;
}

.\[height\:35px\] {
  height: 35px;
}

.\[height\:36px\] {
  height: 36px;
}

.\[height\:38px\] {
  height: 38px;
}

.\[height\:40px\] {
  height: 40px;
}

.\[height\:44px\] {
  height: 44px;
}

.\[height\:48px\] {
  height: 48px;
}

.\[height\:4px\] {
  height: 4px;
}

.\[height\:50\%\] {
  height: 50%;
}

.\[height\:50px\] {
  height: 50px;
}

.\[height\:60px\] {
  height: 60px;
}

.\[height\:64px\] {
  height: 64px;
}

.\[height\:73px\] {
  height: 73px;
}

.\[height\:80px\] {
  height: 80px;
}

.\[height\:85px\] {
  height: 85px;
}

.\[height\:8px\] {
  height: 8px;
}

.\[height\:8vh\] {
  height: 8vh;
}

.\[height\:auto\] {
  height: auto;
}

.\[height\:calc\(70vh-60px\)\] {
  height: calc(70vh - 60px);
}

.\[justify-content\:stretch\] {
  justify-content: stretch;
}

.\[justify-items\:end\] {
  justify-items: end;
}

.\[justify-self\:auto\] {
  justify-self: auto;
}

.\[justify-self\:center\] {
  justify-self: center;
}

.\[justify-self\:end\] {
  justify-self: end;
}

.\[justify-self\:start\] {
  justify-self: start;
}

.\[justify-self\:stretch\] {
  justify-self: stretch;
}

.\[left\:20px\] {
  left: 20px;
}

.\[left\:24px\] {
  left: 24px;
}

.\[left\:6px\] {
  left: 6px;
}

.\[left\:auto\] {
  left: auto;
}

.\[letter-spacing\:-0\.015em\] {
  letter-spacing: -0.015em;
}

.\[letter-spacing\:-0\.01em\] {
  letter-spacing: -0.01em;
}

.\[letter-spacing\:-0\.02em\] {
  letter-spacing: -0.02em;
}

.\[letter-spacing\:-0\.03em\] {
  letter-spacing: -0.03em;
}

.\[letter-spacing\:-0\.04em\] {
  letter-spacing: -0.04em;
}

.\[letter-spacing\:-0\.05px\] {
  letter-spacing: -0.05px;
}

.\[letter-spacing\:-0\.2px\] {
  letter-spacing: -0.2px;
}

.\[line-height\:0\.78\] {
  line-height: 0.78;
}

.\[line-height\:0\.8\] {
  line-height: 0.8;
}

.\[line-height\:0\.9\] {
  line-height: 0.9;
}

.\[line-height\:0\] {
  line-height: 0;
}

.\[line-height\:1\.1\] {
  line-height: 1.1;
}

.\[line-height\:1\.428571429\] {
  line-height: 1.428571429;
}

.\[line-height\:1\.42857143\] {
  line-height: 1.42857143;
}

.\[line-height\:1\.4\] {
  line-height: 1.4;
}

.\[line-height\:1\.5\] {
  line-height: 1.5;
}

.\[line-height\:1\.6\] {
  line-height: 1.6;
}

.\[line-height\:11px\] {
  line-height: 11px;
}

.\[line-height\:150\%\] {
  line-height: 150%;
}

.\[line-height\:18px\] {
  line-height: 18px;
}

.\[line-height\:1\] {
  line-height: 1;
}

.\[line-height\:22px\] {
  line-height: 22px;
}

.\[line-height\:25px\] {
  line-height: 25px;
}

.\[line-height\:26px\] {
  line-height: 26px;
}

.\[line-height\:28px\] {
  line-height: 28px;
}

.\[line-height\:32px\] {
  line-height: 32px;
}

.\[line-height\:34px\] {
  line-height: 34px;
}

.\[line-height\:36px\] {
  line-height: 36px;
}

.\[line-height\:44px\] {
  line-height: 44px;
}

.\[line-height\:46px\] {
  line-height: 46px;
}

.\[line-height\:48px\] {
  line-height: 48px;
}

.\[line-height\:60px\] {
  line-height: 60px;
}

.\[line-height\:normal\] {
  line-height: normal;
}

.\[list-style-type\:disc\] {
  list-style-type: disc;
}

.\[list-style-type\:none\!important\] {
  list-style-type: none !important;
}

.\[list-style-type\:none\] {
  list-style-type: none;
}

.\[list-style\:none\] {
  list-style: none;
}

.\[margin-bottom\:-17px\] {
  margin-bottom: -17px;
}

.\[margin-bottom\:-2px\] {
  margin-bottom: -2px;
}

.\[margin-bottom\:0\.5em\] {
  margin-bottom: 0.5em;
}

.\[margin-bottom\:0\.67em\] {
  margin-bottom: 0.67em;
}

.\[margin-bottom\:10px\] {
  margin-bottom: 10px;
}

.\[margin-bottom\:120px\] {
  margin-bottom: 120px;
}

.\[margin-bottom\:12px\] {
  margin-bottom: 12px;
}

.\[margin-bottom\:14px\] {
  margin-bottom: 14px;
}

.\[margin-bottom\:15px\] {
  margin-bottom: 15px;
}

.\[margin-bottom\:16px\] {
  margin-bottom: 16px;
}

.\[margin-bottom\:20px\] {
  margin-bottom: 20px;
}

.\[margin-bottom\:22px\] {
  margin-bottom: 22px;
}

.\[margin-bottom\:24px\] {
  margin-bottom: 24px;
}

.\[margin-bottom\:25px\] {
  margin-bottom: 25px;
}

.\[margin-bottom\:26px\] {
  margin-bottom: 26px;
}

.\[margin-bottom\:30px\] {
  margin-bottom: 30px;
}

.\[margin-bottom\:36px\] {
  margin-bottom: 36px;
}

.\[margin-bottom\:40px\] {
  margin-bottom: 40px;
}

.\[margin-bottom\:4px\] {
  margin-bottom: 4px;
}

.\[margin-bottom\:52px\] {
  margin-bottom: 52px;
}

.\[margin-bottom\:5px\] {
  margin-bottom: 5px;
}

.\[margin-bottom\:60px\] {
  margin-bottom: 60px;
}

.\[margin-bottom\:8px\] {
  margin-bottom: 8px;
}

.\[margin-bottom\:auto\] {
  margin-bottom: auto;
}

.\[margin-left\:10px\] {
  margin-left: 10px;
}

.\[margin-left\:12px\] {
  margin-left: 12px;
}

.\[margin-left\:2\.5rem\] {
  margin-left: 2.5rem;
}

.\[margin-left\:20px\] {
  margin-left: 20px;
}

.\[margin-left\:4px\] {
  margin-left: 4px;
}

.\[margin-left\:7px\] {
  margin-left: 7px;
}

.\[margin-left\:8px\] {
  margin-left: 8px;
}

.\[margin-left\:auto\] {
  margin-left: auto;
}

.\[margin-right\:100px\] {
  margin-right: 100px;
}

.\[margin-right\:10px\] {
  margin-right: 10px;
}

.\[margin-right\:12px\] {
  margin-right: 12px;
}

.\[margin-right\:15px\] {
  margin-right: 15px;
}

.\[margin-right\:20px\] {
  margin-right: 20px;
}

.\[margin-right\:22px\] {
  margin-right: 22px;
}

.\[margin-right\:24px\] {
  margin-right: 24px;
}

.\[margin-right\:4px\] {
  margin-right: 4px;
}

.\[margin-right\:6px\] {
  margin-right: 6px;
}

.\[margin-right\:8px\] {
  margin-right: 8px;
}

.\[margin-right\:auto\] {
  margin-right: auto;
}

.\[margin-top\:-100px\] {
  margin-top: -100px;
}

.\[margin-top\:-132px\] {
  margin-top: -132px;
}

.\[margin-top\:-160px\] {
  margin-top: -160px;
}

.\[margin-top\:1\.3333333em\] {
  margin-top: 1.3333333em;
}

.\[margin-top\:10px\] {
  margin-top: 10px;
}

.\[margin-top\:120px\] {
  margin-top: 120px;
}

.\[margin-top\:12px\] {
  margin-top: 12px;
}

.\[margin-top\:15px\] {
  margin-top: 15px;
}

.\[margin-top\:16px\] {
  margin-top: 16px;
}

.\[margin-top\:20px\] {
  margin-top: 20px;
}

.\[margin-top\:24px\] {
  margin-top: 24px;
}

.\[margin-top\:25px\] {
  margin-top: 25px;
}

.\[margin-top\:29px\] {
  margin-top: 29px;
}

.\[margin-top\:32px\] {
  margin-top: 32px;
}

.\[margin-top\:3px\] {
  margin-top: 3px;
}

.\[margin-top\:40px\] {
  margin-top: 40px;
}

.\[margin-top\:48px\] {
  margin-top: 48px;
}

.\[margin-top\:4px\] {
  margin-top: 4px;
}

.\[margin-top\:50px\] {
  margin-top: 50px;
}

.\[margin-top\:5px\] {
  margin-top: 5px;
}

.\[margin-top\:60px\] {
  margin-top: 60px;
}

.\[margin-top\:6px\] {
  margin-top: 6px;
}

.\[margin-top\:70px\] {
  margin-top: 70px;
}

.\[margin-top\:8px\] {
  margin-top: 8px;
}

.\[margin-top\:auto\] {
  margin-top: auto;
}

.\[margin\:0\.67em_0\] {
  margin: 0.67em 0;
}

.\[margin\:0_0_15px\] {
  margin: 0 0 15px;
}

.\[margin\:0_auto\] {
  margin: 0 auto;
}

.\[margin\:8px_0_0_-13px\] {
  margin: 8px 0 0 -13px;
}

.\[margin\:auto\] {
  margin: auto;
}

.\[max-height\:100\%\] {
  max-height: 100%;
}

.\[max-height\:250px\] {
  max-height: 250px;
}

.\[max-height\:30\%\] {
  max-height: 30%;
}

.\[max-height\:70vh\] {
  max-height: 70vh;
}

.\[max-height\:none\] {
  max-height: none;
}

.\[max-width\:100vw\] {
  max-width: 100vw;
}

.\[max-width\:190px\] {
  max-width: 190px;
}

.\[max-width\:250px\] {
  max-width: 250px;
}

.\[max-width\:373px\] {
  max-width: 373px;
}

.\[max-width\:386px\] {
  max-width: 386px;
}

.\[max-width\:680px\] {
  max-width: 680px;
}

.\[max-width\:700px\] {
  max-width: 700px;
}

.\[max-width\:80\%\] {
  max-width: 80%;
}

.\[max-width\:800px\] {
  max-width: 800px;
}

.\[max-width\:80px\] {
  max-width: 80px;
}

.\[max-width\:none\] {
  max-width: none;
}

.\[min-height\:100vh\] {
  min-height: 100vh;
}

.\[min-height\:200px\] {
  min-height: 200px;
}

.\[min-height\:24px\] {
  min-height: 24px;
}

.\[min-height\:32px\] {
  min-height: 32px;
}

.\[min-height\:380px\] {
  min-height: 380px;
}

.\[min-height\:40px\] {
  min-height: 40px;
}

.\[min-height\:48px\] {
  min-height: 48px;
}

.\[min-height\:640px\] {
  min-height: 640px;
}

.\[min-height\:auto\] {
  min-height: auto;
}

.\[min-width\:120px\] {
  min-width: 120px;
}

.\[min-width\:140px\] {
  min-width: 140px;
}

.\[min-width\:160px\] {
  min-width: 160px;
}

.\[min-width\:24px\] {
  min-width: 24px;
}

.\[opacity\:0\.75\] {
  opacity: 0.75;
}

.\[order\:0\] {
  order: 0;
}

.\[outline\:none\] {
  outline: none;
}

.\[overscroll-behavior-y\:contain\] {
  overscroll-behavior-y: contain;
}

.\[padding-bottom\:100px\] {
  padding-bottom: 100px;
}

.\[padding-bottom\:10px\] {
  padding-bottom: 10px;
}

.\[padding-bottom\:120px\] {
  padding-bottom: 120px;
}

.\[padding-bottom\:12px\] {
  padding-bottom: 12px;
}

.\[padding-bottom\:13px\] {
  padding-bottom: 13px;
}

.\[padding-bottom\:14px\] {
  padding-bottom: 14px;
}

.\[padding-bottom\:16px\] {
  padding-bottom: 16px;
}

.\[padding-bottom\:1em\] {
  padding-bottom: 1em;
}

.\[padding-bottom\:200px\] {
  padding-bottom: 200px;
}

.\[padding-bottom\:20px\] {
  padding-bottom: 20px;
}

.\[padding-bottom\:24px\] {
  padding-bottom: 24px;
}

.\[padding-bottom\:2px\] {
  padding-bottom: 2px;
}

.\[padding-bottom\:30px\] {
  padding-bottom: 30px;
}

.\[padding-bottom\:40px\] {
  padding-bottom: 40px;
}

.\[padding-bottom\:4px\] {
  padding-bottom: 4px;
}

.\[padding-bottom\:64px\] {
  padding-bottom: 64px;
}

.\[padding-bottom\:8px\] {
  padding-bottom: 8px;
}

.\[padding-bottom\:9px\] {
  padding-bottom: 9px;
}

.\[padding-left\:100px\] {
  padding-left: 100px;
}

.\[padding-left\:10px\] {
  padding-left: 10px;
}

.\[padding-left\:15px\] {
  padding-left: 15px;
}

.\[padding-left\:16px\] {
  padding-left: 16px;
}

.\[padding-left\:1em\] {
  padding-left: 1em;
}

.\[padding-left\:20px\] {
  padding-left: 20px;
}

.\[padding-left\:22px\] {
  padding-left: 22px;
}

.\[padding-left\:24px\] {
  padding-left: 24px;
}

.\[padding-left\:30px\] {
  padding-left: 30px;
}

.\[padding-left\:35px\] {
  padding-left: 35px;
}

.\[padding-left\:40px\] {
  padding-left: 40px;
}

.\[padding-left\:5px\] {
  padding-left: 5px;
}

.\[padding-left\:67px\] {
  padding-left: 67px;
}

.\[padding-left\:9px\] {
  padding-left: 9px;
}

.\[padding-right\:10px\] {
  padding-right: 10px;
}

.\[padding-right\:15px\] {
  padding-right: 15px;
}

.\[padding-right\:16px\] {
  padding-right: 16px;
}

.\[padding-right\:1em\] {
  padding-right: 1em;
}

.\[padding-right\:20px\] {
  padding-right: 20px;
}

.\[padding-right\:24px\] {
  padding-right: 24px;
}

.\[padding-right\:40px\] {
  padding-right: 40px;
}

.\[padding-right\:4px\] {
  padding-right: 4px;
}

.\[padding-right\:5px\] {
  padding-right: 5px;
}

.\[padding-right\:60px\] {
  padding-right: 60px;
}

.\[padding-right\:67px\] {
  padding-right: 67px;
}

.\[padding-top\:10px\] {
  padding-top: 10px;
}

.\[padding-top\:12px\] {
  padding-top: 12px;
}

.\[padding-top\:13px\] {
  padding-top: 13px;
}

.\[padding-top\:14px\] {
  padding-top: 14px;
}

.\[padding-top\:1em\] {
  padding-top: 1em;
}

.\[padding-top\:20px\] {
  padding-top: 20px;
}

.\[padding-top\:24px\] {
  padding-top: 24px;
}

.\[padding-top\:30px\] {
  padding-top: 30px;
}

.\[padding-top\:32px\] {
  padding-top: 32px;
}

.\[padding-top\:40px\] {
  padding-top: 40px;
}

.\[padding-top\:50px\] {
  padding-top: 50px;
}

.\[padding-top\:56\.25\%\] {
  padding-top: 56.25%;
}

.\[padding-top\:56px\] {
  padding-top: 56px;
}

.\[padding-top\:60px\] {
  padding-top: 60px;
}

.\[padding-top\:62px\] {
  padding-top: 62px;
}

.\[padding-top\:64px\] {
  padding-top: 64px;
}

.\[padding-top\:80px\] {
  padding-top: 80px;
}

.\[padding-top\:9px\] {
  padding-top: 9px;
}

.\[padding\:0\] {
  padding: 0;
}

.\[padding\:0_12px\] {
  padding: 0 12px;
}

.\[padding\:10px\] {
  padding: 10px;
}

.\[padding\:10px_10px_0\] {
  padding: 10px 10px 0;
}

.\[padding\:10px_24px\] {
  padding: 10px 24px;
}

.\[padding\:10px_3px\] {
  padding: 10px 3px;
}

.\[padding\:10px_60px_10px_40px\] {
  padding: 10px 60px 10px 40px;
}

.\[padding\:12px_13px_11px\] {
  padding: 12px 13px 11px;
}

.\[padding\:13px\] {
  padding: 13px;
}

.\[padding\:14px_24px\] {
  padding: 14px 24px;
}

.\[padding\:15px\] {
  padding: 15px;
}

.\[padding\:16px_24px\] {
  padding: 16px 24px;
}

.\[padding\:16px_24px_24px\] {
  padding: 16px 24px 24px;
}

.\[padding\:20px\] {
  padding: 20px;
}

.\[padding\:24px_0\] {
  padding: 24px 0;
}

.\[padding\:24px_24px_12px_20px\] {
  padding: 24px 24px 12px 20px;
}

.\[padding\:24px_80px_0px\] {
  padding: 24px 80px 0px;
}

.\[padding\:2px_12px\] {
  padding: 2px 12px;
}

.\[padding\:32px_20px_56px\] {
  padding: 32px 20px 56px;
}

.\[padding\:32px_24px_24px\] {
  padding: 32px 24px 24px;
}

.\[padding\:32px_24px_28px\] {
  padding: 32px 24px 28px;
}

.\[padding\:35px\] {
  padding: 35px;
}

.\[padding\:36px\] {
  padding: 36px;
}

.\[padding\:3px\] {
  padding: 3px;
}

.\[padding\:40px\] {
  padding: 40px;
}

.\[padding\:5px_20px\] {
  padding: 5px 20px;
}

.\[padding\:60px_40px_20px\] {
  padding: 60px 40px 20px;
}

.\[padding\:64px_40px_48px\] {
  padding: 64px 40px 48px;
}

.\[padding\:7px_11px_11px\] {
  padding: 7px 11px 11px;
}

.\[padding\:8px_0\] {
  padding: 8px 0;
}

.\[padding\:8px_12px\] {
  padding: 8px 12px;
}

.\[padding\:8px_12px_8px_60px\] {
  padding: 8px 12px 8px 60px;
}

.\[padding\:8px_9px_8px_11px\] {
  padding: 8px 9px 8px 11px;
}

.\[right\:auto\] {
  right: auto;
}

.\[speak\:none\] {
  speak: none;
}

.\[tap-highlight-color\:rgba\(0\2c _0\2c _0\2c _0\)\] {
  tap-highlight-color: rgba(0, 0, 0, 0);
}

.\[text-decoration\:line-through\] {
  text-decoration: line-through;
}

.\[text-decoration\:underline\] {
  text-decoration: underline;
}

.\[text-transform\:none\] {
  text-transform: none;
}

.\[top\:-0\.5em\] {
  top: -0.5em;
}

.\[top\:-22px\] {
  top: -22px;
}

.\[top\:0\] {
  top: 0;
}

.\[top\:120px\] {
  top: 120px;
}

.\[top\:20px\] {
  top: 20px;
}

.\[top\:20vh\] {
  top: 20vh;
}

.\[top\:48vh\] {
  top: 48vh;
}

.\[top\:60px\] {
  top: 60px;
}

.\[top\:6px\] {
  top: 6px;
}

.\[top\:80px\] {
  top: 80px;
}

.\[top\:auto\] {
  top: auto;
}

.\[transform\:translate\(0\2c _-5px\)\] {
  transform: translate(0, -5px);
}

.\[transform\:translateX\(43px\)\] {
  transform: translateX(43px);
}

.\[transition-property\:none\] {
  transition-property: none;
}

.\[transition\:all_0\.2s_ease\] {
  transition: all 0.2s ease;
}

.\[transition\:opacity_0\.2s\] {
  transition: opacity 0.2s;
}

.\[transition\:opacity_0\.2s_ease\] {
  transition: opacity 0.2s ease;
}

.\[transition\:opacity_1\.2s_ease\] {
  transition: opacity 1.2s ease;
}

.\[transition\:transform_0\.2s_ease\] {
  transition: transform 0.2s ease;
}

.\[user-select\:none\] {
  user-select: none;
}

.\[vertical-align\:baseline\] {
  vertical-align: baseline;
}

.\[vertical-align\:top\] {
  vertical-align: top;
}

.\[white-space\:normal\] {
  white-space: normal;
}

.\[white-space\:nowrap\] {
  white-space: nowrap;
}

.\[width\:0\.1px\] {
  width: 0.1px;
}

.\[width\:100px\] {
  width: 100px;
}

.\[width\:120\%\] {
  width: 120%;
}

.\[width\:120px\] {
  width: 120px;
}

.\[width\:12px\] {
  width: 12px;
}

.\[width\:13px\] {
  width: 13px;
}

.\[width\:14px\] {
  width: 14px;
}

.\[width\:150px\] {
  width: 150px;
}

.\[width\:15px\] {
  width: 15px;
}

.\[width\:170px\] {
  width: 170px;
}

.\[width\:18px\] {
  width: 18px;
}

.\[width\:190px\] {
  width: 190px;
}

.\[width\:1px\] {
  width: 1px;
}

.\[width\:20px\] {
  width: 20px;
}

.\[width\:22\%\] {
  width: 22%;
}

.\[width\:24px\] {
  width: 24px;
}

.\[width\:26px\] {
  width: 26px;
}

.\[width\:300\%\] {
  width: 300%;
}

.\[width\:30px\] {
  width: 30px;
}

.\[width\:35px\] {
  width: 35px;
}

.\[width\:360px\] {
  width: 360px;
}

.\[width\:40px\] {
  width: 40px;
}

.\[width\:44px\] {
  width: 44px;
}

.\[width\:48px\] {
  width: 48px;
}

.\[width\:4px\] {
  width: 4px;
}

.\[width\:50\%\] {
  width: 50%;
}

.\[width\:50px\] {
  width: 50px;
}

.\[width\:60px\] {
  width: 60px;
}

.\[width\:64px\] {
  width: 64px;
}

.\[width\:70\%\] {
  width: 70%;
}

.\[width\:76\%\] {
  width: 76%;
}

.\[width\:78px\] {
  width: 78px;
}

.\[width\:80\%\] {
  width: 80%;
}

.\[width\:80px\] {
  width: 80px;
}

.\[width\:82\%\] {
  width: 82%;
}

.\[width\:auto\] {
  width: auto;
}

.\[width\:calc\(100\%-40px\)\] {
  width: calc(100% - 40px);
}

.\[z-index\:-100\] {
  z-index: -100;
}

.\[z-index\:0\] {
  z-index: 0;
}

.\[z-index\:100\] {
  z-index: 100;
}

.\[z-index\:1\] {
  z-index: 1;
}

.\[z-index\:200\] {
  z-index: 200;
}

.\[z-index\:2\] {
  z-index: 2;
}

.\[z-index\:3\] {
  z-index: 3;
}

.\[z-index\:4\] {
  z-index: 4;
}

.\[z-index\:6\] {
  z-index: 6;
}

.\[z-index\:7\] {
  z-index: 7;
}

.\[z-index\:8\] {
  z-index: 8;
}

.\[z-index\:90000000000000\] {
  z-index: 90000000000000;
}

.\[z-index\:9\] {
  z-index: 9;
}

.first-letter\:\[margin-right\:auto\]::first-letter {
  margin-right: auto;
}

.placeholder\:text-black::placeholder {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity, 1));
}

.placeholder\:\[color\:\#c4c4c4\]::placeholder {
  color: #c4c4c4;
}

.before\:table::before {
  content: var(--tw-content);
  display: table;
}

.before\:\[content\:\'\\e900\'\]::before {
  content: '\e900';
}

.before\:\[content\:\'\\e903\'\]::before {
  content: '\e903';
}

.before\:\[content\:\'_\'\]::before {
  content: ' ';
}

.before\:\[grid-column-end\:2\]::before {
  content: var(--tw-content);
  grid-column-end: 2;
}

.before\:\[grid-column-start\:1\]::before {
  content: var(--tw-content);
  grid-column-start: 1;
}

.before\:\[grid-row-end\:2\]::before {
  content: var(--tw-content);
  grid-row-end: 2;
}

.before\:\[grid-row-start\:1\]::before {
  content: var(--tw-content);
  grid-row-start: 1;
}

.after\:table::after {
  content: var(--tw-content);
  display: table;
}

.after\:\[clearclear\:both\]::after {
  content: var(--tw-content);
  clearclear: both;
}

.after\:\[content\:\'_\'\]::after {
  content: ' ';
}

.after\:\[grid-column-end\:2\]::after {
  content: var(--tw-content);
  grid-column-end: 2;
}

.after\:\[grid-column-start\:1\]::after {
  content: var(--tw-content);
  grid-column-start: 1;
}

.after\:\[grid-row-end\:2\]::after {
  content: var(--tw-content);
  grid-row-end: 2;
}

.after\:\[grid-row-start\:1\]::after {
  content: var(--tw-content);
  grid-row-start: 1;
}

.first-of-type\:block:first-of-type {
  display: block;
}

.last-of-type\:mb-5:last-of-type {
  margin-bottom: 1.25rem;
}

.visited\:text-black:visited {
  color: rgb(0 0 0 );
}

.visited\:no-underline:visited {
  text-decoration-line: none;
}

.visited\:\[background-color\:\#00cf56\]:visited {
  background-color: #00cf56;
}

.visited\:\[border-bottom\:1px_\#000\]:visited {
  border-bottom: 1px #000;
}

.visited\:\[border-bottom\:1px_none_\#000\]:visited {
  border-bottom: 1px none #000;
}

.default\:border-blueGray-200:default {
  --tw-border-opacity: 1;
  border-color: rgb(236 237 240 / var(--tw-border-opacity, 1));
}

.placeholder-shown\:border-\[1\.5px\]:placeholder-shown {
  border-width: 1.5px;
}

.placeholder-shown\:border-blueGray-200:placeholder-shown {
  --tw-border-opacity: 1;
  border-color: rgb(236 237 240 / var(--tw-border-opacity, 1));
}

.placeholder-shown\:text-sm-copy:placeholder-shown {
  font-size: 0.875rem;
  line-height: 1.375rem;
}

.placeholder-shown\:text-blueGray-800:placeholder-shown {
  --tw-text-opacity: 1;
  color: rgb(131 136 158 / var(--tw-text-opacity, 1));
}

.invalid\:border-red-300:invalid {
  --tw-border-opacity: 1;
  border-color: rgb(255 23 68 / var(--tw-border-opacity, 1));
}

.hover\:max-w-2xl:hover {
  max-width: 42rem;
}

.hover\:-translate-y-\[36px\]:hover {
  --tw-translate-y: -36px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.hover\:scale-110:hover {
  --tw-scale-x: 1.1;
  --tw-scale-y: 1.1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.hover\:scale-125:hover {
  --tw-scale-x: 1.25;
  --tw-scale-y: 1.25;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.hover\:scale-\[1\.02\]:hover {
  --tw-scale-x: 1.02;
  --tw-scale-y: 1.02;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.hover\:cursor-pointer:hover {
  cursor: pointer;
}

.hover\:border-b-2:hover {
  border-bottom-width: 2px;
}

.hover\:border-blueGray-1000:hover {
  --tw-border-opacity: 1;
  border-color: rgb(60 62 73 / var(--tw-border-opacity, 1));
}

.hover\:border-gray-500:hover {
  --tw-border-opacity: 1;
  border-color: rgb(140 140 140 / var(--tw-border-opacity, 1));
}

.hover\:border-green-400:hover {
  --tw-border-opacity: 1;
  border-color: rgb(0 230 118 / var(--tw-border-opacity, 1));
}

.hover\:border-purple-200:hover {
  --tw-border-opacity: 1;
  border-color: rgb(179 136 255 / var(--tw-border-opacity, 1));
}

.hover\:border-purple-500:hover {
  --tw-border-opacity: 1;
  border-color: rgb(98 0 234 / var(--tw-border-opacity, 1));
}

.hover\:bg-blueGray-1000:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(60 62 73 / var(--tw-bg-opacity, 1));
}

.hover\:bg-blueGray-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(236 237 240 / var(--tw-bg-opacity, 1));
}

.hover\:bg-gray-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(250 250 250 / var(--tw-bg-opacity, 1));
}

.hover\:bg-green-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(0 230 118 / var(--tw-bg-opacity, 1));
}

.hover\:bg-green-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(0 214 89 / var(--tw-bg-opacity, 1));
}

.hover\:bg-green-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(67 160 71 / var(--tw-bg-opacity, 1));
}

.hover\:bg-purple-200\/10:hover {
  background-color: rgb(179 136 255 / 0.1);
}

.hover\:bg-purple-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(98 0 234 / var(--tw-bg-opacity, 1));
}

.hover\:bg-white:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
}

.hover\:text-black:hover {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity, 1));
}

.hover\:text-gray-500:hover {
  --tw-text-opacity: 1;
  color: rgb(140 140 140 / var(--tw-text-opacity, 1));
}

.hover\:text-purple-500:hover {
  --tw-text-opacity: 1;
  color: rgb(98 0 234 / var(--tw-text-opacity, 1));
}

.hover\:text-white:hover {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

.hover\:underline:hover {
  text-decoration-line: underline;
}

.hover\:no-underline:hover {
  text-decoration-line: none;
}

.hover\:opacity-90:hover {
  opacity: 0.9;
}

.hover\:outline-none:hover {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.hover\:transition-all:hover {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.hover\:\[background-color\:\#00cf56\]:hover {
  background-color: #00cf56;
}

.hover\:\[background-color\:\#00d659\]:hover {
  background-color: #00d659;
}

.hover\:\[background-color\:\#83889e\]:hover {
  background-color: #83889e;
}

.hover\:\[background-color\:\#ecedf0\]:hover {
  background-color: #ecedf0;
}

.hover\:\[background-image\:linear-gradient\(180deg\2c _rgba\(0\2c _0\2c _0\2c _0\.05\)\2c _rgba\(0\2c _0\2c _0\2c _0\.05\)\)\]:hover {
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05));
}

.hover\:\[background-image\:linear-gradient\(____180deg\2c ____rgba\(0\2c _0\2c _0\2c _0\.05\)\2c ____rgba\(0\2c _0\2c _0\2c _0\.05\)__\)\]:hover {
  background-image: linear-gradient(    180deg,    rgba(0, 0, 0, 0.05),    rgba(0, 0, 0, 0.05)  );
}

.hover\:\[background-image\:none\]:hover {
  background-image: none;
}

.hover\:\[border-bottom-color\:\#00d659\]:hover {
  border-bottom-color: #00d659;
}

.hover\:\[border-bottom-style\:none\]:hover {
  border-bottom-style: none;
}

.hover\:\[border-color\:\#000\]:hover {
  border-color: #000;
}

.hover\:\[border-color\:\#6c01ea\]:hover {
  border-color: #6c01ea;
}

.hover\:\[border-style\:solid\]:hover {
  border-style: solid;
}

.hover\:\[border-width\:2px\]:hover {
  border-width: 2px;
}

.hover\:\[box-shadow\:0_0_0_4px_\#b9f6ca\]:hover {
  box-shadow: 0 0 0 4px #b9f6ca;
}

.hover\:\[color\:\#6200ee\]:hover {
  color: #6200ee;
}

.hover\:\[color\:\#6c00ea\]:hover {
  color: #6c00ea;
}

.hover\:\[color\:\#8800ea\]:hover {
  color: #8800ea;
}

.hover\:\[color\:white\]:hover {
  color: white;
}

.hover\:\[outline\:0\]:hover {
  outline: 0;
}

.hover\:\[text-decoration\:underline\]:hover {
  text-decoration: underline;
}

.hover\:\[transform\:none\]:hover {
  transform: none;
}

.hover\:\[transform\:rotate\(180deg\)\]:hover {
  transform: rotate(180deg);
}

.hover\:\[transform\:scale\(1\.02\)\]:hover {
  transform: scale(1.02);
}

.focus\:z-10:focus {
  z-index: 10;
}

.focus\:border-blueGray-800:focus {
  --tw-border-opacity: 1;
  border-color: rgb(131 136 158 / var(--tw-border-opacity, 1));
}

.focus\:border-purple-500:focus {
  --tw-border-opacity: 1;
  border-color: rgb(98 0 234 / var(--tw-border-opacity, 1));
}

.focus\:bg-gray-200:focus {
  --tw-bg-opacity: 1;
  background-color: rgb(245 245 245 / var(--tw-bg-opacity, 1));
}

.focus\:bg-green-500:focus {
  --tw-bg-opacity: 1;
  background-color: rgb(0 214 89 / var(--tw-bg-opacity, 1));
}

.focus\:text-black:focus {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity, 1));
}

.focus\:text-purple-500:focus {
  --tw-text-opacity: 1;
  color: rgb(98 0 234 / var(--tw-text-opacity, 1));
}

.focus\:no-underline:focus {
  text-decoration-line: none;
}

.focus\:outline-none:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.focus\:ring-2:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-offset-2:focus {
  --tw-ring-offset-width: 2px;
}

.focus\:\[background-color\:\#00cf56\]:focus {
  background-color: #00cf56;
}

.focus\:\[background-color\:\#00d659\]:focus {
  background-color: #00d659;
}

.focus\:\[border-bottom\:1px_\#000\]:focus {
  border-bottom: 1px #000;
}

.focus\:\[border-bottom\:1px_none_\#000\]:focus {
  border-bottom: 1px none #000;
}

.focus\:\[border-color\:\#3898ec\]:focus {
  border-color: #3898ec;
}

.focus\:\[border-color\:\#6c01ea\]:focus {
  border-color: #6c01ea;
}

.focus\:\[box-shadow\:none\]:focus {
  box-shadow: none;
}

.focus\:\[height\:auto\]:focus {
  height: auto;
}

.focus\:\[outline\:0\]:focus {
  outline: 0;
}

.focus\:\[text-decoration\:underline\]:focus {
  text-decoration: underline;
}

.active\:bg-gray-200:active {
  --tw-bg-opacity: 1;
  background-color: rgb(245 245 245 / var(--tw-bg-opacity, 1));
}

.active\:bg-green-500:active {
  --tw-bg-opacity: 1;
  background-color: rgb(0 214 89 / var(--tw-bg-opacity, 1));
}

.active\:text-black:active {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity, 1));
}

.active\:no-underline:active {
  text-decoration-line: none;
}

.active\:outline-none:active {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.active\:\[background-color\:\#00d659\]:active {
  background-color: #00d659;
}

.active\:\[border-bottom\:1px_\#000\]:active {
  border-bottom: 1px #000;
}

.active\:\[border-bottom\:1px_none_\#000\]:active {
  border-bottom: 1px none #000;
}

.active\:\[border-color\:\#6c01ea\]:active {
  border-color: #6c01ea;
}

.active\:\[outline\:0\]:active {
  outline: 0;
}

.active\:\[text-decoration\:underline\]:active {
  text-decoration: underline;
}

.disabled\:border-b-0:disabled {
  border-bottom-width: 0px;
}

.disabled\:text-gray-500:disabled {
  --tw-text-opacity: 1;
  color: rgb(140 140 140 / var(--tw-text-opacity, 1));
}

.group[open] .group-open\:rotate-0 {
  --tw-rotate: 0deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group[open] .group-open\:rotate-180 {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group:hover .group-hover\:visible {
  visibility: visible;
}

.group:hover .group-hover\:rotate-\[135deg\] {
  --tw-rotate: 135deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group:hover .group-hover\:scale-125 {
  --tw-scale-x: 1.25;
  --tw-scale-y: 1.25;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group:hover .group-hover\:border-purple-200 {
  --tw-border-opacity: 1;
  border-color: rgb(179 136 255 / var(--tw-border-opacity, 1));
}

.group:hover .group-hover\:bg-purple-200\/10 {
  background-color: rgb(179 136 255 / 0.1);
}

.group:hover .group-hover\:bg-purple-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(98 0 234 / var(--tw-bg-opacity, 1));
}

.group\/menu:hover .group-hover\/menu\:text-purple-500 {
  --tw-text-opacity: 1;
  color: rgb(98 0 234 / var(--tw-text-opacity, 1));
}

.group:hover .group-hover\:text-purple-500 {
  --tw-text-opacity: 1;
  color: rgb(98 0 234 / var(--tw-text-opacity, 1));
}

.group\/menu:hover .group-hover\/menu\:underline {
  text-decoration-line: underline;
}

.group:hover .group-hover\:underline {
  text-decoration-line: underline;
}

.group:hover .group-hover\:opacity-100 {
  opacity: 1;
}

.group:hover .group-hover\:opacity-70 {
  opacity: 0.7;
}

.group:hover .group-hover\:shadow-lg {
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.group:hover .group-hover\:\[text-decoration\:underline\] {
  text-decoration: underline;
}

.group[aria-current='true'] .group-\[\[aria-current\=\'true\'\]\]\:translate-y-0 {
  --tw-translate-y: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group[aria-current] .group-\[\[aria-current\]\]\:translate-x-0 {
  --tw-translate-x: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group[aria-current] .group-\[\[aria-current\]\]\:translate-y-0 {
  --tw-translate-y: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group[aria-current='true'] .group-\[\[aria-current\=\'true\'\]\]\:opacity-100 {
  opacity: 1;
}

.group[aria-current] .group-\[\[aria-current\]\]\:opacity-100 {
  opacity: 1;
}

.peer\/read-more-1:checked ~ .peer-checked\/read-more-1\:block {
  display: block;
}

.peer\/read-more-2:checked ~ .peer-checked\/read-more-2\:block {
  display: block;
}

.peer\/read-more-3:checked ~ .peer-checked\/read-more-3\:block {
  display: block;
}

.peer\/read-more-4:checked ~ .peer-checked\/read-more-4\:block {
  display: block;
}

.peer\/tab\$\{idx\}:checked ~ .peer-checked\/tab\$\{idx\}\:flex {
  display: flex;
}

.peer\/tab0:checked ~ .peer-checked\/tab0\:flex {
  display: flex;
}

.peer\/tab1:checked ~ .peer-checked\/tab1\:flex {
  display: flex;
}

.peer\/tab2:checked ~ .peer-checked\/tab2\:flex {
  display: flex;
}

.peer\/tab3:checked ~ .peer-checked\/tab3\:flex {
  display: flex;
}

.peer\/tab4:checked ~ .peer-checked\/tab4\:flex {
  display: flex;
}

.peer\/read-more-1:checked ~ .peer-checked\/read-more-1\:hidden {
  display: none;
}

.peer\/read-more-2:checked ~ .peer-checked\/read-more-2\:hidden {
  display: none;
}

.peer\/read-more-3:checked ~ .peer-checked\/read-more-3\:hidden {
  display: none;
}

.peer\/read-more-4:checked ~ .peer-checked\/read-more-4\:hidden {
  display: none;
}

.peer\/tab\$\{idx\}:checked ~ .peer-checked\/tab\$\{idx\}\:hidden {
  display: none;
}

.peer\/tab0:checked ~ .peer-checked\/tab0\:hidden {
  display: none;
}

.peer\/tab1:checked ~ .peer-checked\/tab1\:hidden {
  display: none;
}

.peer\/tab2:checked ~ .peer-checked\/tab2\:hidden {
  display: none;
}

.peer\/tab3:checked ~ .peer-checked\/tab3\:hidden {
  display: none;
}

.peer\/tab4:checked ~ .peer-checked\/tab4\:hidden {
  display: none;
}

.peer\/call:checked ~ .peer-checked\/call\:border-purple-200 {
  --tw-border-opacity: 1;
  border-color: rgb(179 136 255 / var(--tw-border-opacity, 1));
}

.peer\/code:checked ~ .peer-checked\/code\:border-purple-200 {
  --tw-border-opacity: 1;
  border-color: rgb(179 136 255 / var(--tw-border-opacity, 1));
}

.peer\/ean:checked ~ .peer-checked\/ean\:border-purple-200 {
  --tw-border-opacity: 1;
  border-color: rgb(179 136 255 / var(--tw-border-opacity, 1));
}

.peer\/email:checked ~ .peer-checked\/email\:border-purple-200 {
  --tw-border-opacity: 1;
  border-color: rgb(179 136 255 / var(--tw-border-opacity, 1));
}

.peer\/itf:checked ~ .peer-checked\/itf\:border-purple-200 {
  --tw-border-opacity: 1;
  border-color: rgb(179 136 255 / var(--tw-border-opacity, 1));
}

.peer\/sms:checked ~ .peer-checked\/sms\:border-purple-200 {
  --tw-border-opacity: 1;
  border-color: rgb(179 136 255 / var(--tw-border-opacity, 1));
}

.peer\/upc:checked ~ .peer-checked\/upc\:border-purple-200 {
  --tw-border-opacity: 1;
  border-color: rgb(179 136 255 / var(--tw-border-opacity, 1));
}

.peer\/url:checked ~ .peer-checked\/url\:border-purple-200 {
  --tw-border-opacity: 1;
  border-color: rgb(179 136 255 / var(--tw-border-opacity, 1));
}

.peer:checked ~ .peer-checked\:border-purple-200 {
  --tw-border-opacity: 1;
  border-color: rgb(179 136 255 / var(--tw-border-opacity, 1));
}

.peer\/call:checked ~ .peer-checked\/call\:bg-purple-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 235 255 / var(--tw-bg-opacity, 1));
}

.peer\/code:checked ~ .peer-checked\/code\:bg-purple-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 235 255 / var(--tw-bg-opacity, 1));
}

.peer\/dl-jpg:checked ~ .peer-checked\/dl-jpg\:bg-purple-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(98 0 234 / var(--tw-bg-opacity, 1));
}

.peer\/dl-png:checked ~ .peer-checked\/dl-png\:bg-purple-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(98 0 234 / var(--tw-bg-opacity, 1));
}

.peer\/dl-svg:checked ~ .peer-checked\/dl-svg\:bg-purple-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(98 0 234 / var(--tw-bg-opacity, 1));
}

.peer\/ean:checked ~ .peer-checked\/ean\:bg-purple-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 235 255 / var(--tw-bg-opacity, 1));
}

.peer\/email:checked ~ .peer-checked\/email\:bg-purple-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 235 255 / var(--tw-bg-opacity, 1));
}

.peer\/itf:checked ~ .peer-checked\/itf\:bg-purple-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 235 255 / var(--tw-bg-opacity, 1));
}

.peer\/sms:checked ~ .peer-checked\/sms\:bg-purple-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 235 255 / var(--tw-bg-opacity, 1));
}

.peer\/upc:checked ~ .peer-checked\/upc\:bg-purple-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 235 255 / var(--tw-bg-opacity, 1));
}

.peer\/url:checked ~ .peer-checked\/url\:bg-purple-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 235 255 / var(--tw-bg-opacity, 1));
}

.peer:checked ~ .peer-checked\:bg-purple-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(179 136 255 / var(--tw-bg-opacity, 1));
}

.peer:checked ~ .peer-checked\:bg-opacity-10 {
  --tw-bg-opacity: 0.1;
}

.peer\/call:checked ~ .peer-checked\/call\:text-purple-300 {
  --tw-text-opacity: 1;
  color: rgb(124 77 255 / var(--tw-text-opacity, 1));
}

.peer\/code:checked ~ .peer-checked\/code\:text-purple-300 {
  --tw-text-opacity: 1;
  color: rgb(124 77 255 / var(--tw-text-opacity, 1));
}

.peer\/dl-jpg:checked ~ .peer-checked\/dl-jpg\:text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

.peer\/dl-png:checked ~ .peer-checked\/dl-png\:text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

.peer\/dl-svg:checked ~ .peer-checked\/dl-svg\:text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

.peer\/ean:checked ~ .peer-checked\/ean\:text-purple-300 {
  --tw-text-opacity: 1;
  color: rgb(124 77 255 / var(--tw-text-opacity, 1));
}

.peer\/email:checked ~ .peer-checked\/email\:text-purple-300 {
  --tw-text-opacity: 1;
  color: rgb(124 77 255 / var(--tw-text-opacity, 1));
}

.peer\/itf:checked ~ .peer-checked\/itf\:text-purple-300 {
  --tw-text-opacity: 1;
  color: rgb(124 77 255 / var(--tw-text-opacity, 1));
}

.peer\/sms:checked ~ .peer-checked\/sms\:text-purple-300 {
  --tw-text-opacity: 1;
  color: rgb(124 77 255 / var(--tw-text-opacity, 1));
}

.peer\/upc:checked ~ .peer-checked\/upc\:text-purple-300 {
  --tw-text-opacity: 1;
  color: rgb(124 77 255 / var(--tw-text-opacity, 1));
}

.peer\/url:checked ~ .peer-checked\/url\:text-purple-300 {
  --tw-text-opacity: 1;
  color: rgb(124 77 255 / var(--tw-text-opacity, 1));
}

.peer:checked ~ .peer-checked\:text-purple-500 {
  --tw-text-opacity: 1;
  color: rgb(98 0 234 / var(--tw-text-opacity, 1));
}

.peer\/input-call:invalid ~ .peer-invalid\/input-call\:flex {
  display: flex;
}

.peer\/input-code:invalid ~ .peer-invalid\/input-code\:flex {
  display: flex;
}

.peer\/input-ean:invalid ~ .peer-invalid\/input-ean\:flex {
  display: flex;
}

.peer\/input-email:invalid ~ .peer-invalid\/input-email\:flex {
  display: flex;
}

.peer\/input-itf:invalid ~ .peer-invalid\/input-itf\:flex {
  display: flex;
}

.peer\/input-sms:invalid ~ .peer-invalid\/input-sms\:flex {
  display: flex;
}

.peer\/input-subject:invalid ~ .peer-invalid\/input-subject\:flex {
  display: flex;
}

.peer\/input-upc:invalid ~ .peer-invalid\/input-upc\:flex {
  display: flex;
}

.peer\/input-url:invalid ~ .peer-invalid\/input-url\:flex {
  display: flex;
}

.peer\/pwd:invalid ~ .peer-invalid\/pwd\:flex {
  display: flex;
}

.peer\/textarea-body:invalid ~ .peer-invalid\/textarea-body\:flex {
  display: flex;
}

.peer\/textarea-sms:invalid ~ .peer-invalid\/textarea-sms\:flex {
  display: flex;
}

.peer\/pwd:invalid ~ .peer-invalid\/pwd\:hidden {
  display: none;
}

.aria-selected\:translate-y-\[-15\%\][aria-selected="true"] {
  --tw-translate-y: -15%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.aria-selected\:translate-y-\[-5\%\][aria-selected="true"] {
  --tw-translate-y: -5%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.aria-selected\:border-b-2[aria-selected="true"] {
  border-bottom-width: 2px;
}

.aria-selected\:border-b-8[aria-selected="true"] {
  border-bottom-width: 8px;
}

.aria-selected\:border-purple-500[aria-selected="true"] {
  --tw-border-opacity: 1;
  border-color: rgb(98 0 234 / var(--tw-border-opacity, 1));
}

.aria-selected\:\!bg-purple-300[aria-selected="true"] {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(124 77 255 / var(--tw-bg-opacity, 1)) !important;
}

.aria-selected\:bg-white[aria-selected="true"] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
}

.aria-selected\:font-medium[aria-selected="true"] {
  font-weight: 500;
}

.aria-selected\:text-black[aria-selected="true"] {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity, 1));
}

.aria-selected\:text-purple-500[aria-selected="true"] {
  --tw-text-opacity: 1;
  color: rgb(98 0 234 / var(--tw-text-opacity, 1));
}

.aria-selected\:text-white[aria-selected="true"] {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

.aria-selected\:\[box-shadow\:0_6px_12px_0_rgb\(0\2c 0\2c 0\2c 40\%\)\][aria-selected="true"] {
  box-shadow: 0 6px 12px 0 rgb(0,0,0,40%);
}

.aria-\[current\]\:pointer-events-auto[aria-current] {
  pointer-events: auto;
}

.aria-\[current\]\:relative[aria-current] {
  position: relative;
}

.aria-\[current\]\:right-auto[aria-current] {
  right: auto;
}

.aria-\[current\]\:z-20[aria-current] {
  z-index: 20;
}

.group[aria-expanded="true"] .group-aria-expanded\:block {
  display: block;
}

.group[aria-expanded="true"] .group-aria-expanded\:-translate-y-2 {
  --tw-translate-y: -0.5rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group[aria-expanded="true"] .group-aria-expanded\:translate-y-2 {
  --tw-translate-y: 0.5rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group[aria-expanded="true"] .group-aria-expanded\:-rotate-45 {
  --tw-rotate: -45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group[aria-expanded="true"] .group-aria-expanded\:rotate-180 {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group[aria-expanded="true"] .group-aria-expanded\:rotate-45 {
  --tw-rotate: 45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group[aria-expanded="true"] .group-aria-expanded\:opacity-0 {
  opacity: 0;
}

.group[aria-pressed="true"] .group-aria-pressed\:-bottom-\[6px\] {
  bottom: -6px;
}

.group[aria-pressed="true"] .group-aria-pressed\:-left-\[6px\] {
  left: -6px;
}

.group[aria-selected="true"] .group-aria-selected\:max-h-full {
  max-height: 100%;
}

.group[aria-selected="true"] .group-aria-selected\:rotate-\[135deg\] {
  --tw-rotate: 135deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group[aria-selected="true"] .group-aria-selected\:border-black {
  --tw-border-opacity: 1;
  border-color: rgb(0 0 0 / var(--tw-border-opacity, 1));
}

.group[aria-selected="true"] .group-aria-selected\:bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
}

.group[aria-selected="true"] .group-aria-selected\:pl-4 {
  padding-left: 1rem;
}

.group[aria-selected="true"] .group-aria-selected\:text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity, 1));
}

.group[aria-selected="true"] .group-aria-selected\:text-purple-500 {
  --tw-text-opacity: 1;
  color: rgb(98 0 234 / var(--tw-text-opacity, 1));
}

.group[aria-selected="true"] .group-aria-selected\:opacity-100 {
  opacity: 1;
}

.group[aria-selected="true"] .group-aria-selected\:\[box-shadow\:0_6px_12px_0_rgb\(0\2c 0\2c 0\2c 40\%\)\] {
  box-shadow: 0 6px 12px 0 rgb(0,0,0,40%);
}

.group[aria-selected="true"] .group-aria-selected\:\[fill\:\#6200EA\] {
  fill: #6200EA;
}

.prose-headings\:my-4 :is(:where(h1, h2, h3, h4, h5, h6, th):not(:where([class~="not-prose"],[class~="not-prose"] *))) {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.prose-headings\:mt-0 :is(:where(h1, h2, h3, h4, h5, h6, th):not(:where([class~="not-prose"],[class~="not-prose"] *))) {
  margin-top: 0px;
}

.prose-headings\:pt-0 :is(:where(h1, h2, h3, h4, h5, h6, th):not(:where([class~="not-prose"],[class~="not-prose"] *))) {
  padding-top: 0px;
}

.prose-headings\:font-semibold :is(:where(h1, h2, h3, h4, h5, h6, th):not(:where([class~="not-prose"],[class~="not-prose"] *))) {
  font-weight: 600;
}

.prose-a\:m-0 :is(:where(a):not(:where([class~="not-prose"],[class~="not-prose"] *))) {
  margin: 0px;
}

.prose-figure\:mb-0 :is(:where(figure):not(:where([class~="not-prose"],[class~="not-prose"] *))) {
  margin-bottom: 0px;
}

@media (min-width: 640px) {
  .sm\:visible {
    visibility: visible;
  }

  .sm\:col-span-3 {
    grid-column: span 3 / span 3;
  }

  .sm\:col-span-4 {
    grid-column: span 4 / span 4;
  }

  .sm\:mx-8 {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .sm\:my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .sm\:mb-10 {
    margin-bottom: 2.5rem;
  }

  .sm\:mt-0 {
    margin-top: 0px;
  }

  .sm\:mt-4 {
    margin-top: 1rem;
  }

  .sm\:block {
    display: block;
  }

  .sm\:flex {
    display: flex;
  }

  .sm\:w-5\/12 {
    width: 41.666667%;
  }

  .sm\:w-auto {
    width: auto;
  }

  .sm\:w-full {
    width: 100%;
  }

  .sm\:max-w-lg {
    max-width: 32rem;
  }

  .sm\:max-w-none {
    max-width: none;
  }

  .sm\:max-w-xl {
    max-width: 36rem;
  }

  .sm\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .sm\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }

  .sm\:items-start {
    align-items: flex-start;
  }

  .sm\:items-center {
    align-items: center;
  }

  .sm\:rounded-2xl {
    border-radius: 1rem;
  }

  .sm\:rounded-lg {
    border-radius: 0.5rem;
  }

  .sm\:p-0 {
    padding: 0px;
  }

  .sm\:p-6 {
    padding: 1.5rem;
  }

  .sm\:px-20 {
    padding-left: 5rem;
    padding-right: 5rem;
  }

  .sm\:px-24 {
    padding-left: 6rem;
    padding-right: 6rem;
  }

  .sm\:py-9 {
    padding-top: 2.25rem;
    padding-bottom: 2.25rem;
  }

  .sm\:pl-12 {
    padding-left: 3rem;
  }

  .sm\:text-left {
    text-align: left;
  }

  .sm\:text-center {
    text-align: center;
  }

  .sm\:\[min-height\:300px\] {
    min-height: 300px;
  }
}

@media (min-width: 768px) {
  .md\:absolute {
    position: absolute;
  }

  .md\:relative {
    position: relative;
  }

  .md\:sticky {
    position: sticky;
  }

  .md\:-bottom-16 {
    bottom: -4rem;
  }

  .md\:-bottom-4 {
    bottom: -1rem;
  }

  .md\:-bottom-8 {
    bottom: -2rem;
  }

  .md\:-left-4 {
    left: -1rem;
  }

  .md\:bottom-0 {
    bottom: 0px;
  }

  .md\:left-0 {
    left: 0px;
  }

  .md\:left-5 {
    left: 1.25rem;
  }

  .md\:left-\[-107px\] {
    left: -107px;
  }

  .md\:left-\[8px\] {
    left: 8px;
  }

  .md\:right-20 {
    right: 5rem;
  }

  .md\:right-\[-200px\] {
    right: -200px;
  }

  .md\:right-\[2vw\] {
    right: 2vw;
  }

  .md\:right-\[5vw\] {
    right: 5vw;
  }

  .md\:top-0 {
    top: 0px;
  }

  .md\:top-28 {
    top: 7rem;
  }

  .md\:top-\[-25\%\] {
    top: -25%;
  }

  .md\:top-\[-8vw\] {
    top: -8vw;
  }

  .md\:top-\[100px\] {
    top: 100px;
  }

  .md\:top-\[120px\] {
    top: 120px;
  }

  .md\:top-\[170px\] {
    top: 170px;
  }

  .md\:top-\[220px\] {
    top: 220px;
  }

  .md\:top-\[80px\] {
    top: 80px;
  }

  .md\:top-\[87px\] {
    top: 87px;
  }

  .md\:z-\[-1\] {
    z-index: -1;
  }

  .md\:order-2 {
    order: 2;
  }

  .md\:order-3 {
    order: 3;
  }

  .md\:order-first {
    order: -9999;
  }

  .md\:order-last {
    order: 9999;
  }

  .md\:order-none {
    order: 0;
  }

  .md\:col-span-1 {
    grid-column: span 1 / span 1;
  }

  .md\:col-span-10 {
    grid-column: span 10 / span 10;
  }

  .md\:col-span-12 {
    grid-column: span 12 / span 12;
  }

  .md\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .md\:col-span-3 {
    grid-column: span 3 / span 3;
  }

  .md\:col-span-4 {
    grid-column: span 4 / span 4;
  }

  .md\:col-span-5 {
    grid-column: span 5 / span 5;
  }

  .md\:col-span-6 {
    grid-column: span 6 / span 6;
  }

  .md\:col-span-7 {
    grid-column: span 7 / span 7;
  }

  .md\:col-span-8 {
    grid-column: span 8 / span 8;
  }

  .md\:col-span-9 {
    grid-column: span 9 / span 9;
  }

  .md\:col-start-1 {
    grid-column-start: 1;
  }

  .md\:col-start-3 {
    grid-column-start: 3;
  }

  .md\:col-start-4 {
    grid-column-start: 4;
  }

  .md\:col-end-10 {
    grid-column-end: 10;
  }

  .md\:col-end-11 {
    grid-column-end: 11;
  }

  .md\:row-start-1 {
    grid-row-start: 1;
  }

  .md\:\!mx-0 {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .md\:mx-0 {
    margin-left: 0px;
    margin-right: 0px;
  }

  .md\:mx-24 {
    margin-left: 6rem;
    margin-right: 6rem;
  }

  .md\:mx-40 {
    margin-left: 10rem;
    margin-right: 10rem;
  }

  .md\:mx-\[unset\] {
    margin-left: unset;
    margin-right: unset;
  }

  .md\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .md\:my-10 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }

  .md\:my-15 {
    margin-top: 3.75rem;
    margin-bottom: 3.75rem;
  }

  .md\:my-20 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }

  .md\:my-24 {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }

  .md\:my-32 {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }

  .md\:my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .md\:-ml-32 {
    margin-left: -8rem;
  }

  .md\:-mr-10 {
    margin-right: -2.5rem;
  }

  .md\:-mt-14 {
    margin-top: -3.5rem;
  }

  .md\:-mt-16 {
    margin-top: -4rem;
  }

  .md\:-mt-20 {
    margin-top: -5rem;
  }

  .md\:-mt-24 {
    margin-top: -6rem;
  }

  .md\:mb-0 {
    margin-bottom: 0px;
  }

  .md\:mb-1 {
    margin-bottom: 0.25rem;
  }

  .md\:mb-10 {
    margin-bottom: 2.5rem;
  }

  .md\:mb-12 {
    margin-bottom: 3rem;
  }

  .md\:mb-14 {
    margin-bottom: 3.5rem;
  }

  .md\:mb-15 {
    margin-bottom: 3.75rem;
  }

  .md\:mb-16 {
    margin-bottom: 4rem;
  }

  .md\:mb-17 {
    margin-bottom: 4.25rem;
  }

  .md\:mb-2 {
    margin-bottom: 0.5rem;
  }

  .md\:mb-20 {
    margin-bottom: 5rem;
  }

  .md\:mb-24 {
    margin-bottom: 6rem;
  }

  .md\:mb-36 {
    margin-bottom: 9rem;
  }

  .md\:mb-4 {
    margin-bottom: 1rem;
  }

  .md\:mb-44 {
    margin-bottom: 11rem;
  }

  .md\:mb-5 {
    margin-bottom: 1.25rem;
  }

  .md\:mb-6 {
    margin-bottom: 1.5rem;
  }

  .md\:mb-8 {
    margin-bottom: 2rem;
  }

  .md\:ml-0 {
    margin-left: 0px;
  }

  .md\:ml-5 {
    margin-left: 1.25rem;
  }

  .md\:ml-8 {
    margin-left: 2rem;
  }

  .md\:ml-\[50px\] {
    margin-left: 50px;
  }

  .md\:ml-\[calc\(50vw-27vw\)\] {
    margin-left: calc(50vw - 27vw);
  }

  .md\:ml-auto {
    margin-left: auto;
  }

  .md\:mr-24 {
    margin-right: 6rem;
  }

  .md\:mr-4 {
    margin-right: 1rem;
  }

  .md\:mr-6 {
    margin-right: 1.5rem;
  }

  .md\:mr-7 {
    margin-right: 1.75rem;
  }

  .md\:mt-0 {
    margin-top: 0px;
  }

  .md\:mt-0\.5 {
    margin-top: 0.125rem;
  }

  .md\:mt-1 {
    margin-top: 0.25rem;
  }

  .md\:mt-10 {
    margin-top: 2.5rem;
  }

  .md\:mt-12 {
    margin-top: 3rem;
  }

  .md\:mt-15 {
    margin-top: 3.75rem;
  }

  .md\:mt-16 {
    margin-top: 4rem;
  }

  .md\:mt-20 {
    margin-top: 5rem;
  }

  .md\:mt-24 {
    margin-top: 6rem;
  }

  .md\:mt-28 {
    margin-top: 7rem;
  }

  .md\:mt-32 {
    margin-top: 8rem;
  }

  .md\:mt-4 {
    margin-top: 1rem;
  }

  .md\:mt-56 {
    margin-top: 14rem;
  }

  .md\:mt-8 {
    margin-top: 2rem;
  }

  .md\:mt-\[-40px\] {
    margin-top: -40px;
  }

  .md\:mt-\[3px\] {
    margin-top: 3px;
  }

  .md\:block {
    display: block;
  }

  .md\:inline-block {
    display: inline-block;
  }

  .md\:\!flex {
    display: flex !important;
  }

  .md\:flex {
    display: flex;
  }

  .md\:grid {
    display: grid;
  }

  .md\:contents {
    display: contents;
  }

  .md\:hidden {
    display: none;
  }

  .md\:h-72 {
    height: 18rem;
  }

  .md\:h-\[150px\] {
    height: 150px;
  }

  .md\:h-\[210px\] {
    height: 210px;
  }

  .md\:h-\[250px\] {
    height: 250px;
  }

  .md\:h-\[25px\] {
    height: 25px;
  }

  .md\:h-\[270px\] {
    height: 270px;
  }

  .md\:h-\[280px\] {
    height: 280px;
  }

  .md\:h-\[30px\] {
    height: 30px;
  }

  .md\:h-\[350px\] {
    height: 350px;
  }

  .md\:h-\[37\%\] {
    height: 37%;
  }

  .md\:h-\[40px\] {
    height: 40px;
  }

  .md\:h-\[480px\] {
    height: 480px;
  }

  .md\:h-\[540px\] {
    height: 540px;
  }

  .md\:h-\[566px\] {
    height: 566px;
  }

  .md\:h-\[60px\] {
    height: 60px;
  }

  .md\:h-\[65px\] {
    height: 65px;
  }

  .md\:h-\[70vh\] {
    height: 70vh;
  }

  .md\:h-\[80px\] {
    height: 80px;
  }

  .md\:h-auto {
    height: auto;
  }

  .md\:h-fit {
    height: fit-content;
  }

  .md\:h-full {
    height: 100%;
  }

  .md\:max-h-\[100\%\!important\] {
    max-height: 100% !important;
  }

  .md\:max-h-\[250px\] {
    max-height: 250px;
  }

  .md\:max-h-\[380px\] {
    max-height: 380px;
  }

  .md\:max-h-\[40vw\] {
    max-height: 40vw;
  }

  .md\:max-h-\[500px\] {
    max-height: 500px;
  }

  .md\:min-h-\[150vh\] {
    min-height: 150vh;
  }

  .md\:min-h-\[500px\] {
    min-height: 500px;
  }

  .md\:min-h-\[570px\] {
    min-height: 570px;
  }

  .md\:min-h-\[95px\] {
    min-height: 95px;
  }

  .md\:min-h-max {
    min-height: max-content;
  }

  .md\:w-1\/2 {
    width: 50%;
  }

  .md\:w-1\/3 {
    width: 33.333333%;
  }

  .md\:w-1\/4 {
    width: 25%;
  }

  .md\:w-10 {
    width: 2.5rem;
  }

  .md\:w-15 {
    width: 3.75rem;
  }

  .md\:w-3\/4 {
    width: 75%;
  }

  .md\:w-3\/5 {
    width: 60%;
  }

  .md\:w-4\/12 {
    width: 33.333333%;
  }

  .md\:w-6\/12 {
    width: 50%;
  }

  .md\:w-60 {
    width: 15rem;
  }

  .md\:w-\[100\%\] {
    width: 100%;
  }

  .md\:w-\[150px\] {
    width: 150px;
  }

  .md\:w-\[200px\] {
    width: 200px;
  }

  .md\:w-\[210px\] {
    width: 210px;
  }

  .md\:w-\[225px\] {
    width: 225px;
  }

  .md\:w-\[250px\] {
    width: 250px;
  }

  .md\:w-\[25px\] {
    width: 25px;
  }

  .md\:w-\[280px\] {
    width: 280px;
  }

  .md\:w-\[30\%\] {
    width: 30%;
  }

  .md\:w-\[30px\] {
    width: 30px;
  }

  .md\:w-\[35\%\] {
    width: 35%;
  }

  .md\:w-\[350px\] {
    width: 350px;
  }

  .md\:w-\[390px\] {
    width: 390px;
  }

  .md\:w-\[40\%\] {
    width: 40%;
  }

  .md\:w-\[40px\] {
    width: 40px;
  }

  .md\:w-\[44vw\] {
    width: 44vw;
  }

  .md\:w-\[450px\] {
    width: 450px;
  }

  .md\:w-\[45vw\] {
    width: 45vw;
  }

  .md\:w-\[470px\] {
    width: 470px;
  }

  .md\:w-\[47vw\] {
    width: 47vw;
  }

  .md\:w-\[480px\] {
    width: 480px;
  }

  .md\:w-\[485px\] {
    width: 485px;
  }

  .md\:w-\[50\%\] {
    width: 50%;
  }

  .md\:w-\[500px\] {
    width: 500px;
  }

  .md\:w-\[50vw\] {
    width: 50vw;
  }

  .md\:w-\[535px\] {
    width: 535px;
  }

  .md\:w-\[55\%\] {
    width: 55%;
  }

  .md\:w-\[55vw\] {
    width: 55vw;
  }

  .md\:w-\[60\%\] {
    width: 60%;
  }

  .md\:w-\[600px\] {
    width: 600px;
  }

  .md\:w-\[610px\] {
    width: 610px;
  }

  .md\:w-\[65px\] {
    width: 65px;
  }

  .md\:w-\[70\%\] {
    width: 70%;
  }

  .md\:w-\[700px\] {
    width: 700px;
  }

  .md\:w-\[770px\] {
    width: 770px;
  }

  .md\:w-\[80\%\] {
    width: 80%;
  }

  .md\:w-\[80px\] {
    width: 80px;
  }

  .md\:w-\[866px\] {
    width: 866px;
  }

  .md\:w-\[90\%\] {
    width: 90%;
  }

  .md\:w-\[90px\] {
    width: 90px;
  }

  .md\:w-\[calc\(100\%\+250px\)\] {
    width: calc(100% + 250px);
  }

  .md\:w-\[calc\(100\%-100px\)\] {
    width: calc(100% - 100px);
  }

  .md\:w-auto {
    width: auto;
  }

  .md\:w-full {
    width: 100%;
  }

  .md\:min-w-\[200px\] {
    min-width: 200px;
  }

  .md\:min-w-\[310px\] {
    min-width: 310px;
  }

  .md\:min-w-\[600px\] {
    min-width: 600px;
  }

  .md\:max-w-3xl {
    max-width: 48rem;
  }

  .md\:max-w-\[256px\] {
    max-width: 256px;
  }

  .md\:max-w-\[420px\] {
    max-width: 420px;
  }

  .md\:max-w-\[50\%\] {
    max-width: 50%;
  }

  .md\:max-w-full {
    max-width: 100%;
  }

  .md\:max-w-lg {
    max-width: 32rem;
  }

  .md\:max-w-sm {
    max-width: 24rem;
  }

  .md\:max-w-xl {
    max-width: 36rem;
  }

  .md\:flex-initial {
    flex: 0 1 auto;
  }

  .md\:flex-none {
    flex: none;
  }

  .md\:basis-6\/12 {
    flex-basis: 50%;
  }

  .md\:\!translate-x-0 {
    --tw-translate-x: 0px !important;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
  }

  .md\:-translate-y-full {
    --tw-translate-y: -100%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:translate-x-0 {
    --tw-translate-x: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:translate-x-20 {
    --tw-translate-x: 5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:translate-y-\[-50px\] {
    --tw-translate-y: -50px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:translate-y-full {
    --tw-translate-y: 100%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:scale-125 {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:scale-\[1\.4\] {
    --tw-scale-x: 1.4;
    --tw-scale-y: 1.4;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:scale-\[1\.5\] {
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:scale-\[2\.5\] {
    --tw-scale-x: 2.5;
    --tw-scale-y: 2.5;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:scale-\[2\] {
    --tw-scale-x: 2;
    --tw-scale-y: 2;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:scroll-mt-8 {
    scroll-margin-top: 2rem;
  }

  .md\:grid-flow-col {
    grid-auto-flow: column;
  }

  .md\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .md\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .md\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .md\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .md\:grid-cols-\[2fr_2fr_2fr\] {
    grid-template-columns: 2fr 2fr 2fr;
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:flex-row-reverse {
    flex-direction: row-reverse;
  }

  .md\:flex-col {
    flex-direction: column;
  }

  .md\:flex-nowrap {
    flex-wrap: nowrap;
  }

  .md\:items-start {
    align-items: flex-start;
  }

  .md\:items-center {
    align-items: center;
  }

  .md\:justify-start {
    justify-content: flex-start;
  }

  .md\:justify-end {
    justify-content: flex-end;
  }

  .md\:justify-center {
    justify-content: center;
  }

  .md\:justify-between {
    justify-content: space-between;
  }

  .md\:gap-10 {
    gap: 2.5rem;
  }

  .md\:gap-12 {
    gap: 3rem;
  }

  .md\:gap-16 {
    gap: 4rem;
  }

  .md\:gap-2 {
    gap: 0.5rem;
  }

  .md\:gap-20 {
    gap: 5rem;
  }

  .md\:gap-24 {
    gap: 6rem;
  }

  .md\:gap-3 {
    gap: 0.75rem;
  }

  .md\:gap-4 {
    gap: 1rem;
  }

  .md\:gap-6 {
    gap: 1.5rem;
  }

  .md\:gap-7 {
    gap: 1.75rem;
  }

  .md\:gap-x-0 {
    column-gap: 0px;
  }

  .md\:gap-x-10 {
    column-gap: 2.5rem;
  }

  .md\:gap-x-12 {
    column-gap: 3rem;
  }

  .md\:gap-x-15 {
    column-gap: 3.75rem;
  }

  .md\:gap-x-20 {
    column-gap: 5rem;
  }

  .md\:gap-x-24 {
    column-gap: 6rem;
  }

  .md\:gap-x-6 {
    column-gap: 1.5rem;
  }

  .md\:gap-x-8 {
    column-gap: 2rem;
  }

  .md\:gap-y-0 {
    row-gap: 0px;
  }

  .md\:gap-y-12 {
    row-gap: 3rem;
  }

  .md\:gap-y-2 {
    row-gap: 0.5rem;
  }

  .md\:gap-y-36 {
    row-gap: 9rem;
  }

  .md\:gap-y-4 {
    row-gap: 1rem;
  }

  .md\:justify-self-start {
    justify-self: start;
  }

  .md\:justify-self-end {
    justify-self: end;
  }

  .md\:overflow-hidden {
    overflow: hidden;
  }

  .md\:whitespace-nowrap {
    white-space: nowrap;
  }

  .md\:rounded {
    border-radius: 0.25rem;
  }

  .md\:rounded-2xl {
    border-radius: 1rem;
  }

  .md\:rounded-lg {
    border-radius: 0.5rem;
  }

  .md\:rounded-b-lg {
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }

  .md\:rounded-r-full {
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px;
  }

  .md\:rounded-t-lg {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }

  .md\:border-\[1px\] {
    border-width: 1px;
  }

  .md\:border-b-0 {
    border-bottom-width: 0px;
  }

  .md\:border-r-2 {
    border-right-width: 2px;
  }

  .md\:border-r-\[1px\] {
    border-right-width: 1px;
  }

  .md\:border-t-0 {
    border-top-width: 0px;
  }

  .md\:border-none {
    border-style: none;
  }

  .md\:border-blueGray-300 {
    --tw-border-opacity: 1;
    border-color: rgb(223 224 230 / var(--tw-border-opacity, 1));
  }

  .md\:bg-purple-400 {
    --tw-bg-opacity: 1;
    background-color: rgb(101 31 255 / var(--tw-bg-opacity, 1));
  }

  .md\:bg-purple-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(98 0 234 / var(--tw-bg-opacity, 1));
  }

  .md\:bg-white {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
  }

  @media (min-width: 768px) {
    .md\:md\:bg-blue-100 {
      --tw-bg-opacity: 1;
      background-color: rgb(229 231 255 / var(--tw-bg-opacity, 1));
    }
  }

  .md\:bg-cover {
    background-size: cover;
  }

  .md\:object-left {
    object-position: left;
  }

  .md\:p-0 {
    padding: 0px;
  }

  .md\:p-10 {
    padding: 2.5rem;
  }

  .md\:p-12 {
    padding: 3rem;
  }

  .md\:p-16 {
    padding: 4rem;
  }

  .md\:p-3 {
    padding: 0.75rem;
  }

  .md\:p-4 {
    padding: 1rem;
  }

  .md\:p-5 {
    padding: 1.25rem;
  }

  .md\:p-6 {
    padding: 1.5rem;
  }

  .md\:p-8 {
    padding: 2rem;
  }

  .md\:p-9 {
    padding: 2.25rem;
  }

  .md\:px-0 {
    padding-left: 0px;
    padding-right: 0px;
  }

  .md\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .md\:px-11 {
    padding-left: 2.75rem;
    padding-right: 2.75rem;
  }

  .md\:px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .md\:px-16 {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .md\:px-20 {
    padding-left: 5rem;
    padding-right: 5rem;
  }

  .md\:px-24 {
    padding-left: 6rem;
    padding-right: 6rem;
  }

  .md\:px-32 {
    padding-left: 8rem;
    padding-right: 8rem;
  }

  .md\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .md\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .md\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .md\:py-0 {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .md\:py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .md\:py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .md\:py-15 {
    padding-top: 3.75rem;
    padding-bottom: 3.75rem;
  }

  .md\:py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .md\:py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .md\:py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .md\:py-24 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .md\:py-28 {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }

  .md\:py-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }

  .md\:py-32 {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  .md\:py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .md\:py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .md\:py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .md\:pb-0 {
    padding-bottom: 0px;
  }

  .md\:pb-2 {
    padding-bottom: 0.5rem;
  }

  .md\:pb-20 {
    padding-bottom: 5rem;
  }

  .md\:pb-4 {
    padding-bottom: 1rem;
  }

  .md\:pb-40 {
    padding-bottom: 10rem;
  }

  .md\:pb-52 {
    padding-bottom: 13rem;
  }

  .md\:pb-6 {
    padding-bottom: 1.5rem;
  }

  .md\:pb-64 {
    padding-bottom: 16rem;
  }

  .md\:pe-5 {
    padding-inline-end: 1.25rem;
  }

  .md\:pl-0 {
    padding-left: 0px;
  }

  .md\:pl-10 {
    padding-left: 2.5rem;
  }

  .md\:pl-12 {
    padding-left: 3rem;
  }

  .md\:pl-4 {
    padding-left: 1rem;
  }

  .md\:pl-44 {
    padding-left: 11rem;
  }

  .md\:pl-8 {
    padding-left: 2rem;
  }

  .md\:pr-0 {
    padding-right: 0px;
  }

  .md\:pr-20 {
    padding-right: 5rem;
  }

  .md\:pr-24 {
    padding-right: 6rem;
  }

  .md\:pr-3 {
    padding-right: 0.75rem;
  }

  .md\:pr-4 {
    padding-right: 1rem;
  }

  .md\:pr-6 {
    padding-right: 1.5rem;
  }

  .md\:pr-8 {
    padding-right: 2rem;
  }

  .md\:pt-0 {
    padding-top: 0px;
  }

  .md\:pt-10 {
    padding-top: 2.5rem;
  }

  .md\:pt-12 {
    padding-top: 3rem;
  }

  .md\:pt-14 {
    padding-top: 3.5rem;
  }

  .md\:pt-20 {
    padding-top: 5rem;
  }

  .md\:pt-24 {
    padding-top: 6rem;
  }

  .md\:pt-4 {
    padding-top: 1rem;
  }

  .md\:pt-6 {
    padding-top: 1.5rem;
  }

  .md\:pt-8 {
    padding-top: 2rem;
  }

  .md\:text-left {
    text-align: left;
  }

  .md\:text-center {
    text-align: center;
  }

  .md\:text-right {
    text-align: right;
  }

  .md\:text-start {
    text-align: start;
  }

  .md\:\!text-8xl-copy {
    font-size: 6rem !important;
    line-height: 1 !important;
  }

  .md\:text-2xl-heading {
    font-size: 2rem;
    line-height: 2.25rem;
    letter-spacing: -0.04em;
  }

  .md\:text-5xl-heading {
    font-size: 3.5rem;
    line-height: 3.75rem;
    letter-spacing: -0.04em;
  }

  .md\:text-\[100px\] {
    font-size: 100px;
  }

  .md\:text-\[114px\] {
    font-size: 114px;
  }

  .md\:text-\[24px\] {
    font-size: 24px;
  }

  .md\:text-\[28px\] {
    font-size: 28px;
  }

  .md\:text-\[30px\] {
    font-size: 30px;
  }

  .md\:text-\[32px\] {
    font-size: 32px;
  }

  .md\:text-\[36px\] {
    font-size: 36px;
  }

  .md\:text-\[42px\] {
    font-size: 42px;
  }

  .md\:text-\[44px\] {
    font-size: 44px;
  }

  .md\:text-\[84px\] {
    font-size: 84px;
  }

  .md\:text-lg-copy {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .md\:text-lg-heading {
    font-size: 1.5rem;
    line-height: 2rem;
    letter-spacing: -0.04em;
  }

  .md\:text-md-copy {
    font-size: 1.125rem;
    line-height: 1.625rem;
  }

  .md\:text-sm-copy {
    font-size: 0.875rem;
    line-height: 1.375rem;
  }

  .md\:text-xl-copy {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .md\:font-medium {
    font-weight: 500;
  }

  .md\:leading-8 {
    line-height: 2rem;
  }

  .md\:text-blueGray-700 {
    --tw-text-opacity: 1;
    color: rgb(155 159 177 / var(--tw-text-opacity, 1));
  }

  .md\:text-white {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity, 1));
  }

  .md\:opacity-0 {
    opacity: 0;
  }

  .md\:shadow-lg {
    --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:shadow-md {
    --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:shadow-nav {
    --tw-shadow: 1px 1px 20px 0 #0002;
    --tw-shadow-colored: 1px 1px 20px 0 var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:transition-opacity {
    transition-property: opacity;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
  }

  .md\:\[background-image\:url\(\'\/images\/re-bg-img\.svg\'\)\] {
    background-image: url('/images/re-bg-img.svg');
  }

  .md\:\[background-position\:center_-70vh\] {
    background-position: center -70vh;
  }

  .md\:\[box-shadow\:4px_4px_12px_0px_\#00000026\] {
    box-shadow: 4px 4px 12px 0px #00000026;
  }

  .md\:\[box-shadow\:_0_0_10px_0_\#00000030\] {
    box-shadow: 0 0 10px 0 #00000030;
  }

  .md\:\[flex\:1\] {
    flex: 1;
  }

  .md\:\[grid-template-columns\:160px_1fr\] {
    grid-template-columns: 160px 1fr;
  }

  .md\:\[grid-template-columns\:1fr_1fr\] {
    grid-template-columns: 1fr 1fr;
  }

  .md\:\[grid-template-columns\:1fr_1fr_1fr\] {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .md\:\[grid-template-columns\:1fr_2fr\] {
    grid-template-columns: 1fr 2fr;
  }

  .md\:\[grid-template-columns\:1fr_2px_1fr\] {
    grid-template-columns: 1fr 2px 1fr;
  }

  .md\:\[grid-template-columns\:1fr_40px_1fr\] {
    grid-template-columns: 1fr 40px 1fr;
  }

  .md\:\[grid-template-columns\:3fr_2fr\] {
    grid-template-columns: 3fr 2fr;
  }

  .md\:\[grid-template-columns\:3fr_7fr\] {
    grid-template-columns: 3fr 7fr;
  }

  .md\:\[grid-template-columns\:5fr_24px_6fr\] {
    grid-template-columns: 5fr 24px 6fr;
  }

  .md\:\[grid-template-columns\:5fr_7fr\] {
    grid-template-columns: 5fr 7fr;
  }

  .md\:\[grid-template-columns\:6fr_1fr_5fr\] {
    grid-template-columns: 6fr 1fr 5fr;
  }

  .md\:\[grid-template-columns\:6fr_4fr\] {
    grid-template-columns: 6fr 4fr;
  }

  .md\:\[grid-template-columns\:6fr_8fr\] {
    grid-template-columns: 6fr 8fr;
  }

  .md\:\[grid-template-columns\:repeat\(3\2c 1fr\)\] {
    grid-template-columns: repeat(3,1fr);
  }

  .md\:\[grid-template-columns\:repeat\(4\2c 1fr\)\] {
    grid-template-columns: repeat(4,1fr);
  }

  .md\:\[height\:104px\] {
    height: 104px;
  }

  .md\:\[height\:200px\] {
    height: 200px;
  }

  .md\:\[height\:80px\] {
    height: 80px;
  }

  .md\:\[line-height\:0\.9\] {
    line-height: 0.9;
  }

  .md\:\[margin-bottom\:30px\] {
    margin-bottom: 30px;
  }

  .md\:\[margin\:0_auto_3rem\] {
    margin: 0 auto 3rem;
  }

  .md\:\[max-height\:100\%\] {
    max-height: 100%;
  }

  .md\:\[max-height\:350px\] {
    max-height: 350px;
  }

  .md\:\[max-width\:120px\] {
    max-width: 120px;
  }

  .md\:\[max-width\:80\%\] {
    max-width: 80%;
  }

  .md\:\[min-height\:260px\] {
    min-height: 260px;
  }

  .md\:\[min-height\:56px\] {
    min-height: 56px;
  }

  .md\:\[min-width\:180px\] {
    min-width: 180px;
  }

  .md\:\[order\:1\] {
    order: 1;
  }

  .md\:\[padding-block-end\:20px\] {
    padding-block-end: 20px;
  }

  .md\:\[right\:50px\] {
    right: 50px;
  }

  .md\:\[top\:-60px\] {
    top: -60px;
  }

  .md\:\[transform\:translateX\(53px\)\] {
    transform: translateX(53px);
  }

  .md\:\[transform\:translateY\(0px\)_\!important\] {
    transform: translateY(0px) !important;
  }

  .md\:\[transform\:translateY\(calc\(-50vh\+40px\)\)\] {
    transform: translateY(calc(-50vh + 40px));
  }

  .md\:\[width\:30px\] {
    width: 30px;
  }

  .md\:\[width\:70\%\] {
    width: 70%;
  }

  .md\:\[width\:90\%\] {
    width: 90%;
  }

  .md\:\[z-index\:-1\] {
    z-index: -1;
  }

  .md\:last-of-type\:mb-0:last-of-type {
    margin-bottom: 0px;
  }

  .md\:hover\:bg-green-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(0 214 89 / var(--tw-bg-opacity, 1));
  }

  .md\:hover\:bg-white:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
  }

  .group:hover .group-hover\:md\:rotate-45 {
    --tw-rotate: 45deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .group:hover .md\:group-hover\:opacity-100 {
    opacity: 1;
  }

  .aria-selected\:md\:translate-y-\[-7\%\][aria-selected="true"] {
    --tw-translate-y: -7%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:aria-selected\:bg-purple-100[aria-selected="true"] {
    --tw-bg-opacity: 1;
    background-color: rgb(243 235 255 / var(--tw-bg-opacity, 1));
  }

  .group[aria-selected="true"] .group-aria-selected\:md\:h-\[25px\] {
    height: 25px;
  }

  .group[aria-selected="true"] .group-aria-selected\:md\:w-\[25px\] {
    width: 25px;
  }

  .group[aria-selected="true"] .group-aria-selected\:md\:rotate-45 {
    --tw-rotate: 45deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
}

@media (min-width: 992px) {
  .lg\:absolute {
    position: absolute;
  }

  .lg\:relative {
    position: relative;
  }

  .lg\:sticky {
    position: sticky;
  }

  .lg\:left-5 {
    left: 1.25rem;
  }

  .lg\:right-5 {
    right: 1.25rem;
  }

  .lg\:top-\[87px\] {
    top: 87px;
  }

  .lg\:order-first {
    order: -9999;
  }

  .lg\:order-last {
    order: 9999;
  }

  .lg\:order-none {
    order: 0;
  }

  .lg\:col-span-1 {
    grid-column: span 1 / span 1;
  }

  .lg\:col-span-10 {
    grid-column: span 10 / span 10;
  }

  .lg\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .lg\:col-span-3 {
    grid-column: span 3 / span 3;
  }

  .lg\:col-span-4 {
    grid-column: span 4 / span 4;
  }

  .lg\:col-span-5 {
    grid-column: span 5 / span 5;
  }

  .lg\:col-span-6 {
    grid-column: span 6 / span 6;
  }

  .lg\:col-span-7 {
    grid-column: span 7 / span 7;
  }

  .lg\:col-span-8 {
    grid-column: span 8 / span 8;
  }

  .lg\:col-span-9 {
    grid-column: span 9 / span 9;
  }

  .lg\:col-start-5 {
    grid-column-start: 5;
  }

  .lg\:col-end-9 {
    grid-column-end: 9;
  }

  .lg\:-mb-18 {
    margin-bottom: -4.5rem;
  }

  .lg\:-mt-16 {
    margin-top: -4rem;
  }

  .lg\:-mt-20 {
    margin-top: -5rem;
  }

  .lg\:mb-0 {
    margin-bottom: 0px;
  }

  .lg\:mb-2 {
    margin-bottom: 0.5rem;
  }

  .lg\:mb-6 {
    margin-bottom: 1.5rem;
  }

  .lg\:ml-16 {
    margin-left: 4rem;
  }

  .lg\:mt-0 {
    margin-top: 0px;
  }

  .lg\:block {
    display: block;
  }

  .lg\:inline-block {
    display: inline-block;
  }

  .lg\:flex {
    display: flex;
  }

  .lg\:grid {
    display: grid;
  }

  .lg\:hidden {
    display: none;
  }

  .lg\:h-\[620px\] {
    height: 620px;
  }

  .lg\:h-\[80px\] {
    height: 80px;
  }

  .lg\:h-auto {
    height: auto;
  }

  .lg\:max-h-\[390px\] {
    max-height: 390px;
  }

  .lg\:min-h-28 {
    min-height: 7rem;
  }

  .lg\:min-h-32 {
    min-height: 8rem;
  }

  .lg\:min-h-\[480px\] {
    min-height: 480px;
  }

  .lg\:min-h-\[550px\] {
    min-height: 550px;
  }

  .lg\:w-1\/2 {
    width: 50%;
  }

  .lg\:w-3\/4 {
    width: 75%;
  }

  .lg\:w-4\/12 {
    width: 33.333333%;
  }

  .lg\:w-4\/5 {
    width: 80%;
  }

  .lg\:w-8\/12 {
    width: 66.666667%;
  }

  .lg\:w-\[230px\] {
    width: 230px;
  }

  .lg\:w-\[75\%\] {
    width: 75%;
  }

  .lg\:w-\[80\%\] {
    width: 80%;
  }

  .lg\:w-\[94px\] {
    width: 94px;
  }

  .lg\:w-auto {
    width: auto;
  }

  .lg\:w-full {
    width: 100%;
  }

  .lg\:max-w-\[23ch\] {
    max-width: 23ch;
  }

  .lg\:max-w-\[264px\] {
    max-width: 264px;
  }

  .lg\:max-w-\[360px\] {
    max-width: 360px;
  }

  .lg\:max-w-\[42\%\] {
    max-width: 42%;
  }

  .lg\:max-w-sm {
    max-width: 24rem;
  }

  .lg\:basis-2\/5 {
    flex-basis: 40%;
  }

  .lg\:basis-3\/5 {
    flex-basis: 60%;
  }

  .lg\:grid-flow-col {
    grid-auto-flow: column;
  }

  .lg\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .lg\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .lg\:grid-cols-\[2fr_2fr_2fr_2fr_1fr\] {
    grid-template-columns: 2fr 2fr 2fr 2fr 1fr;
  }

  .lg\:flex-row {
    flex-direction: row;
  }

  .lg\:flex-col {
    flex-direction: column;
  }

  .lg\:items-start {
    align-items: flex-start;
  }

  .lg\:items-center {
    align-items: center;
  }

  .lg\:justify-start {
    justify-content: flex-start;
  }

  .lg\:justify-center {
    justify-content: center;
  }

  .lg\:justify-between {
    justify-content: space-between;
  }

  .lg\:gap-20 {
    gap: 5rem;
  }

  .lg\:gap-5 {
    gap: 1.25rem;
  }

  .lg\:gap-6 {
    gap: 1.5rem;
  }

  .lg\:gap-x-10 {
    column-gap: 2.5rem;
  }

  .lg\:gap-x-20 {
    column-gap: 5rem;
  }

  .lg\:gap-x-8 {
    column-gap: 2rem;
  }

  .lg\:gap-y-0 {
    row-gap: 0px;
  }

  .lg\:overflow-auto {
    overflow: auto;
  }

  .lg\:overflow-hidden {
    overflow: hidden;
  }

  .lg\:rounded-br-2xl {
    border-bottom-right-radius: 1rem;
  }

  .lg\:rounded-tl-none {
    border-top-left-radius: 0px;
  }

  .lg\:bg-center {
    background-position: center;
  }

  .lg\:object-contain {
    object-fit: contain;
  }

  .lg\:p-15 {
    padding: 3.75rem;
  }

  .lg\:p-8 {
    padding: 2rem;
  }

  .lg\:px-0 {
    padding-left: 0px;
    padding-right: 0px;
  }

  .lg\:px-15 {
    padding-left: 3.75rem;
    padding-right: 3.75rem;
  }

  .lg\:px-16 {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .lg\:px-20 {
    padding-left: 5rem;
    padding-right: 5rem;
  }

  .lg\:px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .lg\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .lg\:py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .lg\:py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .lg\:py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .lg\:pb-0 {
    padding-bottom: 0px;
  }

  .lg\:pl-0 {
    padding-left: 0px;
  }

  .lg\:pl-18 {
    padding-left: 4.5rem;
  }

  .lg\:pl-48 {
    padding-left: 12rem;
  }

  .lg\:pr-0 {
    padding-right: 0px;
  }

  .lg\:pr-16 {
    padding-right: 4rem;
  }

  .lg\:pr-32 {
    padding-right: 8rem;
  }

  .lg\:pt-10 {
    padding-top: 2.5rem;
  }

  .lg\:pt-\[5710px\] {
    padding-top: 5710px;
  }

  .lg\:text-left {
    text-align: left;
  }

  .lg\:text-2xl-copy {
    font-size: 2rem;
    line-height: 2.25rem;
  }

  .lg\:text-2xl-heading {
    font-size: 2rem;
    line-height: 2.25rem;
    letter-spacing: -0.04em;
  }

  .lg\:text-3xl-heading {
    font-size: 2.25rem;
    line-height: 2.5rem;
    letter-spacing: -0.04em;
  }

  .lg\:text-4xl-heading {
    font-size: 2.75rem;
    line-height: 3rem;
    letter-spacing: -0.04em;
  }

  .lg\:text-5xl-heading {
    font-size: 3.5rem;
    line-height: 3.75rem;
    letter-spacing: -0.04em;
  }

  .lg\:text-6xl-heading {
    font-size: 5rem;
    line-height: 5.25rem;
    letter-spacing: -0.04em;
  }

  .lg\:text-\[100px\] {
    font-size: 100px;
  }

  .lg\:text-\[114px\] {
    font-size: 114px;
  }

  .lg\:text-\[60px\] {
    font-size: 60px;
  }

  .lg\:text-lg-copy {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .lg\:text-lg-heading {
    font-size: 1.5rem;
    line-height: 2rem;
    letter-spacing: -0.04em;
  }

  .lg\:text-md-copy {
    font-size: 1.125rem;
    line-height: 1.625rem;
  }

  .lg\:text-xs-copy {
    font-size: 0.75rem;
    line-height: 1.125rem;
  }

  .lg\:leading-normal {
    line-height: 1.5;
  }

  .lg\:tracking-\[-\.02em\] {
    letter-spacing: -.02em;
  }

  .lg\:tracking-negative {
    letter-spacing: -0.5rem;
  }

  .lg\:\[align-items\:end\] {
    align-items: end;
  }

  .lg\:\[align-items\:flex-end\] {
    align-items: flex-end;
  }

  .lg\:\[align-items\:flex-start\] {
    align-items: flex-start;
  }

  .lg\:\[align-self\:center\] {
    align-self: center;
  }

  .lg\:\[align-self\:start\] {
    align-self: start;
  }

  .lg\:\[align-self\:stretch\] {
    align-self: stretch;
  }

  .lg\:\[background-color\:\#f8f9fa\] {
    background-color: #f8f9fa;
  }

  .lg\:\[background-position\:0_0\] {
    background-position: 0 0;
  }

  .lg\:\[background-position\:0_50\%\] {
    background-position: 0 50%;
  }

  .lg\:\[background-position\:100\%_50\%\] {
    background-position: 100% 50%;
  }

  .lg\:\[background-size\:auto\] {
    background-size: auto;
  }

  .lg\:\[bottom\:24px\] {
    bottom: 24px;
  }

  .lg\:\[font-size\:28px\] {
    font-size: 28px;
  }

  .lg\:\[grid-column-gap\:0px\] {
    grid-column-gap: 0px;
  }

  .lg\:\[grid-column-gap\:10px\] {
    grid-column-gap: 10px;
  }

  .lg\:\[grid-column-gap\:16px\] {
    grid-column-gap: 16px;
  }

  .lg\:\[grid-column-gap\:24px\] {
    grid-column-gap: 24px;
  }

  .lg\:\[grid-column-gap\:25px\] {
    grid-column-gap: 25px;
  }

  .lg\:\[grid-column-gap\:48px\] {
    grid-column-gap: 48px;
  }

  .lg\:\[grid-column-gap\:8px\] {
    grid-column-gap: 8px;
  }

  .lg\:\[grid-row-gap\:0px\] {
    grid-row-gap: 0px;
  }

  .lg\:\[grid-row-gap\:16px\] {
    grid-row-gap: 16px;
  }

  .lg\:\[grid-row-gap\:24px\] {
    grid-row-gap: 24px;
  }

  .lg\:\[grid-row-gap\:25px\] {
    grid-row-gap: 25px;
  }

  .lg\:\[grid-row-gap\:48px\] {
    grid-row-gap: 48px;
  }

  .lg\:\[grid-row-gap\:8px\] {
    grid-row-gap: 8px;
  }

  .lg\:\[grid-template-columns\:1fr_10fr_1fr\] {
    grid-template-columns: 1fr 10fr 1fr;
  }

  .lg\:\[grid-template-columns\:1fr_1fr\] {
    grid-template-columns: 1fr 1fr;
  }

  .lg\:\[grid-template-columns\:1fr_1fr_1fr\] {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .lg\:\[grid-template-columns\:1fr_1fr_1fr_1fr\] {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .lg\:\[grid-template-columns\:1fr_1fr_1fr_1fr_1fr_1fr\] {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }

  .lg\:\[grid-template-columns\:1fr_4fr_1fr_5fr_1fr\] {
    grid-template-columns: 1fr 4fr 1fr 5fr 1fr;
  }

  .lg\:\[grid-template-columns\:1fr_5fr_1fr\] {
    grid-template-columns: 1fr 5fr 1fr;
  }

  .lg\:\[grid-template-columns\:1fr_5fr_1fr_4fr_1fr\] {
    grid-template-columns: 1fr 5fr 1fr 4fr 1fr;
  }

  .lg\:\[grid-template-columns\:1fr_5fr_5fr_1fr\] {
    grid-template-columns: 1fr 5fr 5fr 1fr;
  }

  .lg\:\[grid-template-columns\:1fr_5fr_6fr_1fr\] {
    grid-template-columns: 1fr 5fr 6fr 1fr;
  }

  .lg\:\[grid-template-columns\:1fr_6fr_5fr_1fr\] {
    grid-template-columns: 1fr 6fr 5fr 1fr;
  }

  .lg\:\[grid-template-columns\:2fr_1fr_3fr\] {
    grid-template-columns: 2fr 1fr 3fr;
  }

  .lg\:\[grid-template-columns\:2fr_2fr_2fr\] {
    grid-template-columns: 2fr 2fr 2fr;
  }

  .lg\:\[grid-template-columns\:2fr_2fr_2fr_2fr_2fr_2fr\] {
    grid-template-columns: 2fr 2fr 2fr 2fr 2fr 2fr;
  }

  .lg\:\[grid-template-columns\:2fr_8fr_2fr\] {
    grid-template-columns: 2fr 8fr 2fr;
  }

  .lg\:\[grid-template-columns\:2fr_minmax\(200px\2c _6fr\)_2fr\] {
    grid-template-columns: 2fr minmax(200px, 6fr) 2fr;
  }

  .lg\:\[grid-template-columns\:3fr_1fr_8fr\] {
    grid-template-columns: 3fr 1fr 8fr;
  }

  .lg\:\[grid-template-columns\:3fr_2fr\] {
    grid-template-columns: 3fr 2fr;
  }

  .lg\:\[grid-template-columns\:3fr_6fr_3fr\] {
    grid-template-columns: 3fr 6fr 3fr;
  }

  .lg\:\[grid-template-columns\:4fr_1fr_5\.75fr_0\.25fr\] {
    grid-template-columns: 4fr 1fr 5.75fr 0.25fr;
  }

  .lg\:\[grid-template-columns\:4fr_1fr_5fr\] {
    grid-template-columns: 4fr 1fr 5fr;
  }

  .lg\:\[grid-template-columns\:4fr_1fr_7fr\] {
    grid-template-columns: 4fr 1fr 7fr;
  }

  .lg\:\[grid-template-columns\:4fr_2fr_6fr\] {
    grid-template-columns: 4fr 2fr 6fr;
  }

  .lg\:\[grid-template-columns\:4fr_4fr_4fr\] {
    grid-template-columns: 4fr 4fr 4fr;
  }

  .lg\:\[grid-template-columns\:4fr_4fr_4fr_4fr\] {
    grid-template-columns: 4fr 4fr 4fr 4fr;
  }

  .lg\:\[grid-template-columns\:5fr_1fr_4fr\] {
    grid-template-columns: 5fr 1fr 4fr;
  }

  .lg\:\[grid-template-columns\:5fr_1fr_5fr\] {
    grid-template-columns: 5fr 1fr 5fr;
  }

  .lg\:\[grid-template-columns\:5fr_1fr_6fr\] {
    grid-template-columns: 5fr 1fr 6fr;
  }

  .lg\:\[grid-template-columns\:5fr_6fr\] {
    grid-template-columns: 5fr 6fr;
  }

  .lg\:\[grid-template-columns\:6fr_1fr_5fr\] {
    grid-template-columns: 6fr 1fr 5fr;
  }

  .lg\:\[grid-template-columns\:6fr_2fr_4fr\] {
    grid-template-columns: 6fr 2fr 4fr;
  }

  .lg\:\[grid-template-columns\:6fr_6fr\] {
    grid-template-columns: 6fr 6fr;
  }

  .lg\:\[grid-template-columns\:7fr_5fr\] {
    grid-template-columns: 7fr 5fr;
  }

  .lg\:\[grid-template-columns\:auto\] {
    grid-template-columns: auto;
  }

  .lg\:\[grid-template-rows\:auto\] {
    grid-template-rows: auto;
  }

  .lg\:\[height\:100\%\] {
    height: 100%;
  }

  .lg\:\[height\:100px\] {
    height: 100px;
  }

  .lg\:\[height\:100vh\] {
    height: 100vh;
  }

  .lg\:\[height\:104px\] {
    height: 104px;
  }

  .lg\:\[height\:120px\] {
    height: 120px;
  }

  .lg\:\[height\:16px\] {
    height: 16px;
  }

  .lg\:\[height\:180px\] {
    height: 180px;
  }

  .lg\:\[height\:20px\] {
    height: 20px;
  }

  .lg\:\[height\:215px\] {
    height: 215px;
  }

  .lg\:\[height\:28px\] {
    height: 28px;
  }

  .lg\:\[height\:28vh\] {
    height: 28vh;
  }

  .lg\:\[height\:300px\] {
    height: 300px;
  }

  .lg\:\[height\:36px\] {
    height: 36px;
  }

  .lg\:\[height\:40px\] {
    height: 40px;
  }

  .lg\:\[height\:446px\] {
    height: 446px;
  }

  .lg\:\[height\:480px\] {
    height: 480px;
  }

  .lg\:\[height\:528px\] {
    height: 528px;
  }

  .lg\:\[height\:64px\] {
    height: 64px;
  }

  .lg\:\[height\:70vh\] {
    height: 70vh;
  }

  .lg\:\[height\:78px\] {
    height: 78px;
  }

  .lg\:\[height\:80px\] {
    height: 80px;
  }

  .lg\:\[height\:85px\] {
    height: 85px;
  }

  .lg\:\[height\:8vh\] {
    height: 8vh;
  }

  .lg\:\[justify-self\:center\] {
    justify-self: center;
  }

  .lg\:\[justify-self\:end\] {
    justify-self: end;
  }

  .lg\:\[justify-self\:start\] {
    justify-self: start;
  }

  .lg\:\[justify-self\:stretch\] {
    justify-self: stretch;
  }

  .lg\:\[left\:-50px\] {
    left: -50px;
  }

  .lg\:\[left\:603px\] {
    left: 603px;
  }

  .lg\:\[left\:660px\] {
    left: 660px;
  }

  .lg\:\[left\:900px\] {
    left: 900px;
  }

  .lg\:\[line-height\:18px\] {
    line-height: 18px;
  }

  .lg\:\[line-height\:28px\] {
    line-height: 28px;
  }

  .lg\:\[line-height\:32px\] {
    line-height: 32px;
  }

  .lg\:\[line-height\:40px\] {
    line-height: 40px;
  }

  .lg\:\[line-height\:48px\] {
    line-height: 48px;
  }

  .lg\:\[margin-bottom\:-40px\] {
    margin-bottom: -40px;
  }

  .lg\:\[margin-bottom\:24px\] {
    margin-bottom: 24px;
  }

  .lg\:\[margin-bottom\:35px\] {
    margin-bottom: 35px;
  }

  .lg\:\[margin-bottom\:40px\] {
    margin-bottom: 40px;
  }

  .lg\:\[margin-bottom\:60px\] {
    margin-bottom: 60px;
  }

  .lg\:\[margin-left\:-61px\] {
    margin-left: -61px;
  }

  .lg\:\[margin-left\:10px\] {
    margin-left: 10px;
  }

  .lg\:\[margin-left\:14px\] {
    margin-left: 14px;
  }

  .lg\:\[margin-left\:20px\] {
    margin-left: 20px;
  }

  .lg\:\[margin-left\:23px\] {
    margin-left: 23px;
  }

  .lg\:\[margin-left\:2em\] {
    margin-left: 2em;
  }

  .lg\:\[margin-left\:40px\] {
    margin-left: 40px;
  }

  .lg\:\[margin-left\:5px\] {
    margin-left: 5px;
  }

  .lg\:\[margin-left\:8px\] {
    margin-left: 8px;
  }

  .lg\:\[margin-left\:auto\] {
    margin-left: auto;
  }

  .lg\:\[margin-right\:-50px\] {
    margin-right: -50px;
  }

  .lg\:\[margin-right\:-61px\] {
    margin-right: -61px;
  }

  .lg\:\[margin-right\:10px\] {
    margin-right: 10px;
  }

  .lg\:\[margin-right\:15px\] {
    margin-right: 15px;
  }

  .lg\:\[margin-right\:16px\] {
    margin-right: 16px;
  }

  .lg\:\[margin-right\:20px\] {
    margin-right: 20px;
  }

  .lg\:\[margin-right\:23px\] {
    margin-right: 23px;
  }

  .lg\:\[margin-right\:24px\] {
    margin-right: 24px;
  }

  .lg\:\[margin-right\:2em\] {
    margin-right: 2em;
  }

  .lg\:\[margin-right\:40px\] {
    margin-right: 40px;
  }

  .lg\:\[margin-right\:auto\] {
    margin-right: auto;
  }

  .lg\:\[margin-top\:-300px\] {
    margin-top: -300px;
  }

  .lg\:\[margin-top\:20px\] {
    margin-top: 20px;
  }

  .lg\:\[margin-top\:24px\] {
    margin-top: 24px;
  }

  .lg\:\[margin-top\:35px\] {
    margin-top: 35px;
  }

  .lg\:\[margin-top\:40px\] {
    margin-top: 40px;
  }

  .lg\:\[margin-top\:46px\] {
    margin-top: 46px;
  }

  .lg\:\[margin-top\:8px\] {
    margin-top: 8px;
  }

  .lg\:\[max-width\:140px\] {
    max-width: 140px;
  }

  .lg\:\[max-width\:40ch\] {
    max-width: 40ch;
  }

  .lg\:\[max-width\:48\%\] {
    max-width: 48%;
  }

  .lg\:\[max-width\:50ch\] {
    max-width: 50ch;
  }

  .lg\:\[max-width\:640px\] {
    max-width: 640px;
  }

  .lg\:\[max-width\:65ch\] {
    max-width: 65ch;
  }

  .lg\:\[max-width\:940px\] {
    max-width: 940px;
  }

  .lg\:\[min-height\:160px\] {
    min-height: 160px;
  }

  .lg\:\[min-height\:490px\] {
    min-height: 490px;
  }

  .lg\:\[min-height\:500px\] {
    min-height: 500px;
  }

  .lg\:\[min-height\:700px\] {
    min-height: 700px;
  }

  .lg\:\[min-height\:750px\] {
    min-height: 750px;
  }

  .lg\:\[min-width\:240px\] {
    min-width: 240px;
  }

  .lg\:\[padding-left\:10px\] {
    padding-left: 10px;
  }

  .lg\:\[padding-left\:16px\] {
    padding-left: 16px;
  }

  .lg\:\[padding-left\:20px\] {
    padding-left: 20px;
  }

  .lg\:\[padding-left\:24px\] {
    padding-left: 24px;
  }

  .lg\:\[padding-left\:25px\] {
    padding-left: 25px;
  }

  .lg\:\[padding-left\:32px\] {
    padding-left: 32px;
  }

  .lg\:\[padding-left\:40px\] {
    padding-left: 40px;
  }

  .lg\:\[padding-left\:60px\] {
    padding-left: 60px;
  }

  .lg\:\[padding-right\:10px\] {
    padding-right: 10px;
  }

  .lg\:\[padding-right\:22px\] {
    padding-right: 22px;
  }

  .lg\:\[padding-right\:24px\] {
    padding-right: 24px;
  }

  .lg\:\[padding-right\:40px\] {
    padding-right: 40px;
  }

  .lg\:\[padding-top\:12px\] {
    padding-top: 12px;
  }

  .lg\:\[padding-top\:15px\] {
    padding-top: 15px;
  }

  .lg\:\[padding-top\:220px\] {
    padding-top: 220px;
  }

  .lg\:\[padding-top\:25px\] {
    padding-top: 25px;
  }

  .lg\:\[padding-top\:40px\] {
    padding-top: 40px;
  }

  .lg\:\[padding-top\:55px\] {
    padding-top: 55px;
  }

  .lg\:\[padding-top\:56px\] {
    padding-top: 56px;
  }

  .lg\:\[padding-top\:80px\] {
    padding-top: 80px;
  }

  .lg\:\[padding\:10px_24px\] {
    padding: 10px 24px;
  }

  .lg\:\[padding\:10px_32px\] {
    padding: 10px 32px;
  }

  .lg\:\[padding\:12px_40px\] {
    padding: 12px 40px;
  }

  .lg\:\[padding\:15px_10px\] {
    padding: 15px 10px;
  }

  .lg\:\[padding\:24px\] {
    padding: 24px;
  }

  .lg\:\[padding\:25px\] {
    padding: 25px;
  }

  .lg\:\[padding\:30px\] {
    padding: 30px;
  }

  .lg\:\[padding\:30px_40px_50px\] {
    padding: 30px 40px 50px;
  }

  .lg\:\[padding\:40px_50px\] {
    padding: 40px 50px;
  }

  .lg\:\[padding\:70px\] {
    padding: 70px;
  }

  .lg\:\[padding\:70px_60px_70px_40px\] {
    padding: 70px 60px 70px 40px;
  }

  .lg\:\[scroll-padding-top\:80px\] {
    scroll-padding-top: 80px;
  }

  .lg\:\[top\:118px\] {
    top: 118px;
  }

  .lg\:\[top\:173px\] {
    top: 173px;
  }

  .lg\:\[top\:180px\] {
    top: 180px;
  }

  .lg\:\[top\:230px\] {
    top: 230px;
  }

  .lg\:\[top\:80px\] {
    top: 80px;
  }

  .lg\:\[width\:100px\] {
    width: 100px;
  }

  .lg\:\[width\:12\.5\%\] {
    width: 12.5%;
  }

  .lg\:\[width\:1px\] {
    width: 1px;
  }

  .lg\:\[width\:200px\] {
    width: 200px;
  }

  .lg\:\[width\:286px\] {
    width: 286px;
  }

  .lg\:\[width\:30\%\] {
    width: 30%;
  }

  .lg\:\[width\:40\%\] {
    width: 40%;
  }

  .lg\:\[width\:45\%\] {
    width: 45%;
  }

  .lg\:\[width\:50\%\] {
    width: 50%;
  }

  .lg\:\[width\:50vw\] {
    width: 50vw;
  }

  .lg\:\[width\:60\%\] {
    width: 60%;
  }

  .lg\:\[width\:639px\] {
    width: 639px;
  }

  .lg\:\[width\:78px\] {
    width: 78px;
  }

  .lg\:\[width\:792px\] {
    width: 792px;
  }

  .lg\:\[width\:80\%\] {
    width: 80%;
  }

  .lg\:\[width\:90\%\] {
    width: 90%;
  }

  .lg\:\[width\:99\%\] {
    width: 99%;
  }

  .lg\:\[width\:auto\] {
    width: auto;
  }

  .group:hover .group-hover\:lg\:bg-purple-300 {
    --tw-bg-opacity: 1;
    background-color: rgb(124 77 255 / var(--tw-bg-opacity, 1));
  }
}

@media (min-width: 1280px) {
  .xl\:static {
    position: static;
  }

  .xl\:left-12 {
    left: 3rem;
  }

  .xl\:left-4 {
    left: 1rem;
  }

  .xl\:right-12 {
    right: 3rem;
  }

  .xl\:right-4 {
    right: 1rem;
  }

  .xl\:col-span-6 {
    grid-column: span 6 / span 6;
  }

  .xl\:-mx-12 {
    margin-left: -3rem;
    margin-right: -3rem;
  }

  .xl\:-ml-12 {
    margin-left: -3rem;
  }

  .xl\:-mr-12 {
    margin-right: -3rem;
  }

  .xl\:-mt-28 {
    margin-top: -7rem;
  }

  .xl\:inline-flex {
    display: inline-flex;
  }

  .xl\:grid {
    display: grid;
  }

  .xl\:w-1\/3 {
    width: 33.333333%;
  }

  .xl\:w-2\/4 {
    width: 50%;
  }

  .xl\:w-3\/4 {
    width: 75%;
  }

  .xl\:w-\[108px\] {
    width: 108px;
  }

  .xl\:w-full {
    width: 100%;
  }

  .xl\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .xl\:justify-start {
    justify-content: flex-start;
  }

  .xl\:justify-center {
    justify-content: center;
  }

  .xl\:gap-32 {
    gap: 8rem;
  }

  .xl\:gap-40 {
    gap: 10rem;
  }

  .xl\:object-fill {
    object-fit: fill;
  }

  .xl\:p-10 {
    padding: 2.5rem;
  }

  .xl\:p-12 {
    padding: 3rem;
  }

  .xl\:px-0 {
    padding-left: 0px;
    padding-right: 0px;
  }

  .xl\:px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .xl\:px-64 {
    padding-left: 16rem;
    padding-right: 16rem;
  }

  .xl\:py-24 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .xl\:pl-0 {
    padding-left: 0px;
  }

  .xl\:pl-12 {
    padding-left: 3rem;
  }

  .xl\:pl-18 {
    padding-left: 4.5rem;
  }

  .xl\:pl-20 {
    padding-left: 5rem;
  }

  .xl\:pr-0 {
    padding-right: 0px;
  }

  .xl\:text-2xl-copy {
    font-size: 2rem;
    line-height: 2.25rem;
  }

  .xl\:text-3xl-heading {
    font-size: 2.25rem;
    line-height: 2.5rem;
    letter-spacing: -0.04em;
  }

  .xl\:text-4xl-heading {
    font-size: 2.75rem;
    line-height: 3rem;
    letter-spacing: -0.04em;
  }

  .xl\:text-5xl-heading {
    font-size: 3.5rem;
    line-height: 3.75rem;
    letter-spacing: -0.04em;
  }

  .xl\:text-lg-copy {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .xl\:text-xl-copy {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .xl\:\[align-self\:stretch\] {
    align-self: stretch;
  }

  .xl\:\[font-size\:48px\] {
    font-size: 48px;
  }

  .xl\:\[height\:36px\] {
    height: 36px;
  }

  .xl\:\[height\:48px\] {
    height: 48px;
  }

  .xl\:\[justify-self\:auto\] {
    justify-self: auto;
  }

  .xl\:\[left\:48px\] {
    left: 48px;
  }

  .xl\:\[line-height\:32px\] {
    line-height: 32px;
  }

  .xl\:\[line-height\:36px\] {
    line-height: 36px;
  }

  .xl\:\[line-height\:38px\] {
    line-height: 38px;
  }

  .xl\:\[line-height\:48px\] {
    line-height: 48px;
  }

  .xl\:\[line-height\:52px\] {
    line-height: 52px;
  }

  .xl\:\[line-height\:60px\] {
    line-height: 60px;
  }

  .xl\:\[max-width\:none\] {
    max-width: none;
  }

  .xl\:\[min-height\:540px\] {
    min-height: 540px;
  }

  .xl\:\[padding-left\:30px\] {
    padding-left: 30px;
  }

  .xl\:\[padding-left\:48px\] {
    padding-left: 48px;
  }

  .xl\:\[padding-right\:24px\] {
    padding-right: 24px;
  }

  .xl\:\[padding-right\:30px\] {
    padding-right: 30px;
  }

  .xl\:\[padding-right\:48px\] {
    padding-right: 48px;
  }

  .xl\:\[right\:48px\] {
    right: 48px;
  }

  .xl\:\[top\:312px\] {
    top: 312px;
  }

  .xl\:\[width\:70\%\] {
    width: 70%;
  }

  .xl\:\[width\:95\%\] {
    width: 95%;
  }
}

@media (min-width: 1440px) {
  .\32xl\:left-0 {
    left: 0px;
  }

  .\32xl\:left-32 {
    left: 8rem;
  }

  .\32xl\:right-0 {
    right: 0px;
  }

  .\32xl\:right-32 {
    right: 8rem;
  }

  .\32xl\:-mx-\[120px\] {
    margin-left: -120px;
    margin-right: -120px;
  }

  .\32xl\:-ml-\[120px\] {
    margin-left: -120px;
  }

  .\32xl\:-mr-\[120px\] {
    margin-right: -120px;
  }

  .\32xl\:mt-0 {
    margin-top: 0px;
  }

  .\32xl\:block {
    display: block;
  }

  .\32xl\:flex {
    display: flex;
  }

  .\32xl\:flex-col {
    flex-direction: column;
  }

  .\32xl\:gap-x-20 {
    column-gap: 5rem;
  }

  .\32xl\:px-0 {
    padding-left: 0px;
    padding-right: 0px;
  }

  .\32xl\:px-\[120px\] {
    padding-left: 120px;
    padding-right: 120px;
  }

  .\32xl\:pb-60 {
    padding-bottom: 15rem;
  }

  .\32xl\:pl-40 {
    padding-left: 10rem;
  }

  .\32xl\:pl-\[120px\] {
    padding-left: 120px;
  }

  .\32xl\:pr-\[120px\] {
    padding-right: 120px;
  }

  .\32xl\:pt-40 {
    padding-top: 10rem;
  }

  .\32xl\:text-left {
    text-align: left;
  }

  .\32xl\:\[align-items\:stretch\] {
    align-items: stretch;
  }

  .\32xl\:\[align-self\:start\] {
    align-self: start;
  }

  .\32xl\:\[border-width\:2px\] {
    border-width: 2px;
  }

  .\32xl\:\[grid-row-gap\:24px\] {
    grid-row-gap: 24px;
  }

  .\32xl\:\[height\:225px\] {
    height: 225px;
  }

  .\32xl\:\[height\:330px\] {
    height: 330px;
  }

  .\32xl\:\[height\:50vh\] {
    height: 50vh;
  }

  .\32xl\:\[left\:1000px\] {
    left: 1000px;
  }

  .\32xl\:\[left\:120px\] {
    left: 120px;
  }

  .\32xl\:\[line-height\:40px\] {
    line-height: 40px;
  }

  .\32xl\:\[margin-left\:auto\] {
    margin-left: auto;
  }

  .\32xl\:\[margin-right\:auto\] {
    margin-right: auto;
  }

  .\32xl\:\[min-height\:150px\] {
    min-height: 150px;
  }

  .\32xl\:\[min-height\:580px\] {
    min-height: 580px;
  }

  .\32xl\:\[padding-left\:120px\] {
    padding-left: 120px;
  }

  .\32xl\:\[padding-left\:48px\] {
    padding-left: 48px;
  }

  .\32xl\:\[padding-left\:60px\] {
    padding-left: 60px;
  }

  .\32xl\:\[padding-right\:120px\] {
    padding-right: 120px;
  }

  .\32xl\:\[padding-right\:18px\] {
    padding-right: 18px;
  }

  .\32xl\:\[padding-right\:60px\] {
    padding-right: 60px;
  }

  .\32xl\:\[right\:120px\] {
    right: 120px;
  }

  .\32xl\:\[top\:367px\] {
    top: 367px;
  }

  .\32xl\:\[width\:64\%\] {
    width: 64%;
  }

  .\32xl\:\[width\:80\%\] {
    width: 80%;
  }

  .\32xl\:\[width\:88\%\] {
    width: 88%;
  }
}

@media (min-width: 480px) {
  .xs\:right-\[-25px\] {
    right: -25px;
  }

  .xs\:mr-6 {
    margin-right: 1.5rem;
  }

  .xs\:flex {
    display: flex;
  }

  .xs\:hidden {
    display: none;
  }

  .xs\:w-\[110px\] {
    width: 110px;
  }

  .xs\:w-\[120px\] {
    width: 120px;
  }

  .xs\:w-auto {
    width: auto;
  }

  .xs\:max-w-\[110px\] {
    max-width: 110px;
  }

  .xs\:max-w-\[120px\] {
    max-width: 120px;
  }

  .xs\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .xs\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .xs\:flex-row {
    flex-direction: row;
  }

  .xs\:flex-col {
    flex-direction: column;
  }

  .xs\:items-center {
    align-items: center;
  }

  .xs\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .xs\:leading-6 {
    line-height: 1.5rem;
  }

  .xs\:\[width\:220px\] {
    width: 220px;
  }

  .last-of-type\:xs\:mr-0:last-of-type {
    margin-right: 0px;
  }
}

@media (min-width: 1920px) {
  .\33xl\:col-span-5 {
    grid-column: span 5 / span 5;
  }

  .\33xl\:col-span-7 {
    grid-column: span 7 / span 7;
  }

  .\33xl\:mx-\[calc\(\(100vw-1500px\)\/-2\)\] {
    margin-left: calc((100vw - 1500px) / -2);
    margin-right: calc((100vw - 1500px) / -2);
  }

  .\33xl\:ml-\[calc\(\(100vw-1500px\)\/-2\)\] {
    margin-left: calc((100vw - 1500px) / -2);
  }

  .\33xl\:mr-\[calc\(\(100vw-1500px\)\/-2\)\] {
    margin-right: calc((100vw - 1500px) / -2);
  }

  .\33xl\:block {
    display: block;
  }

  .\33xl\:w-full {
    width: 100%;
  }

  .\33xl\:max-w-\[1500px\] {
    max-width: 1500px;
  }

  .\33xl\:items-center {
    align-items: center;
  }

  .\33xl\:p-20 {
    padding: 5rem;
  }

  .\33xl\:px-0 {
    padding-left: 0px;
    padding-right: 0px;
  }

  .\33xl\:pl-\[calc\(\(100vw-1500px\)\/2\)\] {
    padding-left: calc((100vw - 1500px) / 2);
  }

  .\33xl\:pr-\[calc\(\(100vw-1500px\)\/2\)\] {
    padding-right: calc((100vw - 1500px) / 2);
  }

  .\33xl\:\[align-items\:start\] {
    align-items: start;
  }

  .\33xl\:\[align-self\:center\] {
    align-self: center;
  }

  .\33xl\:\[align-self\:end\] {
    align-self: end;
  }

  .\33xl\:\[align-self\:stretch\] {
    align-self: stretch;
  }

  .\33xl\:\[grid-template-columns\:4fr_1fr_7fr\] {
    grid-template-columns: 4fr 1fr 7fr;
  }

  .\33xl\:\[grid-template-columns\:5fr_2fr_5fr\] {
    grid-template-columns: 5fr 2fr 5fr;
  }

  .\33xl\:\[grid-template-columns\:5fr_5fr\] {
    grid-template-columns: 5fr 5fr;
  }

  .\33xl\:\[height\:226px\] {
    height: 226px;
  }

  .\33xl\:\[height\:26vh\] {
    height: 26vh;
  }

  .\33xl\:\[height\:390px\] {
    height: 390px;
  }

  .\33xl\:\[height\:517px\] {
    height: 517px;
  }

  .\33xl\:\[left\:1200px\] {
    left: 1200px;
  }

  .\33xl\:\[min-height\:140px\] {
    min-height: 140px;
  }

  .\33xl\:\[min-height\:640px\] {
    min-height: 640px;
  }

  .\33xl\:\[min-height\:auto\] {
    min-height: auto;
  }

  .\33xl\:\[padding-bottom\:120px\] {
    padding-bottom: 120px;
  }

  .\33xl\:\[padding-left\:15vw\] {
    padding-left: 15vw;
  }

  .\33xl\:\[padding-left\:65px\] {
    padding-left: 65px;
  }

  .\33xl\:\[padding-right\:15vw\] {
    padding-right: 15vw;
  }

  .\33xl\:\[padding-right\:65px\] {
    padding-right: 65px;
  }

  .\33xl\:\[padding-top\:120px\] {
    padding-top: 120px;
  }

  .\33xl\:\[top\:318px\] {
    top: 318px;
  }

  .\33xl\:\[width\:48\%\] {
    width: 48%;
  }

  .\33xl\:\[width\:70\%\] {
    width: 70%;
  }

  .\33xl\:\[width\:75\%\] {
    width: 75%;
  }

  .\33xl\:\[width\:auto\] {
    width: auto;
  }
}

@media (max-width: 991px) {
  .-lg\:static {
    position: static;
  }

  .-lg\:absolute {
    position: absolute;
  }

  .-lg\:relative {
    position: relative;
  }

  .-lg\:sticky {
    position: sticky;
  }

  .-lg\:mb-0 {
    margin-bottom: 0px;
  }

  .-lg\:mb-\[16px\] {
    margin-bottom: 16px;
  }

  .-lg\:ml-0 {
    margin-left: 0px;
  }

  .-lg\:mr-0 {
    margin-right: 0px;
  }

  .-lg\:mt-0 {
    margin-top: 0px;
  }

  .-lg\:block {
    display: block;
  }

  .-lg\:inline-block {
    display: inline-block;
  }

  .-lg\:inline {
    display: inline;
  }

  .-lg\:flex {
    display: flex;
  }

  .-lg\:grid {
    display: grid;
  }

  .-lg\:hidden {
    display: none;
  }

  .-lg\:h-\[440px\] {
    height: 440px;
  }

  .-lg\:max-h-\[250px\] {
    max-height: 250px;
  }

  .-lg\:min-h-44 {
    min-height: 11rem;
  }

  .-lg\:w-full {
    width: 100%;
  }

  .-lg\:flex-col {
    flex-direction: column;
  }

  .-lg\:flex-wrap {
    flex-wrap: wrap;
  }

  .-lg\:items-center {
    align-items: center;
  }

  .-lg\:justify-start {
    justify-content: flex-start;
  }

  .-lg\:justify-between {
    justify-content: space-between;
  }

  .-lg\:overflow-auto {
    overflow: auto;
  }

  .-lg\:overflow-hidden {
    overflow: hidden;
  }

  .-lg\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .-lg\:py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .-lg\:pb-0 {
    padding-bottom: 0px;
  }

  .-lg\:pl-0 {
    padding-left: 0px;
  }

  .-lg\:text-left {
    text-align: left;
  }

  .-lg\:text-center {
    text-align: center;
  }

  .-lg\:text-2xl-copy {
    font-size: 2rem;
    line-height: 2.25rem;
  }

  .-lg\:text-3xl-heading {
    font-size: 2.25rem;
    line-height: 2.5rem;
    letter-spacing: -0.04em;
  }

  .-lg\:text-4xl-heading {
    font-size: 2.75rem;
    line-height: 3rem;
    letter-spacing: -0.04em;
  }

  .-lg\:text-lg-copy {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .-lg\:text-md-copy {
    font-size: 1.125rem;
    line-height: 1.625rem;
  }

  .-lg\:text-xl-copy {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .-lg\:text-xs-copy {
    font-size: 0.75rem;
    line-height: 1.125rem;
  }

  .-lg\:\[align-content\:stretch\] {
    align-content: stretch;
  }

  .-lg\:\[align-items\:flex-end\] {
    align-items: flex-end;
  }

  .-lg\:\[align-items\:flex-start\] {
    align-items: flex-start;
  }

  .-lg\:\[align-items\:stretch\] {
    align-items: stretch;
  }

  .-lg\:\[align-self\:auto\] {
    align-self: auto;
  }

  .-lg\:\[align-self\:center\] {
    align-self: center;
  }

  .-lg\:\[align-self\:end\] {
    align-self: end;
  }

  .-lg\:\[align-self\:start\] {
    align-self: start;
  }

  .-lg\:\[align-self\:stretch\] {
    align-self: stretch;
  }

  .-lg\:\[background-color\:\#f8f9fa\] {
    background-color: #f8f9fa;
  }

  .-lg\:\[background-position\:0_4\%\] {
    background-position: 0 4%;
  }

  .-lg\:\[background-position\:0_50\%\] {
    background-position: 0 50%;
  }

  .-lg\:\[background-size\:auto\] {
    background-size: auto;
  }

  .-lg\:\[bottom\:16px\] {
    bottom: 16px;
  }

  .-lg\:\[bottom\:333px\] {
    bottom: 333px;
  }

  .-lg\:\[flex-direction\:row\] {
    flex-direction: row;
  }

  .-lg\:\[font-size\:28px\] {
    font-size: 28px;
  }

  .-lg\:\[font-size\:38px\] {
    font-size: 38px;
  }

  .-lg\:\[grid-column-end\:span_1\] {
    grid-column-end: span 1;
  }

  .-lg\:\[grid-column-end\:span_2\] {
    grid-column-end: span 2;
  }

  .-lg\:\[grid-column-end\:span_6\] {
    grid-column-end: span 6;
  }

  .-lg\:\[grid-column-gap\:0px\] {
    grid-column-gap: 0px;
  }

  .-lg\:\[grid-column-gap\:10px\] {
    grid-column-gap: 10px;
  }

  .-lg\:\[grid-column-gap\:16px\] {
    grid-column-gap: 16px;
  }

  .-lg\:\[grid-column-gap\:24px\] {
    grid-column-gap: 24px;
  }

  .-lg\:\[grid-column-gap\:32px\] {
    grid-column-gap: 32px;
  }

  .-lg\:\[grid-column-gap\:8px\] {
    grid-column-gap: 8px;
  }

  .-lg\:\[grid-column-start\:span_1\] {
    grid-column-start: span 1;
  }

  .-lg\:\[grid-column-start\:span_2\] {
    grid-column-start: span 2;
  }

  .-lg\:\[grid-column-start\:span_6\] {
    grid-column-start: span 6;
  }

  .-lg\:\[grid-row-end\:span_1\] {
    grid-row-end: span 1;
  }

  .-lg\:\[grid-row-gap\:0px\] {
    grid-row-gap: 0px;
  }

  .-lg\:\[grid-row-gap\:10px\] {
    grid-row-gap: 10px;
  }

  .-lg\:\[grid-row-gap\:16px\] {
    grid-row-gap: 16px;
  }

  .-lg\:\[grid-row-gap\:24px\] {
    grid-row-gap: 24px;
  }

  .-lg\:\[grid-row-gap\:8px\] {
    grid-row-gap: 8px;
  }

  .-lg\:\[grid-row-start\:span_1\] {
    grid-row-start: span 1;
  }

  .-lg\:\[grid-template-columns\:1fr\] {
    grid-template-columns: 1fr;
  }

  .-lg\:\[grid-template-columns\:1fr_10fr_1fr\] {
    grid-template-columns: 1fr 10fr 1fr;
  }

  .-lg\:\[grid-template-columns\:1fr_1fr\] {
    grid-template-columns: 1fr 1fr;
  }

  .-lg\:\[grid-template-columns\:1fr_1fr_1fr\] {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .-lg\:\[grid-template-columns\:1fr_1fr_1fr_1fr\] {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .-lg\:\[grid-template-columns\:1fr_1fr_1fr_1fr_1fr_1fr\] {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }

  .-lg\:\[grid-template-columns\:2fr_1fr_3fr\] {
    grid-template-columns: 2fr 1fr 3fr;
  }

  .-lg\:\[grid-template-columns\:2fr_2fr_2fr\] {
    grid-template-columns: 2fr 2fr 2fr;
  }

  .-lg\:\[grid-template-columns\:2fr_2fr_2fr_1fr_1fr_1fr\] {
    grid-template-columns: 2fr 2fr 2fr 1fr 1fr 1fr;
  }

  .-lg\:\[grid-template-columns\:2fr_2fr_2fr_2fr_2fr_2fr\] {
    grid-template-columns: 2fr 2fr 2fr 2fr 2fr 2fr;
  }

  .-lg\:\[grid-template-columns\:2fr_8fr_2fr\] {
    grid-template-columns: 2fr 8fr 2fr;
  }

  .-lg\:\[grid-template-columns\:2fr_minmax\(200px\2c _4fr\)_2fr\] {
    grid-template-columns: 2fr minmax(200px, 4fr) 2fr;
  }

  .-lg\:\[grid-template-columns\:3fr_1fr_8fr\] {
    grid-template-columns: 3fr 1fr 8fr;
  }

  .-lg\:\[grid-template-columns\:3fr_2fr\] {
    grid-template-columns: 3fr 2fr;
  }

  .-lg\:\[grid-template-columns\:3fr_3fr_3fr\] {
    grid-template-columns: 3fr 3fr 3fr;
  }

  .-lg\:\[grid-template-columns\:4fr_1fr_5\.75fr_0\.25fr\] {
    grid-template-columns: 4fr 1fr 5.75fr 0.25fr;
  }

  .-lg\:\[grid-template-columns\:4fr_1fr_5fr\] {
    grid-template-columns: 4fr 1fr 5fr;
  }

  .-lg\:\[grid-template-columns\:4fr_1fr_7fr\] {
    grid-template-columns: 4fr 1fr 7fr;
  }

  .-lg\:\[grid-template-columns\:4fr_2fr_6fr\] {
    grid-template-columns: 4fr 2fr 6fr;
  }

  .-lg\:\[grid-template-columns\:4fr_4fr\] {
    grid-template-columns: 4fr 4fr;
  }

  .-lg\:\[grid-template-columns\:4fr_4fr_4fr\] {
    grid-template-columns: 4fr 4fr 4fr;
  }

  .-lg\:\[grid-template-columns\:5fr_1fr_4fr\] {
    grid-template-columns: 5fr 1fr 4fr;
  }

  .-lg\:\[grid-template-columns\:5fr_1fr_5fr\] {
    grid-template-columns: 5fr 1fr 5fr;
  }

  .-lg\:\[grid-template-columns\:5fr_1fr_6fr\] {
    grid-template-columns: 5fr 1fr 6fr;
  }

  .-lg\:\[grid-template-columns\:5fr_5fr\] {
    grid-template-columns: 5fr 5fr;
  }

  .-lg\:\[grid-template-columns\:5fr_6fr\] {
    grid-template-columns: 5fr 6fr;
  }

  .-lg\:\[grid-template-columns\:6fr\] {
    grid-template-columns: 6fr;
  }

  .-lg\:\[grid-template-columns\:6fr_1fr_5fr\] {
    grid-template-columns: 6fr 1fr 5fr;
  }

  .-lg\:\[grid-template-columns\:6fr_2fr_4fr\] {
    grid-template-columns: 6fr 2fr 4fr;
  }

  .-lg\:\[grid-template-columns\:6fr_4fr\] {
    grid-template-columns: 6fr 4fr;
  }

  .-lg\:\[grid-template-columns\:6fr_6fr\] {
    grid-template-columns: 6fr 6fr;
  }

  .-lg\:\[grid-template-rows\:auto\] {
    grid-template-rows: auto;
  }

  .-lg\:\[height\:100\%\] {
    height: 100%;
  }

  .-lg\:\[height\:100px\] {
    height: 100px;
  }

  .-lg\:\[height\:100vh\] {
    height: 100vh;
  }

  .-lg\:\[height\:104px\] {
    height: 104px;
  }

  .-lg\:\[height\:16px\] {
    height: 16px;
  }

  .-lg\:\[height\:180px\] {
    height: 180px;
  }

  .-lg\:\[height\:193px\] {
    height: 193px;
  }

  .-lg\:\[height\:20px\] {
    height: 20px;
  }

  .-lg\:\[height\:28px\] {
    height: 28px;
  }

  .-lg\:\[height\:300px\] {
    height: 300px;
  }

  .-lg\:\[height\:34vh\] {
    height: 34vh;
  }

  .-lg\:\[height\:36px\] {
    height: 36px;
  }

  .-lg\:\[height\:40px\] {
    height: 40px;
  }

  .-lg\:\[height\:528px\] {
    height: 528px;
  }

  .-lg\:\[height\:580px\] {
    height: 580px;
  }

  .-lg\:\[height\:60px\] {
    height: 60px;
  }

  .-lg\:\[height\:64px\] {
    height: 64px;
  }

  .-lg\:\[height\:70vh\] {
    height: 70vh;
  }

  .-lg\:\[height\:78px\] {
    height: 78px;
  }

  .-lg\:\[height\:80px\] {
    height: 80px;
  }

  .-lg\:\[height\:8vh\] {
    height: 8vh;
  }

  .-lg\:\[height\:auto\] {
    height: auto;
  }

  .-lg\:\[justify-content\:space-around\] {
    justify-content: space-around;
  }

  .-lg\:\[justify-self\:center\] {
    justify-self: center;
  }

  .-lg\:\[justify-self\:end\] {
    justify-self: end;
  }

  .-lg\:\[justify-self\:start\] {
    justify-self: start;
  }

  .-lg\:\[justify-self\:stretch\] {
    justify-self: stretch;
  }

  .-lg\:\[left\:-15px\] {
    left: -15px;
  }

  .-lg\:\[left\:270px\] {
    left: 270px;
  }

  .-lg\:\[left\:469px\] {
    left: 469px;
  }

  .-lg\:\[left\:495px\] {
    left: 495px;
  }

  .-lg\:\[line-height\:18px\] {
    line-height: 18px;
  }

  .-lg\:\[line-height\:28px\] {
    line-height: 28px;
  }

  .-lg\:\[line-height\:32px\] {
    line-height: 32px;
  }

  .-lg\:\[line-height\:36px\] {
    line-height: 36px;
  }

  .-lg\:\[line-height\:40px\] {
    line-height: 40px;
  }

  .-lg\:\[line-height\:48px\] {
    line-height: 48px;
  }

  .-lg\:\[margin-bottom\:16px\] {
    margin-bottom: 16px;
  }

  .-lg\:\[margin-bottom\:20px\] {
    margin-bottom: 20px;
  }

  .-lg\:\[margin-bottom\:24px\] {
    margin-bottom: 24px;
  }

  .-lg\:\[margin-bottom\:35px\] {
    margin-bottom: 35px;
  }

  .-lg\:\[margin-bottom\:40px\] {
    margin-bottom: 40px;
  }

  .-lg\:\[margin-bottom\:50px\] {
    margin-bottom: 50px;
  }

  .-lg\:\[margin-bottom\:8px\] {
    margin-bottom: 8px;
  }

  .-lg\:\[margin-left\:-61px\] {
    margin-left: -61px;
  }

  .-lg\:\[margin-left\:10px\] {
    margin-left: 10px;
  }

  .-lg\:\[margin-left\:17px\] {
    margin-left: 17px;
  }

  .-lg\:\[margin-left\:20px\] {
    margin-left: 20px;
  }

  .-lg\:\[margin-left\:2em\] {
    margin-left: 2em;
  }

  .-lg\:\[margin-left\:5px\] {
    margin-left: 5px;
  }

  .-lg\:\[margin-left\:77px\] {
    margin-left: 77px;
  }

  .-lg\:\[margin-left\:8px\] {
    margin-left: 8px;
  }

  .-lg\:\[margin-left\:9px\] {
    margin-left: 9px;
  }

  .-lg\:\[margin-left\:auto\] {
    margin-left: auto;
  }

  .-lg\:\[margin-right\:-20px\] {
    margin-right: -20px;
  }

  .-lg\:\[margin-right\:-61px\] {
    margin-right: -61px;
  }

  .-lg\:\[margin-right\:10px\] {
    margin-right: 10px;
  }

  .-lg\:\[margin-right\:15px\] {
    margin-right: 15px;
  }

  .-lg\:\[margin-right\:16px\] {
    margin-right: 16px;
  }

  .-lg\:\[margin-right\:17px\] {
    margin-right: 17px;
  }

  .-lg\:\[margin-right\:20px\] {
    margin-right: 20px;
  }

  .-lg\:\[margin-right\:24px\] {
    margin-right: 24px;
  }

  .-lg\:\[margin-right\:2em\] {
    margin-right: 2em;
  }

  .-lg\:\[margin-right\:auto\] {
    margin-right: auto;
  }

  .-lg\:\[margin-top\:-300px\] {
    margin-top: -300px;
  }

  .-lg\:\[margin-top\:16px\] {
    margin-top: 16px;
  }

  .-lg\:\[margin-top\:24px\] {
    margin-top: 24px;
  }

  .-lg\:\[margin-top\:32px\] {
    margin-top: 32px;
  }

  .-lg\:\[margin-top\:35px\] {
    margin-top: 35px;
  }

  .-lg\:\[margin-top\:40px\] {
    margin-top: 40px;
  }

  .-lg\:\[margin-top\:43px\] {
    margin-top: 43px;
  }

  .-lg\:\[margin-top\:46px\] {
    margin-top: 46px;
  }

  .-lg\:\[margin-top\:48px\] {
    margin-top: 48px;
  }

  .-lg\:\[margin-top\:8px\] {
    margin-top: 8px;
  }

  .-lg\:\[max-width\:140px\] {
    max-width: 140px;
  }

  .-lg\:\[max-width\:48\%\] {
    max-width: 48%;
  }

  .-lg\:\[max-width\:50ch\] {
    max-width: 50ch;
  }

  .-lg\:\[max-width\:640px\] {
    max-width: 640px;
  }

  .-lg\:\[max-width\:680px\] {
    max-width: 680px;
  }

  .-lg\:\[max-width\:728px\] {
    max-width: 728px;
  }

  .-lg\:\[max-width\:none\] {
    max-width: none;
  }

  .-lg\:\[min-height\:300px\] {
    min-height: 300px;
  }

  .-lg\:\[min-height\:390px\] {
    min-height: 390px;
  }

  .-lg\:\[min-height\:500px\] {
    min-height: 500px;
  }

  .-lg\:\[min-height\:800px\] {
    min-height: 800px;
  }

  .-lg\:\[min-height\:auto\] {
    min-height: auto;
  }

  .-lg\:\[min-width\:240px\] {
    min-width: 240px;
  }

  .-lg\:\[order\:-9999\] {
    order: -9999;
  }

  .-lg\:\[padding-bottom\:16px\] {
    padding-bottom: 16px;
  }

  .-lg\:\[padding-bottom\:24px\] {
    padding-bottom: 24px;
  }

  .-lg\:\[padding-bottom\:40px\] {
    padding-bottom: 40px;
  }

  .-lg\:\[padding-left\:10px\] {
    padding-left: 10px;
  }

  .-lg\:\[padding-left\:16px\] {
    padding-left: 16px;
  }

  .-lg\:\[padding-left\:20px\] {
    padding-left: 20px;
  }

  .-lg\:\[padding-left\:24px\] {
    padding-left: 24px;
  }

  .-lg\:\[padding-left\:25px\] {
    padding-left: 25px;
  }

  .-lg\:\[padding-left\:32px\] {
    padding-left: 32px;
  }

  .-lg\:\[padding-left\:40px\] {
    padding-left: 40px;
  }

  .-lg\:\[padding-left\:60px\] {
    padding-left: 60px;
  }

  .-lg\:\[padding-right\:10px\] {
    padding-right: 10px;
  }

  .-lg\:\[padding-right\:16px\] {
    padding-right: 16px;
  }

  .-lg\:\[padding-right\:22px\] {
    padding-right: 22px;
  }

  .-lg\:\[padding-right\:24px\] {
    padding-right: 24px;
  }

  .-lg\:\[padding-right\:40px\] {
    padding-right: 40px;
  }

  .-lg\:\[padding-top\:12px\] {
    padding-top: 12px;
  }

  .-lg\:\[padding-top\:15px\] {
    padding-top: 15px;
  }

  .-lg\:\[padding-top\:220px\] {
    padding-top: 220px;
  }

  .-lg\:\[padding-top\:24px\] {
    padding-top: 24px;
  }

  .-lg\:\[padding-top\:40px\] {
    padding-top: 40px;
  }

  .-lg\:\[padding-top\:48px\] {
    padding-top: 48px;
  }

  .-lg\:\[padding-top\:60px\] {
    padding-top: 60px;
  }

  .-lg\:\[padding-top\:80px\] {
    padding-top: 80px;
  }

  .-lg\:\[padding-top\:84px\] {
    padding-top: 84px;
  }

  .-lg\:\[padding\:10px_24px\] {
    padding: 10px 24px;
  }

  .-lg\:\[padding\:12px_40px\] {
    padding: 12px 40px;
  }

  .-lg\:\[padding\:15px\] {
    padding: 15px;
  }

  .-lg\:\[padding\:15px_10px\] {
    padding: 15px 10px;
  }

  .-lg\:\[padding\:16px\] {
    padding: 16px;
  }

  .-lg\:\[padding\:20px\] {
    padding: 20px;
  }

  .-lg\:\[padding\:20px_20px_30px\] {
    padding: 20px 20px 30px;
  }

  .-lg\:\[padding\:24px\] {
    padding: 24px;
  }

  .-lg\:\[padding\:30px\] {
    padding: 30px;
  }

  .-lg\:\[padding\:40px_50px\] {
    padding: 40px 50px;
  }

  .-lg\:\[scroll-padding-top\:80px\] {
    scroll-padding-top: 80px;
  }

  .-lg\:\[top\:173px\] {
    top: 173px;
  }

  .-lg\:\[top\:217px\] {
    top: 217px;
  }

  .-lg\:\[top\:60px\] {
    top: 60px;
  }

  .-lg\:\[top\:auto\] {
    top: auto;
  }

  .-lg\:\[width\:100px\] {
    width: 100px;
  }

  .-lg\:\[width\:12\.5\%\] {
    width: 12.5%;
  }

  .-lg\:\[width\:170px\] {
    width: 170px;
  }

  .-lg\:\[width\:1px\] {
    width: 1px;
  }

  .-lg\:\[width\:40\%\] {
    width: 40%;
  }

  .-lg\:\[width\:400px\] {
    width: 400px;
  }

  .-lg\:\[width\:48\%\] {
    width: 48%;
  }

  .-lg\:\[width\:50\%\] {
    width: 50%;
  }

  .-lg\:\[width\:50vw\] {
    width: 50vw;
  }

  .-lg\:\[width\:60\%\] {
    width: 60%;
  }

  .-lg\:\[width\:78px\] {
    width: 78px;
  }

  .-lg\:\[width\:80\%\] {
    width: 80%;
  }

  .-lg\:\[width\:88\%\] {
    width: 88%;
  }

  .-lg\:\[width\:90\%\] {
    width: 90%;
  }

  .-lg\:\[width\:97\%\] {
    width: 97%;
  }

  .-lg\:\[width\:auto\] {
    width: auto;
  }
}

@media (max-width: 767px) {
  .-md\:static {
    position: static;
  }

  .-md\:fixed {
    position: fixed;
  }

  .-md\:absolute {
    position: absolute;
  }

  .-md\:relative {
    position: relative;
  }

  .-md\:bottom-0 {
    bottom: 0px;
  }

  .-md\:bottom-\[-125px\] {
    bottom: -125px;
  }

  .-md\:left-0 {
    left: 0px;
  }

  .-md\:left-1\/2 {
    left: 50%;
  }

  .-md\:left-\[-110px\] {
    left: -110px;
  }

  .-md\:left-\[30px\] {
    left: 30px;
  }

  .-md\:right-0 {
    right: 0px;
  }

  .-md\:top-\[-50px\] {
    top: -50px;
  }

  .-md\:order-1 {
    order: 1;
  }

  .-md\:order-2 {
    order: 2;
  }

  .-md\:order-3 {
    order: 3;
  }

  .-md\:order-4 {
    order: 4;
  }

  .-md\:order-5 {
    order: 5;
  }

  .-md\:order-6 {
    order: 6;
  }

  .-md\:order-7 {
    order: 7;
  }

  .-md\:order-8 {
    order: 8;
  }

  .-md\:col-span-3 {
    grid-column: span 3 / span 3;
  }

  .-md\:row-start-1 {
    grid-row-start: 1;
  }

  .-md\:\!m-0 {
    margin: 0px !important;
  }

  .-md\:m-4 {
    margin: 1rem;
  }

  .-md\:mx-4 {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .-md\:mx-\[20px\] {
    margin-left: 20px;
    margin-right: 20px;
  }

  .-md\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .-md\:my-4 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .-md\:my-6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .-md\:my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .-md\:-ml-6 {
    margin-left: -1.5rem;
  }

  .-md\:-mr-6 {
    margin-right: -1.5rem;
  }

  .-md\:-mt-8 {
    margin-top: -2rem;
  }

  .-md\:mb-0 {
    margin-bottom: 0px;
  }

  .-md\:mb-2 {
    margin-bottom: 0.5rem;
  }

  .-md\:mb-4 {
    margin-bottom: 1rem;
  }

  .-md\:mb-5 {
    margin-bottom: 1.25rem;
  }

  .-md\:ml-0 {
    margin-left: 0px;
  }

  .-md\:mr-0 {
    margin-right: 0px;
  }

  .-md\:mt-0 {
    margin-top: 0px;
  }

  .-md\:block {
    display: block;
  }

  .-md\:inline-block {
    display: inline-block;
  }

  .-md\:flex {
    display: flex;
  }

  .-md\:grid {
    display: grid;
  }

  .-md\:hidden {
    display: none;
  }

  .-md\:h-\[200\%\] {
    height: 200%;
  }

  .-md\:h-\[800px\] {
    height: 800px;
  }

  .-md\:h-full {
    height: 100%;
  }

  .-md\:min-h-\[100vh\] {
    min-height: 100vh;
  }

  .-md\:min-h-\[120px\] {
    min-height: 120px;
  }

  .-md\:min-h-\[160vh\] {
    min-height: 160vh;
  }

  .-md\:min-h-\[172px\] {
    min-height: 172px;
  }

  .-md\:min-h-\[2000px\] {
    min-height: 2000px;
  }

  .-md\:min-h-\[64px\] {
    min-height: 64px;
  }

  .-md\:min-h-\[80px\] {
    min-height: 80px;
  }

  .-md\:min-h-screen {
    min-height: 100vh;
  }

  .-md\:w-2\/6 {
    width: 33.333333%;
  }

  .-md\:w-4\/6 {
    width: 66.666667%;
  }

  .-md\:w-\[90\%\] {
    width: 90%;
  }

  .-md\:w-full {
    width: 100%;
  }

  .-md\:max-w-full {
    max-width: 100%;
  }

  .-md\:max-w-sm {
    max-width: 24rem;
  }

  .-md\:-translate-x-1\/2 {
    --tw-translate-x: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .-md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .-md\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .-md\:flex-col {
    flex-direction: column;
  }

  .-md\:items-center {
    align-items: center;
  }

  .-md\:justify-start {
    justify-content: flex-start;
  }

  .-md\:justify-center {
    justify-content: center;
  }

  .-md\:gap-4 {
    gap: 1rem;
  }

  .-md\:gap-6 {
    gap: 1.5rem;
  }

  .-md\:gap-8 {
    gap: 2rem;
  }

  .-md\:gap-y-4 {
    row-gap: 1rem;
  }

  .-md\:overflow-auto {
    overflow: auto;
  }

  .-md\:overflow-hidden {
    overflow: hidden;
  }

  .-md\:overflow-visible {
    overflow: visible;
  }

  .-md\:overflow-scroll {
    overflow: scroll;
  }

  .-md\:overflow-y-scroll {
    overflow-y: scroll;
  }

  .-md\:overscroll-y-contain {
    overscroll-behavior-y: contain;
  }

  .-md\:rounded-2xl {
    border-radius: 1rem;
  }

  .-md\:rounded-none {
    border-radius: 0px;
  }

  .-md\:bg-purple-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(243 235 255 / var(--tw-bg-opacity, 1));
  }

  .-md\:bg-purple-400 {
    --tw-bg-opacity: 1;
    background-color: rgb(101 31 255 / var(--tw-bg-opacity, 1));
  }

  .-md\:bg-white {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
  }

  .-md\:object-none {
    object-fit: none;
  }

  .-md\:object-left {
    object-position: left;
  }

  .-md\:\!p-0 {
    padding: 0px !important;
  }

  .-md\:p-0 {
    padding: 0px;
  }

  .-md\:p-12 {
    padding: 3rem;
  }

  .-md\:p-16 {
    padding: 4rem;
  }

  .-md\:p-6 {
    padding: 1.5rem;
  }

  .-md\:\!px-8 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .-md\:\!py-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .-md\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .-md\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .-md\:py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .-md\:py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .-md\:py-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }

  .-md\:py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .-md\:py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .-md\:pb-0 {
    padding-bottom: 0px;
  }

  .-md\:pb-4 {
    padding-bottom: 1rem;
  }

  .-md\:pl-0 {
    padding-left: 0px;
  }

  .-md\:pl-2 {
    padding-left: 0.5rem;
  }

  .-md\:pr-0 {
    padding-right: 0px;
  }

  .-md\:pt-0 {
    padding-top: 0px;
  }

  .-md\:pt-16 {
    padding-top: 4rem;
  }

  .-md\:text-left {
    text-align: left;
  }

  .-md\:text-center {
    text-align: center;
  }

  .-md\:text-right {
    text-align: right;
  }

  .-md\:text-3xl-heading {
    font-size: 2.25rem;
    line-height: 2.5rem;
    letter-spacing: -0.04em;
  }

  .-md\:text-5xl-copy {
    font-size: 3rem;
    line-height: 1;
  }

  .-md\:text-6xl-copy {
    font-size: 3.75rem;
    line-height: 1;
  }

  .-md\:text-\[17px\] {
    font-size: 17px;
  }

  .-md\:text-md-copy {
    font-size: 1.125rem;
    line-height: 1.625rem;
  }

  .-md\:text-sm-copy {
    font-size: 0.875rem;
    line-height: 1.375rem;
  }

  .-md\:text-xs-copy {
    font-size: 0.75rem;
    line-height: 1.125rem;
  }

  .-md\:leading-6 {
    line-height: 1.5rem;
  }

  .-md\:tracking-mobileNeg {
    letter-spacing: -0.25rem;
  }

  .-md\:text-white {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity, 1));
  }

  .-md\:shadow-\[0_4px_10px_2px_rgba\(0\2c 0\2c 0\2c 0\.1\)\] {
    --tw-shadow: 0 4px 10px 2px rgba(0,0,0,0.1);
    --tw-shadow-colored: 0 4px 10px 2px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .-md\:\[align-self\:center\] {
    align-self: center;
  }

  .-md\:\[align-self\:start\] {
    align-self: start;
  }

  .-md\:\[align-self\:stretch\] {
    align-self: stretch;
  }

  .-md\:\[background-position\:100\%_50\%\] {
    background-position: 100% 50%;
  }

  .-md\:\[background-size\:20px\] {
    background-size: 20px;
  }

  .-md\:\[border-radius\:3px\] {
    border-radius: 3px;
  }

  .-md\:\[border-radius\:5px\] {
    border-radius: 5px;
  }

  .-md\:\[bottom\:298px\] {
    bottom: 298px;
  }

  .-md\:\[bottom\:333px\] {
    bottom: 333px;
  }

  .-md\:\[box-shadow\:0_0_8px_4px_\#ecedf0\] {
    box-shadow: 0 0 8px 4px #ecedf0;
  }

  .-md\:\[flex-direction\:row\] {
    flex-direction: row;
  }

  .-md\:\[font-size\:8px\] {
    font-size: 8px;
  }

  .-md\:\[grid-auto-columns\:1fr\] {
    grid-auto-columns: 1fr;
  }

  .-md\:\[grid-column-end\:span_1\] {
    grid-column-end: span 1;
  }

  .-md\:\[grid-column-end\:span_2\] {
    grid-column-end: span 2;
  }

  .-md\:\[grid-column-gap\:0px\] {
    grid-column-gap: 0px;
  }

  .-md\:\[grid-column-gap\:16px\] {
    grid-column-gap: 16px;
  }

  .-md\:\[grid-column-gap\:20px\] {
    grid-column-gap: 20px;
  }

  .-md\:\[grid-column-gap\:24px\] {
    grid-column-gap: 24px;
  }

  .-md\:\[grid-column-start\:span_1\] {
    grid-column-start: span 1;
  }

  .-md\:\[grid-column-start\:span_2\] {
    grid-column-start: span 2;
  }

  .-md\:\[grid-row-end\:span_1\] {
    grid-row-end: span 1;
  }

  .-md\:\[grid-row-gap\:0px\] {
    grid-row-gap: 0px;
  }

  .-md\:\[grid-row-gap\:16px\] {
    grid-row-gap: 16px;
  }

  .-md\:\[grid-row-gap\:20px\] {
    grid-row-gap: 20px;
  }

  .-md\:\[grid-row-gap\:24px\] {
    grid-row-gap: 24px;
  }

  .-md\:\[grid-row-gap\:36px\] {
    grid-row-gap: 36px;
  }

  .-md\:\[grid-row-gap\:48px\] {
    grid-row-gap: 48px;
  }

  .-md\:\[grid-row-start\:span_1\] {
    grid-row-start: span 1;
  }

  .-md\:\[grid-template-columns\:1fr\] {
    grid-template-columns: 1fr;
  }

  .-md\:\[grid-template-columns\:1fr_10fr_1fr\] {
    grid-template-columns: 1fr 10fr 1fr;
  }

  .-md\:\[grid-template-columns\:1fr_1fr\] {
    grid-template-columns: 1fr 1fr;
  }

  .-md\:\[grid-template-columns\:1fr_1fr_1fr\] {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .-md\:\[grid-template-columns\:1fr_1fr_1fr_1fr_1fr_1fr\] {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }

  .-md\:\[grid-template-columns\:2fr\] {
    grid-template-columns: 2fr;
  }

  .-md\:\[grid-template-columns\:2fr_2fr_2fr_2fr_2fr_2fr\] {
    grid-template-columns: 2fr 2fr 2fr 2fr 2fr 2fr;
  }

  .-md\:\[grid-template-columns\:4fr\] {
    grid-template-columns: 4fr;
  }

  .-md\:\[grid-template-columns\:5fr\] {
    grid-template-columns: 5fr;
  }

  .-md\:\[grid-template-columns\:6fr\] {
    grid-template-columns: 6fr;
  }

  .-md\:\[grid-template-columns\:7fr\] {
    grid-template-columns: 7fr;
  }

  .-md\:\[grid-template-columns\:8fr\] {
    grid-template-columns: 8fr;
  }

  .-md\:\[grid-template-rows\:auto_auto\] {
    grid-template-rows: auto auto;
  }

  .-md\:\[height\:100\%\] {
    height: 100%;
  }

  .-md\:\[height\:12vh\] {
    height: 12vh;
  }

  .-md\:\[height\:224px\] {
    height: 224px;
  }

  .-md\:\[height\:22px\] {
    height: 22px;
  }

  .-md\:\[height\:24px\] {
    height: 24px;
  }

  .-md\:\[height\:26px\] {
    height: 26px;
  }

  .-md\:\[height\:390px\] {
    height: 390px;
  }

  .-md\:\[height\:50px\] {
    height: 50px;
  }

  .-md\:\[height\:60px\] {
    height: 60px;
  }

  .-md\:\[height\:64px\] {
    height: 64px;
  }

  .-md\:\[height\:80px\] {
    height: 80px;
  }

  .-md\:\[height\:auto\] {
    height: auto;
  }

  .-md\:\[justify-content\:stretch\] {
    justify-content: stretch;
  }

  .-md\:\[justify-self\:auto\] {
    justify-self: auto;
  }

  .-md\:\[justify-self\:end\] {
    justify-self: end;
  }

  .-md\:\[justify-self\:start\] {
    justify-self: start;
  }

  .-md\:\[justify-self\:stretch\] {
    justify-self: stretch;
  }

  .-md\:\[left\:230px\] {
    left: 230px;
  }

  .-md\:\[left\:270px\] {
    left: 270px;
  }

  .-md\:\[line-height\:14px\] {
    line-height: 14px;
  }

  .-md\:\[line-height\:26px\] {
    line-height: 26px;
  }

  .-md\:\[margin-bottom\:10px\] {
    margin-bottom: 10px;
  }

  .-md\:\[margin-bottom\:15px\] {
    margin-bottom: 15px;
  }

  .-md\:\[margin-bottom\:20px\] {
    margin-bottom: 20px;
  }

  .-md\:\[margin-bottom\:24px\] {
    margin-bottom: 24px;
  }

  .-md\:\[margin-bottom\:30px\] {
    margin-bottom: 30px;
  }

  .-md\:\[margin-bottom\:40px\] {
    margin-bottom: 40px;
  }

  .-md\:\[margin-bottom\:48px\] {
    margin-bottom: 48px;
  }

  .-md\:\[margin-left\:16px\] {
    margin-left: 16px;
  }

  .-md\:\[margin-left\:5px\] {
    margin-left: 5px;
  }

  .-md\:\[margin-left\:86px\] {
    margin-left: 86px;
  }

  .-md\:\[margin-left\:auto\] {
    margin-left: auto;
  }

  .-md\:\[margin-right\:25px\] {
    margin-right: 25px;
  }

  .-md\:\[margin-right\:5px\] {
    margin-right: 5px;
  }

  .-md\:\[margin-right\:auto\] {
    margin-right: auto;
  }

  .-md\:\[margin-top\:10px\] {
    margin-top: 10px;
  }

  .-md\:\[margin-top\:16px\] {
    margin-top: 16px;
  }

  .-md\:\[margin-top\:20px\] {
    margin-top: 20px;
  }

  .-md\:\[margin-top\:24px\] {
    margin-top: 24px;
  }

  .-md\:\[margin-top\:30px\] {
    margin-top: 30px;
  }

  .-md\:\[margin-top\:40px\] {
    margin-top: 40px;
  }

  .-md\:\[max-width\:100ch\] {
    max-width: 100ch;
  }

  .-md\:\[max-width\:none\] {
    max-width: none;
  }

  .-md\:\[min-height\:650px\] {
    min-height: 650px;
  }

  .-md\:\[min-height\:auto\] {
    min-height: auto;
  }

  .-md\:\[order\:-9999\] {
    order: -9999;
  }

  .-md\:\[order\:9999\] {
    order: 9999;
  }

  .-md\:\[padding-bottom\:12px\] {
    padding-bottom: 12px;
  }

  .-md\:\[padding-bottom\:16px\] {
    padding-bottom: 16px;
  }

  .-md\:\[padding-left\:20px\] {
    padding-left: 20px;
  }

  .-md\:\[padding-left\:24px\] {
    padding-left: 24px;
  }

  .-md\:\[padding-left\:25px\] {
    padding-left: 25px;
  }

  .-md\:\[padding-left\:40px\] {
    padding-left: 40px;
  }

  .-md\:\[padding-right\:20px\] {
    padding-right: 20px;
  }

  .-md\:\[padding-right\:24px\] {
    padding-right: 24px;
  }

  .-md\:\[padding-right\:25px\] {
    padding-right: 25px;
  }

  .-md\:\[padding-right\:40px\] {
    padding-right: 40px;
  }

  .-md\:\[padding-top\:160px\] {
    padding-top: 160px;
  }

  .-md\:\[padding-top\:19px\] {
    padding-top: 19px;
  }

  .-md\:\[padding-top\:20px\] {
    padding-top: 20px;
  }

  .-md\:\[padding-top\:24px\] {
    padding-top: 24px;
  }

  .-md\:\[padding-top\:76px\] {
    padding-top: 76px;
  }

  .-md\:\[padding\:10px_32px\] {
    padding: 10px 32px;
  }

  .-md\:\[padding\:20px\] {
    padding: 20px;
  }

  .-md\:\[padding\:24px\] {
    padding: 24px;
  }

  .-md\:\[padding\:24px_32px\] {
    padding: 24px 32px;
  }

  .-md\:\[padding\:30px\] {
    padding: 30px;
  }

  .-md\:\[padding\:35px_20px\] {
    padding: 35px 20px;
  }

  .-md\:\[padding\:8px_30px\] {
    padding: 8px 30px;
  }

  .-md\:\[scroll-padding-top\:60px\] {
    scroll-padding-top: 60px;
  }

  .-md\:\[top\:0\] {
    top: 0;
  }

  .-md\:\[top\:287px\] {
    top: 287px;
  }

  .-md\:\[top\:auto\] {
    top: auto;
  }

  .-md\:\[transform\:scale\(1\.5\)translate\(5px\2c 6px\)\] {
    transform: scale(1.5)translate(5px,6px);
  }

  .-md\:\[width\:100vw\] {
    width: 100vw;
  }

  .-md\:\[width\:30\%\] {
    width: 30%;
  }

  .-md\:\[width\:50px\] {
    width: 50px;
  }

  .-md\:\[width\:64px\] {
    width: 64px;
  }

  .-md\:\[width\:70\%\] {
    width: 70%;
  }

  .-md\:\[width\:85\%\] {
    width: 85%;
  }

  .-md\:\[width\:95\%\] {
    width: 95%;
  }

  .-md\:\[width\:auto\] {
    width: auto;
  }

  .prose-headings\:-md\:text-left :is(:where(h1, h2, h3, h4, h5, h6, th):not(:where([class~="not-prose"],[class~="not-prose"] *))) {
    text-align: left;
  }

  .prose-p\:-md\:text-left :is(:where(p):not(:where([class~="not-prose"],[class~="not-prose"] *))) {
    text-align: left;
  }

  @media (min-width: 1920px) {
    .-md\:3xl\:max-w-\[1500px\] {
      max-width: 1500px;
    }
  }
}

@media (max-width: 639px) {
  .-sm\:static {
    position: static;
  }

  .-sm\:top-64 {
    top: 16rem;
  }

  .-sm\:top-\[33\%\] {
    top: 33%;
  }

  .-sm\:order-1 {
    order: 1;
  }

  .-sm\:order-2 {
    order: 2;
  }

  .-sm\:m-auto {
    margin: auto;
  }

  .-sm\:mx-0 {
    margin-left: 0px;
    margin-right: 0px;
  }

  .-sm\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .-sm\:my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .-sm\:-mt-24 {
    margin-top: -6rem;
  }

  .-sm\:mb-12 {
    margin-bottom: 3rem;
  }

  .-sm\:mb-\[40rem\] {
    margin-bottom: 40rem;
  }

  .-sm\:mt-12 {
    margin-top: 3rem;
  }

  .-sm\:mt-16 {
    margin-top: 4rem;
  }

  .-sm\:mt-4 {
    margin-top: 1rem;
  }

  .-sm\:mt-6 {
    margin-top: 1.5rem;
  }

  .-sm\:block {
    display: block;
  }

  .-sm\:flex {
    display: flex;
  }

  .-sm\:hidden {
    display: none;
  }

  .-sm\:h-7 {
    height: 1.75rem;
  }

  .-sm\:h-auto {
    height: auto;
  }

  .-sm\:min-h-24 {
    min-height: 6rem;
  }

  .-sm\:min-h-32 {
    min-height: 8rem;
  }

  .-sm\:min-h-\[160vh\] {
    min-height: 160vh;
  }

  .-sm\:min-h-\[2300px\] {
    min-height: 2300px;
  }

  .-sm\:w-36 {
    width: 9rem;
  }

  .-sm\:w-\[30\%\] {
    width: 30%;
  }

  .-sm\:w-\[32vw\] {
    width: 32vw;
  }

  .-sm\:w-full {
    width: 100%;
  }

  .-sm\:\!min-w-\[150px\] {
    min-width: 150px !important;
  }

  .-sm\:scale-125 {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .-sm\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .-sm\:grid-rows-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr));
  }

  .-sm\:flex-col {
    flex-direction: column;
  }

  .-sm\:justify-center {
    justify-content: center;
  }

  .-sm\:overflow-scroll {
    overflow: scroll;
  }

  .-sm\:bg-bottom {
    background-position: bottom;
  }

  .-sm\:p-0 {
    padding: 0px;
  }

  .-sm\:p-12 {
    padding: 3rem;
  }

  .-sm\:p-2 {
    padding: 0.5rem;
  }

  .-sm\:p-4 {
    padding: 1rem;
  }

  .-sm\:px-0 {
    padding-left: 0px;
    padding-right: 0px;
  }

  .-sm\:px-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .-sm\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .-sm\:py-0 {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .-sm\:py-24 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .-sm\:py-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }

  .-sm\:py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .-sm\:py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .-sm\:pl-4 {
    padding-left: 1rem;
  }

  .-sm\:pt-0 {
    padding-top: 0px;
  }

  .-sm\:pt-8 {
    padding-top: 2rem;
  }

  .-sm\:pt-84 {
    padding-top: 21rem;
  }

  .-sm\:text-center {
    text-align: center;
  }

  .-sm\:\!text-2xl-copy {
    font-size: 2rem !important;
    line-height: 2.25rem !important;
  }

  .-sm\:\!text-5xl-copy {
    font-size: 3rem !important;
    line-height: 1 !important;
  }

  .-sm\:\!text-\[28px\] {
    font-size: 28px !important;
  }

  .-sm\:\!text-\[48px\] {
    font-size: 48px !important;
  }

  .-sm\:\!text-xl-copy {
    font-size: 1.5rem !important;
    line-height: 2rem !important;
  }

  .-sm\:text-\[20px\] {
    font-size: 20px;
  }

  .-sm\:text-\[22px\] {
    font-size: 22px;
  }

  .-sm\:text-sm-copy {
    font-size: 0.875rem;
    line-height: 1.375rem;
  }

  .-sm\:tracking-tight {
    letter-spacing: -.025em;
  }

  .-sm\:\[line-height\:1\.2\] {
    line-height: 1.2;
  }
}

@media (max-width: 479px) {
  .-xs\:top-88 {
    top: 22rem;
  }

  .-xs\:-mt-\[16rem\] {
    margin-top: -16rem;
  }

  .-xs\:ml-0 {
    margin-left: 0px;
  }

  .-xs\:mr-0 {
    margin-right: 0px;
  }

  .-xs\:mt-0 {
    margin-top: 0px;
  }

  .-xs\:block {
    display: block;
  }

  .-xs\:inline-block {
    display: inline-block;
  }

  .-xs\:flex {
    display: flex;
  }

  .-xs\:hidden {
    display: none;
  }

  .-xs\:h-\[700px\] {
    height: 700px;
  }

  .-xs\:\!w-full {
    width: 100% !important;
  }

  .-xs\:w-full {
    width: 100%;
  }

  .-xs\:max-w-\[90\%\] {
    max-width: 90%;
  }

  .-xs\:flex-col {
    flex-direction: column;
  }

  .-xs\:justify-between {
    justify-content: space-between;
  }

  .-xs\:justify-items-stretch {
    justify-items: stretch;
  }

  .-xs\:overflow-auto {
    overflow: auto;
  }

  .-xs\:overflow-hidden {
    overflow: hidden;
  }

  .-xs\:overflow-visible {
    overflow: visible;
  }

  .-xs\:overflow-scroll {
    overflow: scroll;
  }

  .-xs\:object-fill {
    object-fit: fill;
  }

  .-xs\:object-center {
    object-position: center;
  }

  .-xs\:pb-0 {
    padding-bottom: 0px;
  }

  .-xs\:pb-15 {
    padding-bottom: 3.75rem;
  }

  .-xs\:pl-0 {
    padding-left: 0px;
  }

  .-xs\:pr-0 {
    padding-right: 0px;
  }

  .-xs\:pt-0 {
    padding-top: 0px;
  }

  .-xs\:text-left {
    text-align: left;
  }

  .-xs\:text-center {
    text-align: center;
  }

  .-xs\:\!text-\[20px\] {
    font-size: 20px !important;
  }

  .-xs\:text-3xl-heading {
    font-size: 2.25rem;
    line-height: 2.5rem;
    letter-spacing: -0.04em;
  }

  .-xs\:text-lg-copy {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .-xs\:text-md-copy {
    font-size: 1.125rem;
    line-height: 1.625rem;
  }

  .-xs\:text-sm-copy {
    font-size: 0.875rem;
    line-height: 1.375rem;
  }

  .-xs\:text-sm-heading {
    font-size: 1.125rem;
    line-height: 1.5rem;
  }

  .-xs\:text-xl-copy {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .-xs\:leading-6 {
    line-height: 1.5rem;
  }

  .-xs\:no-underline {
    text-decoration-line: none;
  }

  .-xs\:opacity-0 {
    opacity: 0;
  }

  .-xs\:\[-webkit-transform\:translate3d\(0\%\2c _0\2c _0\)_scale3d\(1\2c _1\2c _1\)________________________rotateX\(0\)_rotateY\(0\)_rotateZ\(0\)_skew\(0\2c _0\)\] {
    -webkit-transform: translate3d(0%, 0, 0) scale3d(1, 1, 1)                        rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
  }

  .-xs\:\[-webkit-transform\:translate3d\(0px\2c _0\2c _0\)_scale3d\(1\2c _1\2c _1\)________________________rotateX\(0\)_rotateY\(0\)_rotateZ\(0\)_skew\(0\2c _0\)\] {
    -webkit-transform: translate3d(0px, 0, 0) scale3d(1, 1, 1)                        rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
  }

  .-xs\:\[align-items\:flex-start\] {
    align-items: flex-start;
  }

  .-xs\:\[align-items\:stretch\] {
    align-items: stretch;
  }

  .-xs\:\[align-self\:auto\] {
    align-self: auto;
  }

  .-xs\:\[align-self\:end\] {
    align-self: end;
  }

  .-xs\:\[background-position\:0_100\%\] {
    background-position: 0 100%;
  }

  .-xs\:\[background-position\:0_5\%\] {
    background-position: 0 5%;
  }

  .-xs\:\[border-bottom\:2px_solid_\#000\] {
    border-bottom: 2px solid #000;
  }

  .-xs\:\[border-top-style\:none\] {
    border-top-style: none;
  }

  .-xs\:\[border\:0_none_none_\#000\] {
    border: 0 none none #000;
  }

  .-xs\:\[border\:1px_none_none_\#000\] {
    border: 1px none none #000;
  }

  .-xs\:\[bottom\:343px\] {
    bottom: 343px;
  }

  .-xs\:\[font-size\:28px\] {
    font-size: 28px;
  }

  .-xs\:\[font-size\:30px\] {
    font-size: 30px;
  }

  .-xs\:\[grid-column-end\:span_3\] {
    grid-column-end: span 3;
  }

  .-xs\:\[grid-column-gap\:0px\] {
    grid-column-gap: 0px;
  }

  .-xs\:\[grid-column-gap\:16px\] {
    grid-column-gap: 16px;
  }

  .-xs\:\[grid-column-gap\:24px\] {
    grid-column-gap: 24px;
  }

  .-xs\:\[grid-column-gap\:8px\] {
    grid-column-gap: 8px;
  }

  .-xs\:\[grid-column-start\:span_3\] {
    grid-column-start: span 3;
  }

  .-xs\:\[grid-row-gap\:0px\] {
    grid-row-gap: 0px;
  }

  .-xs\:\[grid-row-gap\:16px\] {
    grid-row-gap: 16px;
  }

  .-xs\:\[grid-row-gap\:24px\] {
    grid-row-gap: 24px;
  }

  .-xs\:\[grid-row-gap\:8px\] {
    grid-row-gap: 8px;
  }

  .-xs\:\[grid-template-columns\:1fr\] {
    grid-template-columns: 1fr;
  }

  .-xs\:\[grid-template-columns\:1fr_1fr_1fr\] {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .-xs\:\[grid-template-columns\:2fr\] {
    grid-template-columns: 2fr;
  }

  .-xs\:\[grid-template-columns\:2fr_2fr_2fr\] {
    grid-template-columns: 2fr 2fr 2fr;
  }

  .-xs\:\[grid-template-columns\:3fr\] {
    grid-template-columns: 3fr;
  }

  .-xs\:\[grid-template-columns\:4fr\] {
    grid-template-columns: 4fr;
  }

  .-xs\:\[grid-template-columns\:5fr\] {
    grid-template-columns: 5fr;
  }

  .-xs\:\[grid-template-rows\:auto_auto\] {
    grid-template-rows: auto auto;
  }

  .-xs\:\[height\:124px\] {
    height: 124px;
  }

  .-xs\:\[height\:150px\] {
    height: 150px;
  }

  .-xs\:\[height\:24px\] {
    height: 24px;
  }

  .-xs\:\[height\:2px\] {
    height: 2px;
  }

  .-xs\:\[height\:300px\] {
    height: 300px;
  }

  .-xs\:\[height\:40px\] {
    height: 40px;
  }

  .-xs\:\[height\:8px\] {
    height: 8px;
  }

  .-xs\:\[height\:96px\] {
    height: 96px;
  }

  .-xs\:\[justify-self\:stretch\] {
    justify-self: stretch;
  }

  .-xs\:\[left\:151px\] {
    left: 151px;
  }

  .-xs\:\[line-height\:28px\] {
    line-height: 28px;
  }

  .-xs\:\[line-height\:30px\] {
    line-height: 30px;
  }

  .-xs\:\[line-height\:32px\] {
    line-height: 32px;
  }

  .-xs\:\[line-height\:38px\] {
    line-height: 38px;
  }

  .-xs\:\[line-height\:40px\] {
    line-height: 40px;
  }

  .-xs\:\[margin-bottom\:10px\] {
    margin-bottom: 10px;
  }

  .-xs\:\[margin-bottom\:15px\] {
    margin-bottom: 15px;
  }

  .-xs\:\[margin-bottom\:16px\] {
    margin-bottom: 16px;
  }

  .-xs\:\[margin-bottom\:20px\] {
    margin-bottom: 20px;
  }

  .-xs\:\[margin-bottom\:24px\] {
    margin-bottom: 24px;
  }

  .-xs\:\[margin-left\:1em\] {
    margin-left: 1em;
  }

  .-xs\:\[margin-left\:22px\] {
    margin-left: 22px;
  }

  .-xs\:\[margin-right\:1em\] {
    margin-right: 1em;
  }

  .-xs\:\[margin-right\:8px\] {
    margin-right: 8px;
  }

  .-xs\:\[margin-top\:10px\] {
    margin-top: 10px;
  }

  .-xs\:\[max-width\:96px\] {
    max-width: 96px;
  }

  .-xs\:\[max-width\:none\] {
    max-width: none;
  }

  .-xs\:\[min-width\:auto\] {
    min-width: auto;
  }

  .-xs\:\[opacity\:1_\!important\] {
    opacity: 1 !important;
  }

  .-xs\:\[order\:-1\] {
    order: -1;
  }

  .-xs\:\[order\:0\] {
    order: 0;
  }

  .-xs\:\[padding-bottom\:10px\] {
    padding-bottom: 10px;
  }

  .-xs\:\[padding-bottom\:15px\] {
    padding-bottom: 15px;
  }

  .-xs\:\[padding-bottom\:20px\] {
    padding-bottom: 20px;
  }

  .-xs\:\[padding-bottom\:40px\] {
    padding-bottom: 40px;
  }

  .-xs\:\[padding-left\:10px\] {
    padding-left: 10px;
  }

  .-xs\:\[padding-left\:16px\] {
    padding-left: 16px;
  }

  .-xs\:\[padding-left\:20px\] {
    padding-left: 20px;
  }

  .-xs\:\[padding-left\:5px\] {
    padding-left: 5px;
  }

  .-xs\:\[padding-right\:10px\] {
    padding-right: 10px;
  }

  .-xs\:\[padding-right\:16px\] {
    padding-right: 16px;
  }

  .-xs\:\[padding-right\:20px\] {
    padding-right: 20px;
  }

  .-xs\:\[padding-top\:10px\] {
    padding-top: 10px;
  }

  .-xs\:\[padding-top\:20px\] {
    padding-top: 20px;
  }

  .-xs\:\[padding-top\:56\.17021276595745\%\] {
    padding-top: 56.17021276595745%;
  }

  .-xs\:\[padding\:12px_24px\] {
    padding: 12px 24px;
  }

  .-xs\:\[padding\:24px\] {
    padding: 24px;
  }

  .-xs\:\[padding\:4px\] {
    padding: 4px;
  }

  .-xs\:\[transform-style\:preserve-3d\] {
    transform-style: preserve-3d;
  }

  .-xs\:\[transform\:translate\(0\%\2c 0\%\)\!important\] {
    transform: translate(0%,0%) !important;
  }

  .-xs\:\[transform\:translate3d\(0vw\2c _0px\2c _0px\)_scale3d\(1\2c _1\2c _1\)_rotateX\(0deg\)_rotateY\(0deg\)_rotateZ\(0deg\)_skew\(0deg\2c _0deg\)\] {
    transform: translate3d(0vw, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  }

  .-xs\:\[width\:150px\] {
    width: 150px;
  }

  .-xs\:\[width\:90\%\] {
    width: 90%;
  }

  .-xs\:\[width\:auto\] {
    width: auto;
  }
}

.scrollbar-none::-webkit-scrollbar {
  display: none !important;
}

.dragging-ew,
.dragging-ew * {
  cursor: ew-resize !important;
  user-select: none !important;
}

.transition-none-everything,
.transition-none-everything * {
  transition: none !important;
}

/* https://stackblitz.com/edit/shopify-hydrogen-j4ackq?file=src%2Fstyles%2Findex.css&title=Hydrogen */

/* .strike {
  position: relative;
} */

/* .strike::before {
  content: '';
  display: block;
  position: absolute;
  width: 108%;
  height: 1.5px;
  left: -4%;
  top: 50%;
  transform: translateY(-50%);
  background: rgb(var(--color-primary));
  box-shadow: 0.5px 0.5px 0px 0.5px rgb(var(--color-contrast));
} */

/* https://stackblitz.com/edit/shopify-hydrogen-j4ackq?file=src%2Fstyles%2Findex.css&title=Hydrogen */

/* .card-image {
  @apply relative rounded overflow-clip flex justify-center items-center;
  &::before {
    content: ' ';
    @apply z-10 absolute block top-0 left-0 w-full h-full shadow-border rounded;
  }
  img {
    @apply object-cover w-full aspect-[inherit];
  }
} */

@keyframes appearFromY {
  0% {
    opacity: 0;
    transform: translateY(var(--appear-from, 100%));
  }

  50% {
    opacity: 0.8;
    top: var(--top-from);
    transform: translateY(0);
    height: var(--height-from, 5rem);
  }

  70% {
    opacity: 1;
    top: var(--top-to);
    height: var(--height-to, 0.65rem);
  }

  80% {
    opacity: 1;
    top: var(--top-to);
    background: var(--background-to);
    height: var(--height-to, 0.65rem);
  }

  100% {
    opacity: 0;
    top: var(--top-to);
    background: var(--background-to);
    height: var(--height-to, 0.65rem);
  }
}

@keyframes appearFromX {
  0% {
    opacity: 0;
    transform: translateX(var(--appear-from, 100%));
  }

  50% {
    opacity: 1;
    left: var(--left-from);
    transform: translateX(0);
    width: var(--width-from, 5rem);
  }

  70% {
    opacity: 1;
    left: var(--left-to);
    width: var(--width-to, 0.65rem);
  }

  80% {
    opacity: 1;
    left: var(--left-to);
    background: var(--background-to);
    width: var(--width-to, 0.65rem);
  }

  100% {
    opacity: 0;
    left: var(--left-to);
    background: var(--background-to);
    width: var(--width-to, 0.65rem);
  }
}

@keyframes graphicFadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.graphic-background {
  left: 50%;
  top: 0;
  height: 50rem;
  width: 45rem;
  pointer-events: none;
  background-size: 2.5rem 2.5rem;
  background-image: radial-gradient(
    circle at center,
    #7c4dff 0.325rem,
    rgb(124 77 255 / 0) 0.325rem
  );
}

.graphic-background-grey {
  left: 50%;
  top: 0;
  height: 100%;
  width: 100%;
  pointer-events: none;
  background-size: 2.5rem 2.5rem;
  background-image: radial-gradient(
    circle at center,
    #bfbfbf4d 0.325rem,
    rgb(124 77 255 / 0) 0.325rem
  );
  z-index: -1;
}

.advert-graphic__background-container {
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  height: 34rem;
  width: 34rem;
  pointer-events: none;
  background-size: 2.5rem 2.5rem;
  background-image: radial-gradient(
    circle at center,
    #7c4dff 0.325rem,
    rgb(124 77 255 / 0) 0.325rem
  );
}

.advert-graphic__background {
  transform: translate(8rem, 0);
}

#advert-graphic span:nth-child(1),
#advert-graphic span:nth-child(3),
#advert-graphic span:nth-child(5),
#advert-graphic span:nth-child(6) {
  backface-visibility: hidden;
  animation: appearFromY 3s linear infinite;
  animation-fill-mode: both;
  width: 0.65rem;
  opacity: 0;
}

#advert-graphic span:nth-child(2),
#advert-graphic span:nth-child(4) {
  backface-visibility: hidden;
  animation: appearFromX 3s linear infinite;
  animation-fill-mode: both;
  height: 0.65rem;
  opacity: 0;
}

#advert-graphic span:nth-child(1) {
  --top-from: 4.65rem;
  --top-to: 4.65rem;
  --background-to: #aa00ff;
  --appear-from: 10rem;
  animation-delay: 2.6s;
  top: var(--top-from);
  left: 13.425rem;
}

#advert-graphic span:nth-child(2) {
  --left-to: 3.4rem;
  --left-from: 0.6rem;
  --background-to: #00e676;
  --appear-from: -7rem;
  --width-from: 3.4rem;
  animation-delay: 1.5s;
  left: var(--left-from);
  top: 4.65rem;
}

#advert-graphic span:nth-child(4) {
  --left-to: 0.9rem;
  --left-from: 0.9rem;
  --background-to: #ff80ab;
  --appear-from: 10rem;
  --width-from: 5rem;
  animation-delay: 1s;
  left: var(--left-from);
  top: 19.675rem;
}

#advert-graphic span:nth-child(3) {
  --top-from: 1.95rem;
  --top-to: 4.7rem;
  --background-to: #ff80ab;
  --appear-from: -10rem;
  --height-from: 3.5rem;
  animation-delay: 3.2s;
  top: var(--top-from);
  left: 8.425rem;
}

#advert-graphic span:nth-child(5) {
  --top-from: 19.675rem;
  --top-to: 19.675rem;
  --background-to: #ffff00;
  --appear-from: 6rem;
  animation-delay: 3s;
  top: var(--top-from);
  left: 10.925rem;
}

#advert-graphic span:nth-child(6) {
  --top-from: 12.15rem;
  --top-to: 12.15rem;
  --background-to: #00b0ff;
  --appear-from: 12rem;
  animation-delay: 2s;
  top: var(--top-from);
  left: 0.925rem;
}

#advert-graphic img {
  animation: graphicFadeIn 600ms linear 200ms;
  animation-fill-mode: both;
}

@media (max-width: 768px) {
  .faq-drop-element svg {
    display: none;
  }
}

@media (min-width: 768px) {
  .feature-accordian {
    grid-template-columns: repeat(5, 1fr);
    grid-template-areas:
      "a b c d e"
      "content content content content content";
  }

  .feature-accordian.sixCol {
    grid-template-columns: repeat(6, 1fr);
    grid-template-areas:
      "a b c d e f"
      "content content content content content content";
  }

  .faq-drop-element.opened .accordian-heading {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
    --tw-text-opacity: 1;
    color: rgb(0 0 0 / var(--tw-text-opacity, 1));
  }

  .faq-drop-element.opened .accordian-heading h3 {
    font-weight: 600;
    --tw-text-opacity: 1;
    color: rgb(0 0 0 / var(--tw-text-opacity, 1));
  }

  .faq-drop-element svg {
    color: white;
    stroke: white;
  }

  .faq-drop-element.opened svg {
    color: black;
    stroke: black;
  }

  .faq-drop-element.opened .accordian-content {
    display: block;
  }

  .accordian-content {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
    --tw-text-opacity: 1;
    color: rgb(0 0 0 / var(--tw-text-opacity, 1));
  }
}

.oct-img {
  clip-path: polygon(
    50% 0,
    85% 15%,
    100% 50%,
    85% 85%,
    50% 100%,
    15% 85%,
    0 50%,
    15% 15%
  );
  background: black;
  padding: 5px;
}

.oct-img img {
  clip-path: polygon(
    50% 0,
    85% 15%,
    100% 50%,
    85% 85%,
    50% 100%,
    15% 85%,
    0 50%,
    15% 15%
  );
}

.uae-theme .bg-green-500 {
  background: #006500;
  border-color: #006500;
  color: #fff;
}

.uae-theme .bg-gradient-to-l {
  background: linear-gradient(to left, rgb(233 229 209), rgb(255 255 255 / 0));
}

.hide-header nav {
  display: none;
}
